import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { RateReview } from "@material-ui/icons";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import AppContext from "../../../AppContext";

const MessagesTemplateButton = ({ setOpenDialogDefaultMessages }) => {
  const { client } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Tooltip title={<Typography variant="body2">{t("default_messages")}</Typography>}>
      <IconButton
        component="span"
        onClick={() => setOpenDialogDefaultMessages(true)}
        disabled={!client.phone || (!client.active && client.active !== undefined)}
      >
        <RateReview />
      </IconButton>
    </Tooltip>
  );
};

export default MessagesTemplateButton;

MessagesTemplateButton.propTypes = {
  setOpenDialogDefaultMessages: PropTypes.func
};
