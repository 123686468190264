import "./ContactListBar.css";
import { Tab, Tabs, Typography } from "@material-ui/core";
import { People } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../AppContext";
import PropTypes from "prop-types";

const TabsContactListBar = () => {
  const { t } = useTranslation();
  const { controlConfig, setControlConfig } = useContext(AppContext);
  const [tabSelected, setTabSelected] = useState("customers");
  const classes = useStyles();

  const handleTabChange = (event, tabSelection) => {
    setTabSelected(tabSelection);
    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        tabSelectedContactList: tabSelection
      };
    });
  };

  useEffect(() => {
    setTabSelected("customers");
  }, [controlConfig.viewContactList]);

  return (
    <Tabs
      value={tabSelected}
      onChange={handleTabChange}
      indicatorColor="primary"
      variant="scrollable"
    >
      <Tab
        value="customers"
        icon={<People />}
        label={<Typography variant="body2">{t("customers")}</Typography>}
        className={classes.customTabOneSource}
        disabled={controlConfig.showLoaderContactList}
      />
      <Tab
        value="advisers"
        icon={<People />}
        className={classes.customTabOneSource}
        label={<Typography variant="body2">{t("advisers")}</Typography>}
        disabled={controlConfig.showLoaderContactList}
      />
    </Tabs>
  );
};

const useStyles = makeStyles(() => ({
  customTabOneSource: {
    display: "flex",
    flexDirection: "row",
    minWidth: "50%"
  }
}));

TabsContactListBar.propTypes = {
  filter: PropTypes.string
};

export default TabsContactListBar;
