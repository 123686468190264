import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Tab, Tabs, Typography } from "@material-ui/core";
import {
	Grade,
	Person
} from "@material-ui/icons";

const TabsUserCompany = ({ tabSelected, setTabSelected }) => {
  const { t } = useTranslation();

  const handleTabChange = (event, tabSelection) => {
    setTabSelected(tabSelection);
  };

  return (
    <Tabs
      value={tabSelected}
      onChange={handleTabChange}
      textColor="primary"
      indicatorColor="primary"
      variant="fullWidth"
    >
      <Tab value="editables" icon={<Person />} label={<Typography  variant="caption" color="primary">{t("editables")}</Typography>} />
      <Tab value="default" icon={<Grade />} label={<Typography  variant="caption" color="primary">{t("default")}</Typography>} />
    </Tabs>
  );
};

TabsUserCompany.propTypes = {
  tabSelected: PropTypes.string,
  setTabSelected: PropTypes.func
};

export default TabsUserCompany;
