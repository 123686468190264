import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChatIcon from "@material-ui/icons/Chat";
import SearchBar from "material-ui-search-bar";
import IconButton from "@material-ui/core/IconButton";
import AppContext from "../../AppContext";
import { Avatar, Badge, Box, Popover, Tab, Tabs } from "@material-ui/core";
import { List, People, Undo, MenuOpen, Menu } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from 'clsx';


import Helper from "../../utils/Helper";
import useDebounce from "../../hooks/useDebounce";
import PopoverItemsDelegates from "./PopoverItemsDelegates";
import PopoverItemsNewChat from "./PopoverItemsNewChat";
import ApiCalls from "../../utils/APIRequests";

export const UserInfoBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorElDelegates, setAnchorElDelegates] = React.useState(null);
  const [anchorNewChat, setanchorNewChat] = useState(null);
  const [filter, setFilter] = useState("");
  const [expandedTagsMenu, setExpandedTagsMenu] = useState(false);
  const debouncedValue = useDebounce(filter, 300);

  useEffect(() => {
    setControlConfig(oControlConfig => {
      return {
        ...oControlConfig,
        searchUserList: debouncedValue
      };
    });
  }, [debouncedValue]);

  const { filterArraySearchString, filterUsersByTag } = Helper;

  const {
    assessor,
    setAssessor,
    controlConfig,
    setControlConfig,
    appConfig,
    setAppConfig,
    delegates,
    setNewChatItem,
    userList,
    setFilteredUserList,
    setSkipSaveAppConfig,
    MQTTConnectionsList,
    setMQTTConnectionsList,
    setStopDelegating,
    selectedTag
  } = useContext(AppContext);

  const bOpenDelegates = Boolean(anchorElDelegates);
  const idDelegates = bOpenDelegates ? "simple-popover" : undefined;
  const bOpenNewChat = Boolean(anchorNewChat);
  const idNewChat = bOpenNewChat ? "simple-popover" : undefined;

  const handleTabChange = (oEvent, sTabSelection) => {
    let filteredArr = filterArraySearchString(userList, filter, ["user", "course_name", "phone"], sTabSelection);
    let newFilteredUserList = filterUsersByTag(filteredArr, selectedTag)

    setFilteredUserList(newFilteredUserList);

    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        tabSelection: sTabSelection
      };
    });
  };

  const onCancelSearch = () => {
    setFilter("");
  };

  const activeDelegateWithUndoMyUser = () => {
    return appConfig.myUser.email !== assessor.email;
  };

  const undoDelegateMyUser = () => {
    setNewChatItem({});
    setMQTTConnectionsList(oMQTT => {
      return {
        ...oMQTT,
        to_disconnect: [...oMQTT.to_disconnect, {email: assessor.email}]
      }
    });
    setControlConfig(oControlConfig => {
      return {
        ...oControlConfig,
        showLoaderUserList: true
      };
    });
    setAssessor(appConfig.myUser);
    setStopDelegating(true)
  };

  const handleOpenPopoverDelegates = event => {
    setAnchorElDelegates(event.currentTarget);
  };

  const handleClosePopoverDelegates = () => {
    setAnchorElDelegates(null);
  };

  const handleOpenPopoverNewChat = event => {
    setanchorNewChat(event.currentTarget);
  };

  const handleClosePopoverNewChat = () => {
    setanchorNewChat(null);
  };

  const checkDelegates = () => {
    return !!(delegates && delegates.length > 0);
  };

  const checkUnreadDelegates = () => {
    let bRet = true;
    delegates.forEach(oDelegate => {
      if (oDelegate.unread > 0) {
        bRet = false;
        return false;
      }
    });

    return bRet;
  };

  const countPendingNotifications = () => {
    let nTotalUnread = 0;
    if (userList && userList.length > 0) {
      userList.forEach(user => {
        if (typeof user?.unread != 'undefined') {
          nTotalUnread += user.unread;
        }
      });
    }

    return nTotalUnread;
  };

  const countUnreadConversations = () => {
    let bUnreadConversations = 0;
    if (userList && userList.length > 0) {
      userList.forEach(user => {
        if (typeof user?.unread != 'undefined' && user.unread) {
          bUnreadConversations = true;
        }
      });
    }
    return bUnreadConversations;
  };

  const findSourceDescription = sSource => {
    const oSource = appConfig.aoSources.find(oSource => oSource.id === sSource);
    return oSource ? oSource.description : sSource;
  };

  useEffect(() => {
    setExpandedTagsMenu(appConfig.hasOwnProperty('expandedTagsMenu') ? appConfig.expandedTagsMenu : false)
  }, []);


  const toggleMenuTags = () => {
    setExpandedTagsMenu(!expandedTagsMenu);
    let oAppConfig = appConfig;
    oAppConfig.expandedTagsMenu = !appConfig.expandedTagsMenu;
    setSkipSaveAppConfig(true)
    setAppConfig(aoAppConfig => {
      return {
        ...aoAppConfig,
        expandedTagsMenu: !expandedTagsMenu
      }
    })
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);
  }

  const printMenuTag = () => {
    if (appConfig.usetags) {
      return (
        <Tooltip
          title={
            <Typography color="secondary" variant="body2">
              {expandedTagsMenu == true ? t('hideTagsMenuTooltip') : t('showTagsMenuTooltip')}
            </Typography>
          }
        >
          <MenuOpen
            color="secondary"
            className={clsx(classes.menuTagsIcon, [expandedTagsMenu == true ? 'open' : 'close'])}
            onClick={toggleMenuTags}
          />
        </Tooltip>
      )
    } else {
      return false;
    }
  }

  return (
    <div className={appConfig.darkTheme ? classes.rootDark : classes.root}>
      <AppBar position="static" className={classes.headerUsers}>
        <Toolbar>
          {printMenuTag()}
          <Typography
            color="secondary"
            style={{ fontSize: `${appConfig.size + 4}px` }}
            className={classes.title}
          >
            {/* {t("chats")} */} {assessor.compoundName}
          </Typography>
          <Tooltip
            title={
              <Typography color="secondary" variant="body2">
                {t("undoMyUser.tooltip")}
              </Typography>
            }
          >
            <IconButton
              color="secondary"
              component="span"
              onClick={undoDelegateMyUser}
              className={activeDelegateWithUndoMyUser() ? "" : classes.invisibleWithDom}
            >
              <Badge
                overlap="rectangular"
                variant="dot"
                badgeContent={appConfig.myUser.unread}
                color="error"
              >
                <Undo />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <Typography color="secondary" variant="body2">
                {t("delegates.tooltip")}
              </Typography>
            }
          >
            <IconButton
              color="secondary"
              component="span"
              onClick={handleOpenPopoverDelegates}
              className={checkDelegates() ? "" : classes.invisible}
            >
              <Badge
                overlap="rectangular"
                variant="dot"
                invisible={checkUnreadDelegates()}
                classes={{ badge: classes.badgeColor }}
              >
                <People />
              </Badge>
            </IconButton>
          </Tooltip>
          <Popover
            id={idDelegates}
            open={bOpenDelegates}
            anchorEl={anchorElDelegates}
            onClose={handleClosePopoverDelegates}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <PopoverItemsDelegates handleClosePopoverDelegates={handleClosePopoverDelegates} />
          </Popover>
          <Tooltip
            title={
              <Typography color="secondary" variant="body2">
                {t("contacts.tooltip")}
              </Typography>
            }
          >
            <IconButton color="secondary" component="span" onClick={handleOpenPopoverNewChat}>
              <ChatIcon />
            </IconButton>
          </Tooltip>
          <Popover
            id={idNewChat}
            open={bOpenNewChat}
            anchorEl={anchorNewChat}
            onClose={handleClosePopoverNewChat}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <PopoverItemsNewChat handleClosePopoverNewChat={handleClosePopoverNewChat} />
          </Popover>
        </Toolbar>
      </AppBar>
      <Box
        className={assessor.email === appConfig.myUser.email ? classes.invisible : ""}
        sx={{
          width: "100%",
          height: 5,
          backgroundColor: "#2d2d30",
          "&:hover": {
            backgroundColor: "primary.main",
            opacity: [0.9, 0.8, 0.7]
          }
        }}
      />
      <SearchBar
        placeholder={t("search")}
        onChange={e => setFilter(e)}
        onRequestSearch={e => setFilter(e)}
        className={appConfig.darkTheme ? classes.searchBarDark : classes.searchBar}
        value={filter}
        onCancelSearch={onCancelSearch}
      />
      <Tabs
        value={controlConfig.tabSelection}
        onChange={handleTabChange}
        indicatorColor="primary"
        variant="scrollable"
      >
        <Tab
          value="all"
          icon={
            <Tooltip
              title={
                <Typography color="secondary" variant="body2">
                  {t("all")}
                </Typography>
              }
            >
              <List color="primary" />
            </Tooltip>
          }
          className={
            appConfig.sources.length > 1 ? classes.customTabMoreSources : classes.customTabOneSource
          }
        />
        <Tab
          value="unread"
          icon={
            <Tooltip
              title={
                <Typography color="secondary" variant="body2">
                  {t("unread")}
                </Typography>
              }
            >
              <Badge
                overlap="rectangular"
                badgeContent={countPendingNotifications() || countUnreadConversations()}
                color="error"
              >
                <ChatIcon color="primary" />
              </Badge>
            </Tooltip>
          }
          className={
            appConfig.sources.length > 1 ? classes.customTabMoreSources : classes.customTabOneSource
          }
        />
        {appConfig.sources.length > 1 &&
          appConfig.sources.map(sSource => (
            <Tab
              key={sSource}
              value={sSource}
              icon={
                <Tooltip
                  color="primary"
                  title={
                    <Typography color="secondary" variant="body2">
                      {findSourceDescription(sSource)}
                    </Typography>
                  }
                >
                  <Avatar className={classes.customAvatar}>{sSource.charAt(0)}</Avatar>
                </Tooltip>
              }
              className={
                appConfig.sources.length > 1
                  ? classes.customTabMoreSources
                  : classes.customTabOneSource
              }
            />
          ))}
      </Tabs>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  badgeColor: {
    backgroundColor: "#4fc3f7"
  },
  customTabMoreSources: {
    minWidth: "25%",
    maxWidth: "25"
  },
  customTabOneSource: {
    minWidth: "50%"
  },
  customAvatar: {
    width: 20.8,
    height: 20.8,
    backgroundColor: "#242424",
    fontSize: "10px"
  },
  headerUsers: {
    backgroundColor: "#065D54",
    borderRadius: "10px 10px 0 0"
  },
  invisible: {
    display: "none"
  },
  invisibleWithDom: {
    visibility: "hidden",
    width: 0
  },
  menuItem: {
    padding: 15,
    gap: 15
  },
  searchBar: {
    borderRadius: 0,
    marginBottom: "1px !important"
  },
  searchBarDark: {
    borderRadius: 0,
    marginBottom: "1px !important",
    backgroundColor: "#2d2d30"
  },
  title: {
    flexGrow: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  root: {
    flexGrow: 1
  },
  rootDark: {
    flexGrow: 1,
    backgroundColor: ""
  },
  menuTagsIcon: {
    cursor: 'pointer',
    transform: "scaleX(-1)",
    marginRight: 10,
    "&.close": {
      transform: "scaleX(1)"
    }
  }
}));

UserInfoBar.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func
};

export default UserInfoBar;
