import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Checkbox,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";
import AppContext from "../../AppContext";

const ContactListItemAssessor = ({ oContactUser, checked, newChatOrNewGroup }) => {
  const classes = useStyles();
  const { assessor, appConfig, controlConfig } = useContext(AppContext);

  return (
    <React.Fragment>
      <ListItem
        key={oContactUser}
        button
        onClick={() => newChatOrNewGroup(oContactUser)}
        alignItems="flex-start"
        disabled={oContactUser.assesor_email === assessor.email}
      >
        {(controlConfig.isNewGroup || controlConfig.isUpdateGroup) && (
          <Checkbox
            edge="start"
            checked={checked.some(
              checkedContactUser => checkedContactUser.assesor_email === oContactUser.assesor_email
            )}
            tabIndex={-1}
            disableRipple
            disabled={oContactUser.assesor_email === assessor.email}
          />
        )}
        <ListItemAvatar>
          <Avatar>{oContactUser.assesor_first_name.charAt(0)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            oContactUser.assesor_last_name ? (
              <Typography component="span" style={{ fontSize: `${appConfig.size}px` }}>
                {oContactUser.assesor_first_name + " " + oContactUser.assesor_last_name}
              </Typography>
            ) : (
              <Typography component="span" style={{ fontSize: `${appConfig.size}px` }}>
                {oContactUser.assesor_first_name}
              </Typography>
            )
          }
        />
      </ListItem>
      <Divider
        className={appConfig.darkTheme ? classes.customDividerDark : classes.customDivider}
        variant="inset"
        component="li"
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles(() => ({
  customDivider: {
    marginLeft: "15px !important",
    marginRight: "15px !important"
  },
  customDividerDark: {
    marginLeft: "15px !important",
    marginRight: "15px !important",
    backgroundColor: "#595959"
  },
  inline: {
    display: "inline"
  }
}));

ContactListItemAssessor.propTypes = {
  oContactUser: PropTypes.shape({
    assesor_email: PropTypes.string,
    assesor_first_name: PropTypes.string,
    assesor_last_name: PropTypes.string,
    client_email: PropTypes.string,
    client_familiarname: PropTypes.string,
    client_lastname: PropTypes.string,
    client_name: PropTypes.string,
    country: PropTypes.string,
    course_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    course_name: PropTypes.string,
    date_modified: PropTypes.string,
    filter: PropTypes.bool,
    language: PropTypes.string,
    phone: PropTypes.string,
    source: PropTypes.string,
    unread: PropTypes.number,
    user: PropTypes.string
  }),
  checked: PropTypes.array,
  newChatOrNewGroup: PropTypes.func
};

export default ContactListItemAssessor;
