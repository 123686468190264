import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  errorAlert: {
    width: "35rem"
  },
  errorAlertContainer: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: "20px"
  }
}));

const AlertMessage = ({ message }) => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      ReactDOM.render(null, document.getElementById("root"));
    }, 5000);
  }, []);

  return (
    <div className={classes.errorAlertContainer}>
      <Alert className={classes.errorAlert} severity="error">
        {message}
      </Alert>
    </div>
  );
};

AlertMessage.propTypes = {
  message: PropTypes.string
};

export default AlertMessage;
