import React, { useContext } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Popover,
  Typography
} from "@material-ui/core";
import { MoreVert, Settings } from "@material-ui/icons";
import PropTypes from "prop-types";
import AppContext from "../../AppContext";

const PopoverOptionsConfiguration = ({ anchorEl, setAnchorEl, setOpenDialogConfiguration }) => {
  const classes = useStyles();
  const { twilioVideo } = useContext(AppContext);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialogConfiguration = () => {
    setOpenDialogConfiguration(true);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className={classes.button}
        onClick={handleClick}
        disabled={!twilioVideo.isConnected}
      >
        <MoreVert className={classes.icon} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <List className={classes.list}>
          <ListItem button onClick={handleOpenDialogConfiguration}>
            <ListItemIcon>
              <Settings className={classes.icon} />
            </ListItemIcon>
            <Typography className={classes.listItemText}>Configuración</Typography>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: "#3e3e42"
  },
  icon: {
    color: "#fff"
  },
  list: {
    backgroundColor: "#3e3e42",
    padding: 0
  },
  listItemText: {
    color: "#fff"
  }
}));

PopoverOptionsConfiguration.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  setOpenDialogConfiguration: PropTypes.func
};

export default PopoverOptionsConfiguration;
