import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Minimize } from "@material-ui/icons";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../AppContext";
import ApiCalls from "../../../utils/APIRequests";

import is_electron from "is-electron";

const MinimizedButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig, setAppConfig, assessor, setSkipSaveAppConfig } = useContext(AppContext);

  const setMinimized = () => {
    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        minimized: !appConfig.minimized
      };
    });
  };

  return (
    <Tooltip
      title={<Typography variant="body2">{t("minimize.tooltip")}</Typography>}
      className={is_electron() ? classes.invisible : ""}
    >
      <IconButton color="secondary" component="span" onClick={setMinimized}>
        <Minimize />
      </IconButton>
    </Tooltip>
  );
};

export default MinimizedButton;

const useStyles = makeStyles(() => ({
  invisible: {
    display: "none"
  }
}));
