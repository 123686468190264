import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import ImageItem from "./ImageItem";
import Helper from "../../../utils/Helper";

const ImagesTab = ({ media }) => {
  const classes = useStyles();
  const { parseMonth, isDateMonthLabelNeeded } = Helper;

  let dLastLinkDate = null;
  const showDateLabel = date_modified => {
    const dLinkDate = new Date(date_modified);
    if (isDateMonthLabelNeeded(dLinkDate, dLastLinkDate)) {
      dLastLinkDate = dLinkDate;
      return true;
    }
    return false;
  };

  return (
    <Box className={classes.imagesContainer} key="imagesContainer">
      {media?.map((image, index) => (
        <React.Fragment key={image.ubm_id ? image.ubm_id : index}>
          {showDateLabel(image.date_modified) && (
            <Typography color="primary" variant="h6" className={classes.date}>
              {parseMonth(image.date_modified)}
            </Typography>
          )}
          <ImageItem image={image} />
        </React.Fragment>
      ))}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  imagesContainer: {
    padding: "5px"
  },
  date: {
    marginLeft: 30,
    margin: "10px 0 "
  }
}));

ImagesTab.propTypes = {
  media: PropTypes.array
};

export default ImagesTab;
