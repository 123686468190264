import React, { useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";
import { makeStyles } from "@material-ui/core/styles";

const DialogPermissionDenied = ({
  openDialogPermissionDenied,
  handleCloseOpenDialogPermissionDenied
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { twilioVideo, appConfig } = useContext(AppContext);

  return (
    <Dialog
      open={openDialogPermissionDenied}
      onClose={handleCloseOpenDialogPermissionDenied}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {twilioVideo.audioOnly
          ? t("messagePermissionVoiceCallTitle")
          : t("messagePermissionVideoCallTitle")}
      </DialogTitle>
      <DialogContent className={appConfig.darkTheme ? classes.dialogDark : ""}>
        <Typography variant="subtitle2" id="alert-dialog-description">
          {twilioVideo.audioOnly
            ? t("messagePermissionVoiceCall")
            : t("messagePermissionVideoCall")}
        </Typography>
      </DialogContent>
      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
      >
        <Button onClick={handleCloseOpenDialogPermissionDenied} color="primary">
          <Typography color="primary" variant="caption">
            {t("close")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  dialogActions: {
    borderTop: "1px solid",
    borderColor: "#bdbdbd"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959"
  },
  dialogDark: {
    backgroundColor: "#3e3e42",
    color: "#fff"
  }
}));

DialogPermissionDenied.propTypes = {
  openDialogPermissionDenied: PropTypes.bool,
  handleCloseOpenDialogPermissionDenied: PropTypes.func
};

export default DialogPermissionDenied;
