import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputBase,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    IconButton 
  } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PersonIcon from '@material-ui/icons/Person';
import ApiCalls from "../../utils/APIRequests";
import SearchBar from "material-ui-search-bar";
import useDebounce from "../../hooks/useDebounce";

const ManageTagContactsDialog = ({ selectedtagname, selectedtagkey, open, onClose }) => {
    const { appConfig, companyTags, assessor, userList, setFilteredUserList, setSkipSaveAppConfig, selectedTag, setSelectedTag } =
    useContext(AppContext);
    const { t } = useTranslation();
    const classes = useStyles();
    const [updatedUserList, setUpdatedUserList] = useState([...userList]);
    const [updateConversations, setUpdateConversations] = useState([]);
    const [filter, setFilter] = useState("");
    const debouncedValue = useDebounce(filter, 300);

    useEffect(() => {
        let filteredUsers = []
        setUpdatedUserList(userList.map(elem => {
            if(
                elem.group === false && (
                    elem.client_familiarname.toLowerCase().includes(debouncedValue)
                    || elem.phone.toLowerCase().includes(debouncedValue)
                    || elem.client_email.toLowerCase().includes(debouncedValue)
                    || elem.user.toLowerCase().includes(debouncedValue)
                    || elem.course_name.toLowerCase().includes(debouncedValue)
                )
            ) {
                filteredUsers.push(elem)
            }
        }))
        setUpdatedUserList(filteredUsers)
    }, [debouncedValue]);

    useEffect(() => {
        setUpdatedUserList([...userList])
        setUpdateConversations([])
        setFilter("")
    }, [open])

    const handleToggle = (contact) => () => {
        let tags = {
            company_tags: typeof contact.tags != 'undefined' && typeof contact.tags.company_tags != 'undefined' && contact.tags?.company_tags?.length > 0 ? contact.tags.company_tags : [],
            user_tags: typeof contact.tags != 'undefined' && typeof contact.tags.user_tags != 'undefined' && contact.tags?.user_tags?.length > 0 ? contact.tags.user_tags : [],
        }

        if ( companyTags.filter(tag => tag.key == selectedtagkey) > 0 ) {
            if (tags.company_tags.indexOf(selectedtagkey) > -1) {
                tags.company_tags = tags.company_tags.filter(item => item != selectedtagkey);
            } else {
                tags.company_tags = [ ...tags.company_tags, selectedtagkey]
            }
        }
        
        if ( appConfig.userTags.filter(tag => tag.key == selectedtagkey).length > 0 ) {
            if (tags.user_tags.indexOf(selectedtagkey) > -1) {
                tags.user_tags = tags.user_tags.filter(item => item != selectedtagkey);
            } else {
                tags.user_tags = [ ...tags.user_tags, selectedtagkey]
            }
        }

        let tmpUserList = [];
        updatedUserList.forEach(elem => {
            if (elem.key == contact.key) {
                elem.tags = tags;
            }
            tmpUserList.push(elem)
        })
        
        setUpdatedUserList(tmpUserList)

        let tmpUpdateConversations = [];
        let found = false;
        updateConversations.forEach(conv => {
            if (conv.phone == contact.phone) {
                conv.tags = tags;
                found = true;
            }
            tmpUpdateConversations.push(conv)
        });
        if (!found) {
            tmpUpdateConversations.push({
                tags: tags,
                phone: contact.phone
            })
        }
        setUpdateConversations(tmpUpdateConversations);
    };

    const handleSave = async () => {
        await Promise.all(updateConversations.map(oConversation => {
            ApiCalls.updateConversationTags(
                appConfig.host,
                appConfig.source,
                appConfig.company,
                assessor.email,
                oConversation
              );
            }
        ));
        setUpdateConversations([]);
        onClose();
    }

    const onSearchContactList = sValue => {
        setFilter(sValue);
      };
      const onCancelSearch = () => {
        setFilter("");
      };

    return (
        <Dialog
            id="tag-contacts-context-menu"
            maxWidth="sm"
            selectedtagname={selectedtagname}
            selectedtagkey={selectedtagkey}
            open={open}
            onClose={onClose}
        >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
            {`${t("manageTagContactsDialogTitle")} ${selectedtagname}`}
        </DialogTitle>

        <DialogContent className={appConfig.darkTheme ? classes.dialogDark : classes.dialog}>
            <SearchBar
                placeholder={t("search")}
                onChange={onSearchContactList}
                onRequestSearch={onSearchContactList}
                className={appConfig.darkTheme ? classes.searchBarDark : classes.searchBar}
                value={filter}
                onCancelSearch={onCancelSearch}
            />
            <List className={classes.root}>
                {updatedUserList.map((contact) => {
                    if (contact.group === false) {
                        const labelId = `checkbox-list-label-${contact.key}`;
                        return (
                            <ListItem key={contact.key} role={undefined} dense button onClick={handleToggle(contact)}>
                                <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={ contact.tags && (contact.tags.user_tags.indexOf(selectedtagkey) !== -1 || contact.tags.company_tags.indexOf(selectedtagkey) !== -1) }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    color="primary"
                                    className={appConfig.darkTheme ? classes.checkBoxDark : ''}
                                />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={contact.client_familiarname} />
                                <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <PersonIcon color="primary"/>
                                </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    }
                })}
            </List>
        </DialogContent>

        <DialogActions className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}>
            <Button onClick={onClose} className={classes.dialogButton} color="primary">
                <Typography variant="body1" color="primary">
                    {t("close")}
                </Typography>
            </Button>
            <Button onClick={handleSave} className={classes.dialogButtonPrimaryAction} color="secondary">
                <Typography variant="body1" color="secondary">
                    {t("save")}
                </Typography>
            </Button>
        </DialogActions>
      </Dialog>
    );
}

export default ManageTagContactsDialog;


const useStyles = makeStyles(() => ({
    dialogTitle: {
      backgroundColor: "#065d54",
      color: "#fff",
      minWidth: "500px"
    },
    dialog: {
      padding: 25,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    },
    dialogDark: {
      backgroundColor: "#3e3e42",
      color: "#fff",
      padding: 25,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    },
    dialogActions: {
      borderTop: "1px solid #bdbdbd",
      padding: "0.8rem 0"
    },
    dialogActionsDark: {
      backgroundColor: "#3e3e42",
      borderTop: "1px solid #595959",
      padding: "0.8rem 0"
    },
    dialogButton: {
      padding: "0.5rem 0.8rem",
      marginRight: "1.5rem"
    },
    dialogButtonPrimaryAction: {
      backgroundColor: "#065d54",
      padding: "0.5rem 0.8rem",
      marginRight: "1.5rem",
      "&:hover": {
        backgroundColor: "#207060",
        opacity: [0.9, 0.8, 0.7]
      }
    },
    customInput: {
      border: "1px solid #f9f9f9",
      borderRadius: 5,
      minWidth: 500,
      marginBottom: 15,
      fontSize: 20,
      paddingLeft: 15
    },
    searchBar: {
        borderRadius: 0,
        marginBottom: "1px !important"
    },
    searchBarDark: {
        backgroundColor: "#2d2d30",
        borderRadius: 0,
        marginBottom: "1px !important",
        "& input": {
            color: "#FFFFFF"
        },
        "& .MuiIconButton-label": {
            color: "#FFFFFF"
        },
    },
    checkBoxDark: {
        "& .MuiIconButton-label": {
            color: "#FFFFFF"
        }
    }
  }));

  ManageTagContactsDialog.propTypes = {
    selectedtagname: PropTypes.string || undefined,
    selectedtagkey: PropTypes.string || undefined,
    open: PropTypes.bool,
    onClose: PropTypes.func
  };