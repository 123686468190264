import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import Helper from "../../utils/Helper";

const { constants, getRandomInt } = Helper;

const useStyles = makeStyles(() => ({
  dateLabelContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0"
  },
  dateLabelDummy: {
    borderRadius: "16px",
    width: "90px"
  },
  messageContent: {
    borderRadius: "16px",
    padding: "5px 20px",
    width: "70%"
  },
  messageGroupStart: {
    margin: "0 10px 10px 10px",
    display: "flex",
    justifyContent: "flex-start"
  },
  messageGroupEnd: {
    margin: "0 10px 10px 10px",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const MessageSkeleton = () => {
  const classes = useStyles();

  const nItems = getRandomInt(
    constants.messageSkeleton.minItems,
    constants.messageSkeleton.maxItems
  );
  const aoItems = [];

  let oItem = (
    <div key={0} className={classes.dateLabelContainer}>
      <Skeleton variant="rect" height={32} className={classes.dateLabelDummy} />
    </div>
  );
  aoItems.push(oItem);

  for (let i = 0; i < nItems; i++) {
    oItem = (
      <div key={i + 1} className={i % 2 ? classes.messageGroupStart : classes.messageGroupEnd}>
        <Skeleton
          variant="rect"
          height={getRandomInt(
            constants.messageSkeleton.minHeight,
            constants.messageSkeleton.maxHeight
          )}
          className={classes.messageContent}
        />
      </div>
    );
    aoItems.push(oItem);
  }

  return <React.Fragment>{aoItems.reverse()}</React.Fragment>;
};

export default MessageSkeleton;
