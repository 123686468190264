import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import ApiCalls from "./utils/APIRequests";
import Alert from "./components/Alert/Alert";
import App from "./App";
// TODO: Migrate to React 18
// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
// import { createRoot } from 'react-dom/client';
import is_electron from "is-electron";

// Errores críticos por los que debemos parar la ejecución del componente.
const errorCodes = [
    {
        key: 'source',
        code: 0,
        message: 'source is a required parameter',
        required: true
    },
    {
        key: 'assessor_email',
        code: 1,
        message: 'assessor_email is a required parameter',
        required: true
    },
    {
        key: 'assessor_first_name',
        code: 2,
        message: 'assessor_first_name is a required parameter',
        required: true
    },
    {
        key: 'assessor_last_name',
        code: 3,
        message: 'assessor_last_name is a required parameter',
        required: false
    },
    {
        key: 'phone',
        code: 4,
        message: 'phone ({{phone}}) is not valid',
        required: false
    },
    {
        key: 'client_name',
        code: 5,
        message: 'client_name is a required parameter',
        required: false // Sólo si viene el parámetro phone y éste es válido
    },
];

const needInstantiateComponent = (oOldChatInstantiation, oNewChatInstantiation) => {
    return (
        !oOldChatInstantiation ||
        (oNewChatInstantiation.assessor_email != oOldChatInstantiation.assessor_email || oNewChatInstantiation.source != oOldChatInstantiation.source)
    );
}

const clearStorage = () => {
    localStorage.removeItem("userKey");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("userCourse");
    localStorage.removeItem("userKeyGetMessages");
    localStorage.removeItem("userPhoneGetMessages");
    localStorage.removeItem("userCourseGetMessages");
    localStorage.removeItem("client");
}

export function init(oProps) {
    clearStorage();

    const host = oProps.host && typeof oProps.host === "string" ? oProps.host : "https://wa.unir.net";
    oProps.host = host;

    const oNewChatInstantiation = { host: host, ...oProps };
    const oOldChatInstantiation = window && window.oChatInstantiation ? window.oChatInstantiation : undefined;

    // Si es una nueva instanciación o si es es la app de escritorio
    if (needInstantiateComponent(oOldChatInstantiation, oNewChatInstantiation) || is_electron()) {
        if (window) {
            window.oChatInstantiation = oProps;
        }
        const oRootComponent = document.getElementById("root");
        if (oRootComponent) {
            ReactDOM.render(undefined, oRootComponent);
        }
      
        let settings = []
        ApiCalls.getUserSettings(oProps)
            .then(async response => { settings = await response.json() })
            .catch(() => { if (oProps.verbose == true) { console.log('init getUserSettings => catch') } })
            .finally(() => {
                configurePropsAndinitComponent(oProps, settings, oRootComponent);
            });

        // Este if se ejecuta cuando cargan el componente y llaman a la función window.selectConversation
    } else if (typeof window.selectConversation != 'undefined' && oProps.phone && oProps.source) {
        ApiCalls.validatePhoneAPI(oProps.phone, oProps.country, oProps.company, oProps.unit, host)
            .then(oResponse => {
                let min = [true, 'true', 1, '1'].indexOf(oProps.minimized) > -1;
                let show_userlist = [false, 'false', 0, '0'].indexOf(oProps.show_userlist) > -1 ? false : true;
                window.selectConversation(oResponse.phone, min, show_userlist, true, false, oProps);
            })
            .catch((error) => {
                let err = errorCodes.find(item => item.key == 'phone');
                let sendError = {
                    key: err.key,
                    code: err.code,
                    message: err.message.replace(new RegExp(`{{${err.key}}}`, 'g'), oProps?.phone),
                    value: oProps?.phone
                };
                window.throwAppError(sendError);
            });
    }
}

const configureProps = (oProps, oSettings) => {
    return {
        sAssessorName: oProps.assessor_first_name,
        sAssessorLastName: oProps.assessor_last_name,
        sAssessorEmail: oProps.assessor_email,
        sSource: oProps?.source ? oProps.source.toUpperCase() : "",
        sClientName: oProps.client_name,
        sClientLastName: oProps.client_lastname,
        sClientEmail: oProps.client_email,
        sCountry: oProps.country,
        sCourseId: oProps.course_id,
        sCourseName: oProps.course_name,
        sPhone: oProps.phone,
        bFilterSource: oProps.filter,
        sLanguage: oProps.language,
        bDeveloper: oProps.developer,
        bShowUserList:
            typeof oProps.show_userlist === "string" && oProps.show_userlist.toLowerCase() === "true"
                ? true
                : typeof oProps.show_userlist === "string" && oProps.show_userlist.toLowerCase() === "false"
                    ? false
                    : oProps.show_userlist == 1
                        ? true
                        : oProps.show_userlist == 0
                            ? false
                            : oProps.show_userlist
                                ? oProps.show_userlist
                                : true,
        bAlwaysRefresh: oProps.always_refresh,
        sCompany: oProps.company,
        sUnit: oProps.unit,
        bMinimized:
            typeof oProps.minimized === "string" && oProps.minimized.toLowerCase() === "true"
                ? true
                : typeof oProps.minimized === "string" && oProps.minimized.toLowerCase() === "false"
                    ? false
                    : oProps.minimized === 1
                        ? true
                        : oProps.minimized === 0
                            ? false
                            : oProps.minimized
                                ? oProps.minimized
                                : false,
        nWidth: oSettings?.width,
        nHeight: oSettings?.height,
        nSize: oSettings?.size,
        bDarkTheme: oSettings?.dark_theme
            ? oSettings?.dark_theme
            : oProps.dark_theme
                ? oProps.dark_theme
                : false,
        aoSources: [],
        aDefaultMessages: oSettings?.default_messages?.map(message => { return { ...message, type: 'user_message' } }),
        sEncryptionKey: oProps.encryptionkey,
        sVersion: oSettings?.version,
        sHost: oProps.host && typeof oProps.host === "string" ? oProps.host : "https://wa.unir.net",
        bUseWs: oSettings?.usews,
        bShowCloseButton: oProps.showclose,
        aoUserTags: oSettings?.userTags,
        aousetags: oSettings?.usetags && ['true', '1', 1, true].indexOf(oSettings?.usetags) >= 0 ? true : false,
        aoExpandedTagsMenu: oSettings?.expandedTagsMenu && ['true', '1', 1, true].indexOf(oSettings?.expandedTagsMenu) >= 0 ? true : false,
        bVerbose: typeof oProps.verbose != 'undefined' && ['true', '1', 1, true].indexOf(oProps.verbose) >= 0 ? true : false,
        oNotifications: typeof oSettings?.notifications != 'undefined' ? oSettings?.notifications : false,
        onLoadError: [],
        defaultSelectedPhone: oProps.phone ?? false,
        sDefaultCompanyMessagesfilters: oSettings.defaultCompanyMessagesfilters ?? []
    };
}

const configurePropsAndinitComponent = async (oProps, oSettings, oRootComponent) => {
    let oAppProps = configureProps(oProps, oSettings);
    const sources = await ApiCalls.getSources({ host: oAppProps.sHost, company: oAppProps.sCompany })
        .then(async response => { return await response.json() })
        .catch((err) => { return [] });

    let updatedProps = await validateParameters(oProps);
    oAppProps = { ...oAppProps, ...updatedProps };


    // Si es un error crítico (falla un parámetro requerido) lanzamos un mensaje de error.
    let criticalError = false;
    oAppProps?.onLoadError?.map(err => {
        if (errorCodes.find(item => item.required && item.code == err.code)){
            criticalError = true;
            ReactDOM.render(<Alert message={err.message} />, oRootComponent);
        }
        // Si tenemos un error con el teléfono introducido, borramos los parámetros relativos al cliente.
        if (err.key == 'phone') {
            delete oAppProps.sPhone;
            delete oAppProps.sClientName;
            delete oAppProps.sClientLastName;
        }
    });
    if(!criticalError) {
        if ( oAppProps.sSource && !sources?.find(item => item.id.toUpperCase() == oAppProps.sSource.toUpperCase())) {
            oAppProps.onLoadError.push({
                key: 'source',
                code: -1,
                message: `El source introducido (${oAppProps.sSource.toUpperCase()}) no es válido. No existe el proyecto. Contacta con UBM.`,
                value: oAppProps.sSource.toUpperCase()
            });
        }
        renderMainApp(oAppProps, oRootComponent);
    }
}

const validateParameters = async (oProps) => {
    let newProps = {
        onLoadError: []
    }

    // Buscamos los parámetros requeridos en oProps. Si es undefined o no tiene valor, devolvemos el error correspondiente.
    errorCodes.forEach(err => {
        if (err.required && (typeof oProps[err.key] === 'undefined' || oProps[err.key]?.length <= 0)) {
            newProps.onLoadError.push({
                key: err.key,
                code: err.code,
                message: err.message,
                value: oProps[err.key] ?? undefined
            });
        }
    });
    if (newProps?.onLoadError?.length > 0) {
        return newProps;
    }

    // Si viene el teléfono, lo validamos junto con el client_name y client_lastname.
    // Si no envían un teléfono, el client_name y client-lastname nos dan igual.
    if (oProps?.phone?.length > 0) {
        let phoneValidationResponse = await ApiCalls.validatePhoneAPI(oProps.phone, oProps.country, oProps.company, oProps.unit, oProps.host)
            .then(oResponse => {
                return {
                    sPhone: oResponse.phone,
                    sCountry: oResponse.country
                }
            })
            .catch(sError => {
                let err = errorCodes.find(item => item.key == 'phone');
                newProps.onLoadError.push({
                    key: err.key,
                    code: err.code,
                    message: err.message.replace(new RegExp(`{{${err.key}}}`, 'g'), oProps?.phone),
                    value: oProps?.phone
                });
                return newProps;
            });
        newProps = { ...newProps, ...phoneValidationResponse};

        // Si han introducido un teléfono y es válido
        if (!newProps.onLoadError.find(item => item.key == 'phone')) {
            if (typeof oProps?.client_name == 'undefined' || oProps?.client_name?.length <= 0) {
                let err = errorCodes.find(item => item.key == 'client_name');
                newProps.onLoadError.push({
                    key: err.key,
                    code: err.code,
                    message: err.message,
                    value: oProps?.client_name
                });
            }
        }
    }

    return newProps;
}

const renderMainApp = (appProps, rootComponent) => {
    // TODO: Migrate to React 18
    // const container = document.getElementById('root');
    // const root = createRoot(container);
    // root.render(<App  appProps={appProps} />);
    ReactDOM.render(<App appProps={appProps} />, rootComponent);
}

if (is_electron()) {
    const oRootComponent = document.getElementById("root");
    oRootComponent.style.width = "100%";
    oRootComponent.style.position = "relative";
    oRootComponent.style.bottom = "auto";
    oRootComponent.style.overflow = "hidden";
    const oAppProps = {};
    renderMainApp(oAppProps, oRootComponent)
}

const destroyComponent = () => {
    ReactDOM.render(null, document.getElementById("root"));
}

window.initChat = init;
window.init = init;
window.closeChat = destroyComponent;

// Check if window is focused
window.focused = true;
window.onfocus = function () {
    window.focused = true;
};
window.onblur = function () {
    window.focused = false;
};
