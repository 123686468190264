import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Typography } from "@material-ui/core";
import { People, Person } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import Helper from "../../utils/Helper";
import AppContext from "../../AppContext";
import ApiCalls from "../../utils/APIRequests";

const PopoverItemsNewChat = ({ handleClosePopoverNewChat }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { constants } = Helper;
  const {
    assessor,
    debugApp,
    appConfig,
    setControlConfig,
    setContactAssessorList,
    setContactList
  } = useContext(AppContext);

  const onOpenContactList = value => {
    handleClosePopoverNewChat();
    if (value === constants.newChat) {
      setControlConfig(controlConfig => {
        return {
          ...controlConfig,
          viewUserList: false,
          viewContactList: true,
          showLoaderContactList: false,
          isNewGroup: false,
          isUpdateGroup: false
        };
      });
      apiGetContactList(value);
    } else {
      setControlConfig(controlConfig => {
        return {
          ...controlConfig,
          viewUserList: false,
          viewContactList: true,
          showLoaderContactList: true,
          isNewGroup: true,
          isUpdateGroup: false
        };
      });
      apiGetAssessorList();
      apiGetContactList(value);
    }
  };

  const apiGetAssessorList = () => {
    ApiCalls.getAssessors(appConfig.host, appConfig.source, appConfig.company, "")
      .then(resp => resp.json())
      .then(aoContactAssessorList => {
        setContactAssessorList(aoContactAssessorList);
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            showLoaderContactList: false
          };
        });
      })
      .catch(() => {
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            showLoaderContactList: false
          };
        });
      });
  };

  const apiGetContactList = type => {
    ApiCalls.getUserContacts(
      appConfig.host,
      assessor.email,
      debugApp.developer,
      appConfig.source,
      "",
      appConfig.company,
      appConfig.unit
    )
      .then(async response => {
        let aoUserContacts = await response.json();
        type === constants.newChat
          ? setContactList(aoUserContacts.filter(user => user.hasconversation === false))
          : setContactList(aoUserContacts);
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            showLoaderContactList: false
          };
        });
      })
      .catch((error) => {
        if (error === 204 || error == 'Error: 204') {
          // respuesta sin contenido
          if (appConfig.verbose) {
            console.log('La agenda está vacía');
          }
        }
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            showLoaderContactList: false
          };
        });
      });
  };

  return (
    <>
      <MenuItem
        className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
        value="newChat"
        onClick={() => onOpenContactList(constants.newChat)}
      >
        <Person color="primary" /> <Typography color="primary">{t("new.chat")}</Typography>
      </MenuItem>
      <MenuItem
        className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
        value="newGroup"
        onClick={() => onOpenContactList(constants.newGroup)}
      >
        <People color="primary" />
        <Typography color="primary">{t("newGroup")}</Typography>
      </MenuItem>
    </>
  );
};

const useStyles = makeStyles(() => ({
  menuItem: {
    padding: 15,
    gap: 15
  },
  menuItemDark: {
    padding: 15,
    gap: 15,
    backgroundColor: "#3e3e42",
    "&:hover, &:focus": {
      backgroundColor: "#2d2d30"
    }
  }
}));

PopoverItemsNewChat.propTypes = {
  handleClosePopoverNewChat: PropTypes.func
};

export default PopoverItemsNewChat;
