import { IconButton, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { EmojiEmotions } from "@material-ui/icons";

const EmojiButton = ({ client, setAnchorEl }) => {
  const { t } = useTranslation();

  const openEmojiPicker = oEvent => {
    if (client.phone) {
      setAnchorEl(oEvent.currentTarget);
    }
  };

  return (
    <Tooltip title={<Typography variant="body2">{t("emoji.tooltip")}</Typography>}>
      <IconButton
        disabled={!client.phone || (!client.active && client.active !== undefined)}
        component="span"
        onClick={openEmojiPicker}
      >
        <EmojiEmotions />
      </IconButton>
    </Tooltip>
  );
};

export default EmojiButton;

EmojiButton.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    compoundName: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.string,
    courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    courseName: PropTypes.string,
    phone: PropTypes.string,
    displayname: PropTypes.string,
    group: PropTypes.bool,
    active: PropTypes.bool
  }),
  setAnchorEl: PropTypes.func
};
