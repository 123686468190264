import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Tab, Tabs, Typography } from "@material-ui/core";

const TabsUserDefaultLists = ({ tabSelected, setTabSelected }) => {
  const { t } = useTranslation();

  const handleTabChange = (event, tabSelection) => {
    setTabSelected(tabSelection);
  };

  return (
    <Tabs
      value={tabSelected}
      onChange={handleTabChange}
      indicatorColor="primary"
      variant="fullWidth"
    >
      <Tab value="messages" label={<Typography variant="body2">{t("messages")}</Typography>} />
      <Tab value="audios" label={<Typography variant="body2">{t("audios")}</Typography>} />
    </Tabs>
  );
};

TabsUserDefaultLists.propTypes = {
  tabSelected: PropTypes.string,
  setTabSelected: PropTypes.func
};

export default TabsUserDefaultLists;
