import React, { useContext } from "react";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../AppContext";
import MenuButton from "./Buttons/MenuButton";
import ClientNameCopyButton from "./Buttons/ClientNameCopyButton";
import SettingsButton from "./Buttons/SettingsButton";
import SettingsPopover from "./SettingsPopover";
import MinimizedButton from "./Buttons/MinimizedButton";
import LogoutButton from "./Buttons/LogoutButton";
import GlobalSearchButton from "./Buttons/GlobalSearchButton";
import ClosedButton from "./Buttons/ClosedButton";

const InfoBar = () => {
  const classes = useStyles();
  const { appConfig, assessor } = useContext(AppContext);

  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const bOpenSettings = Boolean(anchorElSettings);
  const idSettings = bOpenSettings ? "simple-popover" : undefined;

  return (
    <div className={classes.rootDark} style={{ width: "100%" }}>
      <AppBar position="static" className={classes.headerMessageList}>
        <Toolbar>
          <MenuButton />
          <ClientNameCopyButton />
          <GlobalSearchButton />

          <SettingsButton setAnchorElSettings={setAnchorElSettings} />
          <SettingsPopover
            idSettings={idSettings}
            bOpenSettings={bOpenSettings}
            anchorElSettings={anchorElSettings}
            setAnchorElSettings={setAnchorElSettings}
          />
          <MinimizedButton />
          {appConfig.showclose && <ClosedButton />}
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <Box
        className={assessor.email === appConfig.myUser.email ? classes.invisible : classes.blueBar}
      />
    </div>
  );
};

export default InfoBar;

const useStyles = makeStyles(() => ({
  rootDark: {
    backgroundColor: ""
  },
  headerMessageList: {
    backgroundColor: "#065D54 !important",
    borderRadius: "10px 10px 0 0"
  },
  invisible: {
    display: "none"
  },
  blueBar: {
    width: "100%",
    height: 5,
    backgroundColor: "#03a9f4",
    "&:hover": {
      backgroundColor: "primary.main",
      opacity: [0.9, 0.8, 0.7]
    }
  }
}));
