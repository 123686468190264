import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { InputBase } from "@material-ui/core";
import AppContext from "../../../AppContext";

const InputBaseDefaultMessages = ({
  textDefaultMessage,
  setTextDefaultMessage,
  textDefaultMessageTitle,
  setTextDefaultMessageTitle
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { appConfig } = useContext(AppContext);

  return (
    <>
      <InputBase
        className={classes.inputTextDefaultMessageTitle}
        value={textDefaultMessageTitle}
        onChange={event => setTextDefaultMessageTitle(event.target.value)}
        placeholder={t("writeTitle")}
        inputProps={
          appConfig.darkTheme
            ? {
                className: classes.inputDark
              }
            : { className: classes.input }
        }
        autoFocus
      />
      <InputBase
        className={classes.inputTextDefaultMessage}
        value={textDefaultMessage}
        onChange={event => setTextDefaultMessage(event.target.value)}
        multiline={true}
        placeholder={t("writeMessage")}
        inputProps={
          appConfig.darkTheme
            ? {
                className: classes.inputDark
              }
            : { className: classes.input }
        }
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  input: {
    alignSelf: "start",
    marginLeft: 10
  },
  inputDark: {
    alignSelf: "start",
    color: "#fff",
    marginLeft: 10
  },
  inputTextDefaultMessage: {
    border: "1px solid #f9f9f9",
    borderRadius: 5,
    minWidth: 500,
    minHeight: 300
  },
  inputTextDefaultMessageTitle: {
    border: "1px solid #f9f9f9",
    borderRadius: 5,
    minWidth: 500,
    marginBottom: 15,
    fontSize: 20
  }
}));

InputBaseDefaultMessages.propTypes = {
  textDefaultMessage: PropTypes.string,
  setTextDefaultMessage: PropTypes.func,
  textDefaultMessageTitle: PropTypes.string,
  setTextDefaultMessageTitle: PropTypes.func
};

export default InputBaseDefaultMessages;
