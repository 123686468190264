import React, { useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AppContext from "../../../AppContext";

const DialogTranscription = ({
  openDialogTranscription,
  setOpenDialogTranscription,
  transcription
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { appConfig } = useContext(AppContext);

  const handleCloseDialog = () => {
    setOpenDialogTranscription(false);
  };

  return (
    <Dialog open={openDialogTranscription} onClose={handleCloseDialog} fullWidth={true}>
      <DialogTitle className={classes.dialogTitle}>{t("transcription")}</DialogTitle>
      <DialogContent className={appConfig.darkTheme ? classes.dialogDark : classes.dialog}>
        <Typography variant="body1">{transcription}</Typography>
      </DialogContent>

      <DialogActions className={appConfig.darkTheme ? classes.dialogDark : ""}>
        <Button onClick={handleCloseDialog} color="primary">
          <Typography variant="caption" color="primary">
            {t("close")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTranscription;

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  dialogDark: {
    backgroundColor: "#3e3e42",
    color: "#fff",
    paddingTop: 20
  },
  dialog: {
    paddingTop: 20
  }
}));

DialogTranscription.propTypes = {
  openDialogTranscription: PropTypes.bool,
  setOpenDialogTranscription: PropTypes.func,
  transcription: PropTypes.string
};
