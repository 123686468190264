import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../AppContext";
import ApiCalls from "../../utils/APIRequests";
import Helper from "../../utils/Helper";
import MinimizedChat from "../MinimizedChat/MinimizedChat";
import MaximizedChat from "../MaximizedChat/MaximizedChat";
import jQuery, { type } from "jquery";
import { fetchToken, onMessageListener } from "../../firebaseConfig";
import AlertTitleNotification from "../Alert/AlertTitleNotification";
import styles from "./Customisation.module.css";
import DialogInfo from "../InfoBar/DialogInfo";
import useMQTT from "../../hooks/useMQTT";
import DialogGlobalSearch from "../Dialogs/GlobalSearch/DialogGlobalSearch";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


//Global variables
import {
  NOTIFICATION_RECEIVED,
  NOTIFICATION_SERVICE_ERROR,
  NOTIFICATION_SERVICE_STARTED,
  START_NOTIFICATION_SERVICE,
  TOKEN_UPDATED
} from "electron-push-receiver/src/constants";

import is_electron from "is-electron";

let bCache = false;
let bCacheUsers = false;

let getDelegatesIntervalId = null;
let disableGetDelegates = false;

let messagesController = null;
let messagesTask = null;
let messagesSignal = null;

const Chat = ({chatProps}) => {
  const classes = useStyles();
  const [title, setTitle] = useState("Titulo");
  const [message, setMessage] = useState("Mensaje");
  const [notificationPayload, setNotificationPayload] = useState({
    data: {
      client_phone: null
    }
  });
  const [auxClientPhone, setAuxClientPhone] = useState("")


  const [openNotification, setOpenNotification] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState("default");
  const {
    client,
    assessor,
    appConfig,
    setAppConfig,
    controlConfig,
    setControlConfig,
    userList,
    setUserList,
    setFilteredUserList,
    setMessages,
    debugApp,
    newChatItem,
    setNewChatItem,
    setDelegates,
    saveSettings,
    userSelected,
    setCompanyTags,
    setSkipSaveAppConfig,
    setInitNewMQTTWS,
    MQTTConnectionsList,
    stopDelegating, 
    setStopDelegating,
    selectedTag,
    needUpdateUserLayout,
    setNeedUpdateUserLayout,
    setGetMessagesByEmailCalled
  } = useContext(AppContext);
  const { filterArraySearchString, filterUsersByTag, constants, getSourcesFromMessages } = Helper;
  const [refreshUserState, setRefreshUserState] = useState({fetchTimestamp: false, isRunning: false});
  const [lastDateReceived, setLastDateReceived] = useState(null);


  const verbose = appConfig.verbose;
  // Revisamos los permisos para volver a lanzar el getToken del firebase
  if (Notification.permission === "granted" && notificationPermission === "default" && (typeof appConfig.notifications == 'undefined' || !!appConfig.notifications)) {
    setNotificationPermission("granted");
  }

  // Token para notificaciones con firebase
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const getToken = async () => {
        try {
          const firebaseToken = await fetchToken();
          if (!debugApp.developer && !is_electron() && firebaseToken) {
            console.log('%cSave Notification Token', 'color: #00FF00', firebaseToken);
            await ApiCalls.addNotificationToken(appConfig.host, assessor.email, firebaseToken, appConfig.source, appConfig.company)
              .then(() => {
                setAppConfig(oAppConfig => {
                  return {
                    ...oAppConfig,
                    tokenWebFirebase: firebaseToken
                  };
                });
              })
              .catch(() => {
                setAppConfig(oAppConfig => {
                  return {
                    ...oAppConfig,
                    tokenWebFirebase: firebaseToken
                  };
                });
              });
          }
        } catch (error) {
          console.log('Se ha producido un error al registrar el service worker de firebase: ', error);
        }
      };

      if (Notification.permission === "granted" && (typeof appConfig.notifications == 'undefined' || (typeof appConfig.notifications != 'undefined' && appConfig.notifications == true))) {
        getToken();
        if (typeof appConfig.notifications == 'undefined') {
          setAppConfig(oConfig => {
            return {
              ...oConfig,
              notifications: true
            }
          });
        }
      }
      if (Notification.permission !== "granted" && (typeof appConfig.notifications == 'undefined' || (typeof appConfig.notifications != 'undefined' && appConfig.notifications == true))) {
        Notification.requestPermission().then(function (permission) {
          let granted = false;
          if (permission === "granted") {
            getToken();
            granted = true;
          }
          ApiCalls.saveUserSettings(assessor.email, {...appConfig, notifications: granted});
          setAppConfig(oConfig => {
            return {
              ...oConfig,
              notifications: granted
            }
          });
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [Notification.permission, notificationPermission]);

  //Iniciamos los servicios para las notificaciones push de electron
  const initializationNotificationPushElectron = () => {
    const { ipcRenderer } = window.require("electron");

    // Listen for service successfully started
    ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, (_, token) => {
      ApiCalls.addNotificationToken(appConfig.host, assessor.email, token, appConfig.source, appConfig.company).then(() => {
        setAppConfig(oAppConfig => {
          return {
            ...oAppConfig,
            tokenElectron: token
          };
        });
      });
    });

    // Handle notification errors
    ipcRenderer.on(NOTIFICATION_SERVICE_ERROR, (_, error) => {
      throw error;
    });

    // Send FCM token to backend
    ipcRenderer.on(TOKEN_UPDATED, (_, token) => {
      ApiCalls.addNotificationToken(appConfig.host, assessor.email, token, appConfig.source, appConfig.company).then(() => {
        setAppConfig(oAppConfig => {
          return {
            ...oAppConfig,
            tokenElectron: token
          };
        });
      });
    });

    ipcRenderer.on(NOTIFICATION_RECEIVED, (_, serverNotificationPayload) => {
      if (serverNotificationPayload.notification.body) {
        let myNotification = new Notification(serverNotificationPayload.notification.title, {
          body: serverNotificationPayload.notification.body
        });

        myNotification.onclick = () => {};
      } else {
        // payload has no body, so consider it silent (and just consider the data portion)
      }
    });

    // Start service
    const senderId = "1019683924942"; // <-- replace with FCM sender ID from FCM web admin under Settings->Cloud Messaging
    ipcRenderer.send(START_NOTIFICATION_SERVICE, senderId);
  };

  // Guardado de parametros y llamada a la configuración del cliente
/*   useEffect(() => {
    if (!jQuery.isEmptyObject(appConfig)) {
      setSkipSaveAppConfig(true);
      saveSettings();
    }
  }, [
    appConfig.source,
    
    appConfig.date,
    appConfig.minimized,
    appConfig.size,
    appConfig.width,
    appConfig.height,
    appConfig.darkTheme,
    appConfig.default_messages
  ]); */

  // Añado esta llamada a App.js
/*   useEffect(() => {
    ApiCalls.getClientConfig(appConfig.host, appConfig.company, appConfig.sourceFilter)
      .then(async response => {
        let oConfig = await response.json();
        setAppConfig(appConfig => {
          return {
            ...appConfig,
            loggedIn: true,
            clientConfig: oConfig,
            isNotCallGetDelegates: Helper.removeCallDelegates(oConfig, appConfig.source)
          };
        });
      })
      .catch(() => {});
  }, [appConfig.sourceFilter, appConfig.source]); */


  // Saber si cuando iniciamos la app es desktop o no y aplicar unas configuraciones u otras.
  useEffect(() => {
    if (is_electron()) {
      initializationNotificationPushElectron();
      if (!controlConfig.isElectron) {
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            isElectron: true
          };
        });
      }
    }
    return () => {
    };
  }, []);

  useEffect(() => {
    refreshUsers();
  }, [appConfig.isNotCallGetDelegates]);

  //Reset del cache y refresco de la lista de usuarios (UserList)
  useEffect(() => {
    setCacheUsers(false);
    refreshUsers();
  }, [appConfig.source, assessor.email, appConfig.size]);


  let interval;

  const initChatRoutine = async bPopulateChatArtifacts => {
    if (client.phone && !controlConfig.showLoaderMessage) {
      setShowLoaderMessage(true);
    }

    setCacheMessages(false);
    setCacheUsers(false);

    if (client.phone && !client.group) {
      refreshMessages(bPopulateChatArtifacts);
    } else if (client.phone && client.group) {
      refreshMessages(bPopulateChatArtifacts);
    } else { 
      if (!appConfig.usews) {
        refreshOnlyUserList();
        setMessages([]);
      }
    }
  };

  const setShowLoaderMessage = bShowLoaderMessage => {
    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        showLoaderMessage: bShowLoaderMessage
      };
    });
  };

  const setShowLoaderUserList = bShowLoaderList => {
    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        showLoaderUserList: bShowLoaderList
      };
    });
  };

  const refreshOnlyUserList = () => {
    clearInterval(interval);
    interval = setInterval(() => {
      if ((window && window.focused) || debugApp.alwaysRefresh || is_electron()) {
        refreshUsers();
      }
    }, constants.refreshInterval);
  };

  const createIntervalDelegates = () => {
    if (verbose) {
      console.log(`%cChat.js: createIntervalDelegates: Disable get delegates for 1 hour.`, `color: orange;`);
    }
    disableGetDelegates = true;
    setDelegates([]);
    getDelegatesIntervalId = setInterval(() => {
      if (verbose) {
        console.log(`%cChat.js: createIntervalDelegates: Re-enabled get delegates.`, `color: orange;`);
      }
      disableGetDelegates = false;
      clearInterval(getDelegatesIntervalId);
    }, 1 * 60 * 60 * 1000);
  };

  const getDelegates = () => {
    if (appConfig.isNotCallGetDelegates && !disableGetDelegates) {
      ApiCalls.getUserDelegate(
        appConfig.host,
        appConfig.myUser.email,
        debugApp.developer,
        appConfig.source,
        appConfig.company,
        appConfig.unit
      )
        .then(async response => {
          let aoDelegates = await response.json();
          if (aoDelegates && !jQuery.isEmptyObject(aoDelegates)) {
            if (aoDelegates.substitutes?.length > 0) {
              setDelegates(aoDelegates.substitutes);
            } else {
              createIntervalDelegates();
            }
            if (aoDelegates.me) {
              setAppConfig(oAppConfig => {
                return {
                  ...oAppConfig,
                  myUser: {
                    ...oAppConfig.myUser,
                    unread: aoDelegates.me
                  }
                };
              });
            } else {
              setAppConfig(oAppConfig => {
                return {
                  ...oAppConfig,
                  myUser: {
                    ...oAppConfig.myUser,
                    unread: 0
                  }
                };
              });
            }
          } else {
            createIntervalDelegates();
            setAppConfig(oAppConfig => {
              return {
                ...oAppConfig,
                myUser: {
                  ...oAppConfig.myUser,
                  unread: 0
                }
              };
            });
          }
        })
        .catch(() => {
          createIntervalDelegates();
        });
    }
  };

  const markReadMessages = (phone, source, courseId, company, sEmail) => {
    if (appConfig.minimized && !appConfig.usews) {
      refreshUsers();
    } else {
      setUserList(userList => {
        let newUserList = userList.map(oUser => {
          if (oUser.key === client.key) {
            oUser.unread = 0;
            oUser.markasunread = false;
          }
          return oUser;
        });
        return newUserList;
      });

      const _company = typeof client.company != 'undefined' && client.company.length > 0 ? client.company : appConfig.company;
      const _unit = typeof client.unit != 'undefined' && client.unit.length > 0 ? client.unit : appConfig.unit;


      ApiCalls.markReadMessages(
        appConfig.host,
        phone,
        source,
        courseId,
        _company,
        _unit,
        sEmail
      )
        .then(() => {
          /* if (!appConfig.usews) {
            refreshUsers();
          } */
        })
        .catch(() => {
          //do nothing
        });
    }
  };

  const refreshMessages = (bPopulateChatArtifacts, intervalId) => {

    let sUserKey = localStorage.getItem("userKey");
    let sUserPhone = localStorage.getItem("userPhone");
    let userKeyGetMessages = localStorage.getItem("userKeyGetMessages");
    let userPhoneGetMessages = localStorage.getItem("userPhoneGetMessages");

    if(sUserKey == userKeyGetMessages) {
      return false;
    }

    localStorage.setItem("userKeyGetMessages", `${client.key}`);
    localStorage.setItem("userPhoneGetMessages", client.phone);
    localStorage.setItem("userCourseGetMessages", client.courseId);

    if (messagesController && messagesController.abort) {
      messagesController.abort("Request was cancelled.");
    }

    messagesController = new AbortController();
    messagesSignal = messagesController.signal;

    messagesTask = new Promise((resolve, reject) => {
      const company = typeof client.company != 'undefined' && client.company.length > 0 ? client.company : appConfig.company;
        ApiCalls.getMessages(
          appConfig.host,
          client.phone,
          appConfig.source,
          appConfig.filter,
          debugApp.developer,
          bCache,
          company,
          appConfig.unit,
          assessor.email,
          appConfig.encryptionkey,
          messagesSignal
        )
          .then(async response => {
            let aoNewMessages = await response.json();
            let sUserKey = localStorage.getItem("userKey");
            let sUserPhone = localStorage.getItem("userPhone");

            const asUniqueUserKeys = [...new Set(aoNewMessages.map(item => item.conversationid))];
            let bKeyExists = false;
            asUniqueUserKeys.forEach(sKey => {
              if (sKey === sUserKey) {
                bKeyExists = true;
              }
            });

            let bPhoneExists = sUserPhone === aoNewMessages[0].client_phone;

            if ((bKeyExists && !!sUserKey) || bPhoneExists) {
              setMessages(aoNewMessages);

              setCacheMessages(true);
              if (!appConfig.minimized) {
                markReadMessages(
                  client.phone,
                  appConfig.source,
                  client.courseId,
                  appConfig.company,
                  assessor.email
                );
              }
              if (bPopulateChatArtifacts) {
                // initiateInterval();
              }
            } else {
              clearInterval(intervalId);
            }

            resolve("Petición resuelta correctamente.");
          })
          .catch(nStatus => {
            if (nStatus === 404 || nStatus === 204 || nStatus == 'Error: 204') {
              setMessages([]);
              if (bPopulateChatArtifacts) {
                // initiateInterval();
              }
            }
            reject("Petición cancelada por interacción posterior.");
            //Do not update
          })
          .finally(() => {
            setTimeout(() => {
              setShowLoaderMessage(false);
              setShowLoaderUserList(false);
            }, 0);
          });
    });
    messagesTask
      .then(result => {
        if (verbose) { 
          console.log(`%cChat.js: refreshMessages: Resolved: ${result}`, `color: orange;`)
        }
      })
      .catch(error => {
        if (verbose) {
          console.log(`%cChat.js: refreshMessages: Rejected: ${error}`, `color: orange;`)
        }
      });
  };

  const handleMessage = async (oMessage) => {
    if (verbose) {
      console.log(`%cChat.js: handleMessage: Message: `, `color: orange;`);
      console.log(oMessage);
    }

    const userKey = localStorage.getItem("userKey");
    if (userKey && userKey === oMessage.conversationid) {
      setMessages(messages => {
        const bExists = messages.some(message => message.ubm_id === oMessage.ubm_id);
        if (bExists) {
          return messages.map(message => (message.ubm_id === oMessage.ubm_id ? oMessage : message));
        }
        return [...messages, oMessage];
      });
    }

    /**
     * Comprobar que  el swervice worker de firebase no existe, que
     * la pestaña no es visible para el usuario, que tiene las
     * notificaciones activadas (o que no están definidas pero si
     * permitidas en el navegador) y que es un mensaje entrante
     * 
     * Lanzar notificación
     * 
     * @README
     * document.hidden: https://developer.mozilla.org/en-US/docs/Web/API/Document/hidden#browser_compatibility
     * Notification: https://developer.mozilla.org/en-US/docs/Web/API/Notification
     */

    const serviceWorkerExist = await navigator.serviceWorker.getRegistrations().then(registrations => {
      const findSW = registrations.find((sw) => sw.active.scriptURL.indexOf('firebase-messaging-sw.js') >= 0 && sw.active.state == 'activated');
      return !findSW ? false : true;
    });
    
    if (
      serviceWorkerExist === false
      && document.hidden === true 
      && typeof appConfig.notifications != 'undefined' 
      && (Notification.permission === "granted" && (typeof appConfig.notifications == 'undefined' || (typeof appConfig.notifications != 'undefined' && appConfig.notifications == true)))
      && oMessage.direction == 'received'
    ) {
      let texto = '';
      try {
        if (typeof oMessage.text != 'undefined' && oMessage.text.length > 0) {
          texto = `${oMessage.text}`;
        }
        if (typeof oMessage.mime != 'undefined' && oMessage.mime == 'audio/mpeg') {
          texto = typeof oMessage.transcription != 'undefined' && oMessage.transcription.length > 0 ? oMessage.transcription : 'audio';
        } else if (typeof oMessage.mime != 'undefined' && oMessage.mime.length > 0) {
          texto = 'Multimedia';
        } 
      } catch (error) {
        texto = error;
      }

      const messageFrom = typeof oMessage.full_name != 'undefined' && oMessage.full_name.length > 0 ? oMessage.full_name : 'Nuevo mensaje';
      new Notification(`UBM - ${messageFrom}`, {
        body: texto
      });
    }
  };

  const handleConversation = oConversation => {
    if (verbose) {
      console.log(`%cChat.js: handleConversation: Conversation: `, `color: orange;`);
      console.log(oConversation);
    }

    const userKey = localStorage.getItem("userKey");
    const userPhone = localStorage.getItem("userPhone");
    const userCourse = localStorage.getItem("userCourse");

    setUserList(_userList => {
      // quito la conversacion que recibo por mqtt del listado de conversaciones
      let filteredList = _userList.filter(item => item.key != oConversation.key);

      // si tengo abierta la conversación de mqtt, pongo unread a 0 (lo estamos leyendo)
      if (userKey && userKey === oConversation.key) {
        oConversation.unread = 0;
      }

      // añado la conversación de mqtt al listado filtrado de conversaciones y la pongo la primera
      let allConversations = [oConversation, ...filteredList]
      return allConversations;
    })
    

    if (userKey && userKey === oConversation.key) {
      markReadMessages(userPhone, appConfig.source, userCourse, appConfig.company, assessor.email);
    }
  };

  const handleConfiguration = oConfiguration => {
    // Si el mensaje que nos viene por websocket es el que hemos enviado nosotros, lo ignoramos.
    if (oConfiguration.currentTabId == appConfig.currentTabId) {
      return true;
    }
    
    let checkKeys = ['default_messages', 'userTags'];
    let needSave = false;
    let appConfigNew = appConfig;

    for (const [key, value] of Object.entries(oConfiguration)) {
      if (
          checkKeys.includes(key) 
          && (typeof appConfigNew[key] == 'undefined' || (typeof appConfigNew[key] != 'undefined' && value != appConfigNew[key]))
        ) {
        appConfigNew[key] = value;
        needSave = true;
      }
    }
    // Sobreescribimos la tabId con la nuestra para operar con los datos
    appConfigNew.currentTabId = appConfig.currentTabId;
    if (needSave) {
      setSkipSaveAppConfig(true);
      setAppConfig(appConfigNew);
    }
  };

  const handleConversationTags = oUpdatedTags => {
    if (verbose) {
      console.log(`%cChat.js: handleConversationTags: oUpdatedTags `, `color: orange;`);
      console.log(oUpdatedTags);
    }

    setUserList((oUserList) => {
      let newTagsUser = oUserList.map(oUser => {
        if (oUser.key === oUpdatedTags.key) {
          if (oUpdatedTags.company_tags) {
            if (typeof oUser.tags == 'undefined') {
              oUser.tags = {}
            }
            oUser.tags.company_tags = oUpdatedTags.company_tags;
          }
          if (oUpdatedTags.user_tags) {
            if (typeof oUser.tags == 'undefined') {
              oUser.tags = {}
            }
            oUser.tags.user_tags = oUpdatedTags.user_tags;
          }
        }
        return oUser;
      })
      return newTagsUser
    });
  };

  useEffect(()=> {
    const interval = setInterval( () => {
        let timestamp = Date.now();
        if (isNaN(timestamp)) {
          let _date = new Date(timestamp);
          timestamp = _date.valueOf();
        }
        if (lastDateReceived != null && timestamp - lastDateReceived > 55000){
          // <- Falla la conexión  
          console.log('%cLa conexión ha fallado', 'color:red')
          setInitNewMQTTWS(true);
          updateUserLayout();
        }
    },15000)
    return () => {clearInterval(interval);}
}, [])

  const onMessageReceived = oNewMessage => {
    if (appConfig.usews) {

      if (oNewMessage && oNewMessage.date) {
        setLastDateReceived(oNewMessage.date);
      }

      //A message comes
      if (oNewMessage && oNewMessage.message) {
        handleMessage(oNewMessage.message);
      }

      //A conversation comes
      if (oNewMessage && oNewMessage.conversation) {
        handleConversation(oNewMessage.conversation);
      }

      //A configuration object comes
      if (oNewMessage && oNewMessage.config) {
        handleConfiguration(oNewMessage.config);
      }

      //Conversation updated tags object comes
      if (oNewMessage && oNewMessage.updatetags) {
        handleConversationTags(oNewMessage.updatetags);
      }
    }
  };

  const setSelectedIndex = sPhone => {
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        selectedIndex: sPhone
      };
    });
  };

  const refreshUsers = () => {
    getDelegates();
    if (verbose) {
      console.log(`%cChat.js: refreshUsers: Called refreshUsers`, `color: orange;`);
    }
    let timeNow = Date.now()
    let lastExecTime = refreshUserState.fetchTimestamp !== false ? refreshUserState.fetchTimestamp : Date.now()
    let dateDiffMS = Math.abs(timeNow - lastExecTime) + 100;
    
    if (
      refreshUserState.isRunning === true 
      && refreshUserState.fetchTimestamp !== false 
      && dateDiffMS > (constants.refreshInterval * 3)
      ) {
      refreshUserState.isRunning = false
      if (verbose) {
        console.log(`%cChat.js: refreshUsers: Reset last fetch timestamp ${refreshUserState.fetchTimestamp} to ${timeNow} (now)`, 'color: orange;')
      }
      refreshUserState.fetchTimestamp = timeNow
      
    }

    const shouldExec = refreshUserState.isRunning !== true && (dateDiffMS > constants.refreshInterval || !refreshUserState.fetchTimestamp);

    if (verbose) {
      let aux = !refreshUserState.fetchTimestamp;
      console.log(`%cChat.js: refreshUsers: %cexecute refresh -> ${shouldExec} %c(isRunning: ${refreshUserState.isRunning} !== true [%c${refreshUserState.isRunning !== true}%c] && (dateDiffMS: ${dateDiffMS} > ${constants.refreshInterval} [%c${dateDiffMS > constants.refreshInterval}%c] || !refreshUserState.fetchTimestamp: ${refreshUserState.fetchTimestamp} [%c${aux}%c])`
        , `color: orange;`
        , `color: ${shouldExec === true ? 'green' : 'red'}`
        , `color: orange;`
        , `color: ${refreshUserState.isRunning !== true ? 'green' : 'red'}`
        , `color: orange;`
        , `color: ${dateDiffMS > constants.refreshInterval ? 'green' : 'red'}`
        , `color: orange;`
        , `color: ${!refreshUserState.fetchTimestamp ? 'green' : 'red' }`
        , `color: orange;`)
    }


    if (shouldExec) {
      if (verbose) {
        console.log(`%cChat.js: refreshUsers: Calling getMessagesByEmail`, `color: orange;`)
      }
      refreshUserState.fetchTimestamp = timeNow
      refreshUserState.isRunning = true
      
      updateUserLayout();
    }
  };

  const updateUserLayout = () => {
    ApiCalls.getUsers(
      appConfig.host,
      assessor.email,
      debugApp.developer,
      bCacheUsers,
      appConfig.sourceFilter,
      appConfig.company,
      appConfig.unit,
      appConfig.encryptionkey
    )
      .then(response => response.json())
      .then(aoUsers  => {
        setUserList(aoUsers);
        if (
          controlConfig.searchUserList !== "" ||
          controlConfig.tabSelection === constants.tabUnread
        ) {
          let filteredArr = filterArraySearchString(
            aoUsers,
            controlConfig.searchUserList,
            ["user", "course_name", "phone"],
            controlConfig.tabSelection
          )
          let newFilteredUserList = filterUsersByTag(filteredArr, selectedTag)
          setFilteredUserList(newFilteredUserList);
        } else {
          let newFilteredUserList = filterUsersByTag(aoUsers, selectedTag)
          setFilteredUserList(newFilteredUserList);
        }

        if (client.phone && !client.key) {
          setSelectedIndex(client.phone);
          aoUsers.forEach(oUser => {
            if (oUser.phone === client.phone) {
              userSelected(oUser);
            }
          });
        } else if (client.phone && client.key.length > 0) {
          refreshMessages(false);
        }
        setCacheUsers(true);
        setShowLoaderUserList(false);
        if (!appConfig.sources || appConfig.sources.length === 0) {
          setAppConfig(oAppConfig => {
            return {
              ...oAppConfig,
              sources: getSourcesFromMessages(aoUsers)
            };
          });
        }

        if (newChatItem && !jQuery.isEmptyObject(newChatItem)) {
          aoUsers.forEach(oUser => {
            if (oUser.phone === newChatItem.phone) {
              setNewChatItem({});
              setAppConfig(oAppConfig => {
                return {
                  ...oAppConfig,
                  selectedIndex: oUser.phone
                };
              });
              return false;
            }
          });
        }
        
      })
      .catch(error => {
        if (error === 404 || error === 204 || error == 'Error: 204') {
          setShowLoaderUserList(false);
          setUserList([]);
        }
      })
      .finally(() => {
        refreshUserState.isRunning = false;
        setGetMessagesByEmailCalled(true);
      });
  }

  const setCacheUsers = bNewCacheUsers => {
    bCacheUsers = bNewCacheUsers;
  };

  const setCacheMessages = bCacheMessages => {
    bCache = bCacheMessages;
  };

  //Metodo que llama al onMessage del firebase
  onMessageListener().then(payload => {
    const { notification } = payload;
    setNotificationPayload(payload);

    let phone = '';
    if (
      typeof notificationPayload != 'undefined' 
      && typeof notificationPayload.data != 'undefined' 
      && typeof notificationPayload.data.client_phone != 'undefined'
      && notificationPayload.data.client_phone != null
      && notificationPayload.data.client_phone.length > 0
    ) {
      phone = notificationPayload.data.client_phone;
      setAuxClientPhone(phone);
    } else {
      setAuxClientPhone(phone);
    }

    setOpenNotification(true);
    setTitle(notification.title);
    setMessage(notification.body);
  });

  const myMQTT = useMQTT({ onMessageReceived, refreshUsers, refreshMessages });

  // ANTES: Inicio de la rutina del setInterval para actualizar el componente
  // AHORA: Activamos los sockets de la conversación que tenemos en marcha y las notificaciones de los otros chats
  useEffect(() => {
    initChatRoutine(true).then(() => {
      setUserList(userList => {
        let newUserList = userList.map(oUser => {
          if (typeof oUser?.key != 'undefined' && oUser.key === client.key) {
            oUser.unread = 0;
          }
          return oUser;
        });
        return newUserList;
      });

      if (appConfig.myUser.email != assessor.email) {
        if (verbose) {
          console.log(`%cChat.js: useEffect[client, appConfig]: ESTAMOS SUPLANTANDO`, `color: orange;`)
        }
        if (appConfig.usews == true && MQTTConnectionsList.to_start.filter( elem => elem.email == assessor.email).length <= 0 && MQTTConnectionsList.connected.filter( elem => elem.email == assessor.email).length <= 0) {
          if (verbose) {
            console.log(`%cChat.js: useEffect[client, appConfig]: Usamos WS y no tenemos una conexión levantada. Levantamos conexión para ${assessor.email}`, `color: orange;`)
          }
          setInitNewMQTTWS(true);
          updateUserLayout();
        }
      }

      if (stopDelegating == true) {
        setStopDelegating(false)
        updateUserLayout()
      }

    });
    return () => {
      clearInterval(interval);
    };
  }, [client, appConfig.source/* , appConfig.minimized */, assessor.email, appConfig.usews]);

  useEffect(()=>{
    if (verbose) {
      console.log(`%cneedUpdateUserLayout: ${needUpdateUserLayout}`, 'color: #00FF00');
    }
    if (needUpdateUserLayout === true) {
      updateUserLayout();
      setNeedUpdateUserLayout(false);
    }
  }, [needUpdateUserLayout]);

  useEffect(() => {
    chatProps?.onLoadError?.map(err => {
      toast.error(err.message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    });
  }, [chatProps.onLoadError])

  return (
    <div
      id={appConfig.darkTheme ? styles["proeduca-messenger-dark"] : styles["proeduca-messenger"]}
    >
      <div id="container" className={classes.componentContainer}>
        <AlertTitleNotification
          title={title}
          message={message}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
          clientPhone={auxClientPhone}
        />
        <MaximizedChat setCacheUsers={setCacheUsers} handleConversation={handleConversation} />
        <MinimizedChat />
      </div>
      <DialogInfo />
      <DialogGlobalSearch />
      <ToastContainer position="bottom-left" newestOnTop />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  componentContainer: {
    display: "contents",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%"
  }
}));

export default Chat;
