import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
import styles from "../Chat/Customisation.module.css";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import jQuery from "jquery";
import FolderIcon from "@material-ui/icons/Folder";
import ApiCalls from "../../utils/APIRequests";

const ManageTagsDialog = ({ handleConversation }) => {
  const classes = useStyles();
  const { appConfig, controlConfig, setControlConfig, companyTags, assessor, userList } = useContext(AppContext);
  const { t } = useTranslation();
  const [tagListItems, setTagListItems] = useState([]);

  const handleClose = () => {
    setControlConfig(() => {
      return {
        ...controlConfig,
        openManageTagsDialog: false
      };
    });
  };

  const handleSaveConversation = () => {
    let oUserTags = [];
    tagListItems.map(oTag => {
      if (oTag.type === "user" && oTag.selected) {
        oUserTags.push(oTag.key);
      }
    });
    let oCompanyTags = [];
    tagListItems.map(oTag => {
      if (oTag.type === "company" && oTag.selected) {
        oCompanyTags.push(oTag.key);
      }
    });
    let oConversation = {
      ...controlConfig.selectedUserTags,
      tags: {
        user_tags: oUserTags,
        company_tags: oCompanyTags
      }
    };

    ApiCalls.updateConversationTags(
      appConfig.host,
      appConfig.source,
      appConfig.company,
      assessor.email,
      oConversation
    ).then(() => {
      handleConversation(oConversation);
      setControlConfig(() => {
        return {
          ...controlConfig,
          openManageTagsDialog: false,
          selectedUserTags: {}
        };
      });
    });
  };

  useEffect(() => {
    let selectedConversationTags = controlConfig.selectedUserTags;
    let tagListItemsArray = [];
    if (selectedConversationTags && !jQuery.isEmptyObject(selectedConversationTags)) {
      if (companyTags?.length > 0) {
        companyTags.forEach(oTag => {
          let bExists = selectedConversationTags.tags?.company_tags.indexOf(oTag.key) >= 0 ? true : false;
          tagListItemsArray.push({ ...oTag, type: "company", selected: bExists });
        });
      }

      if (appConfig.userTags?.length > 0) {
        appConfig.userTags.forEach(oTag => {
          let bExists = selectedConversationTags.tags?.user_tags.indexOf(oTag.key) >= 0 ? true : false;
          tagListItemsArray.push({ ...oTag, type: "user", selected: bExists });
        });
      }
    }

    setTagListItems(tagListItemsArray);
  }, [controlConfig.selectedUserTags, companyTags, userList]);

  const handleCheckTag = oTag => {
    if (oTag && !jQuery.isEmptyObject(oTag) && tagListItems && tagListItems.length > 0) {
      let tagListItemsArray = tagListItems?.map(oTagList => {
        if (oTagList.key === oTag.key) {
          oTagList.selected = !oTagList.selected;
        }
        return { ...oTagList };
      });
      setTagListItems(tagListItemsArray);
    }
  };

  return (
    <Dialog
      open={controlConfig.openManageTagsDialog}
      maxWidth="sm"
      id={appConfig.darkTheme ? styles["proeduca-messenger-dark"] : styles["proeduca-messenger"]}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        {t("manageTags")}
      </DialogTitle>

      <DialogContent className={appConfig.darkTheme ? classes.dialogDark : classes.dialog}>
        <List>
          {tagListItems?.length > 0 &&
            tagListItems.map(oTag => {
              return (
                <ListItem key={oTag.key}>
                  <ListItemIcon>
                    {oTag.type === "company" && <FolderSpecialIcon />}
                    {oTag.type === "user" && <FolderIcon />}
                  </ListItemIcon>
                  <ListItemText primary={oTag.tag} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      checked={oTag.selected}
                      onClick={() => handleCheckTag(oTag)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      </DialogContent>

      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
      >
        <Button onClick={handleClose} className={classes.dialogButton} color="primary">
          <Typography variant="body1" color="primary">
            {t("cancel")}
          </Typography>
        </Button>
        <Button
          onClick={handleSaveConversation}
          className={classes.dialogButtonPrimaryAction}
          color="primary"
        >
          <Typography variant="body1" color="secondary">
            {t("save")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ManageTagsDialog;

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff",
    minWidth: "500px"
  },
  dialog: {
    padding: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  dialogDark: {
    backgroundColor: "#3e3e42",
    color: "#fff",
    padding: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  dialogActions: {
    borderTop: "1px solid #bdbdbd",
    padding: "0.8rem 0"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959",
    padding: "0.8rem 0"
  },
  dialogButton: {
    padding: "0.5rem 0.8rem",
    marginRight: "1.5rem"
  },
  dialogButtonPrimaryAction: {
    backgroundColor: "#065d54",
    padding: "0.5rem 0.8rem",
    marginRight: "1.5rem",
    "&:hover": {
      backgroundColor: "#207060",
      opacity: [0.9, 0.8, 0.7]
    }
  },
  customInput: {
    border: "1px solid #f9f9f9",
    borderRadius: 5,
    minWidth: 500,
    marginBottom: 15,
    fontSize: 20,
    paddingLeft: 15
  }
}));

ManageTagsDialog.propTypes = {
  selectedConversation: PropTypes.any,
  openNewTagDialog: PropTypes.bool,
  setOpenNewTagDialog: PropTypes.func
};
