import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import AppContext from "../../AppContext";

const Loader = () => {
  const classes = useStyles();

  const { appConfig } = useContext(AppContext);

  return (
    <Box className={classes.container}>
      <CircularProgress
        size={50}
        className={appConfig.darkTheme ? classes.laoderDark : classes.loader}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    padding: "25% 0"
  },
  loader: {
    color: "#065d54"
  },
  laoderDark: {
    color: "#fff"
  }
}));

export default Loader;
