import React, { useContext } from "react";
import { Badge, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AppContext from "../../../AppContext";
import packageInfo from "../../../../package.json";

let version = packageInfo.version;

const SettingsButton = ({ setAnchorElSettings }) => {
  const { t } = useTranslation();
  const { appConfig, lastWebComponentVersion } = useContext(AppContext);
  const classes = useStyles();

  const handleOpenPopoverSettings = event => {
    setAnchorElSettings(event.currentTarget);
  };

  return (
    <Tooltip title={<Typography variant="body2">{t("configuration.tooltip")}</Typography>}>
      <IconButton color="secondary" component="span" onClick={handleOpenPopoverSettings}>
        <Badge
          overlap="rectangular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={appConfig.systemNotifications.length}
          invisible={appConfig.systemNotifications.length === 0}
          classes={{ badge: classes.badgeWarning }}
          >
          <Badge
            overlap="rectangular"
            color="secondary"
            variant="dot"
            invisible={lastWebComponentVersion === version}
            classes={{ badge: classes.badge }}
          >
            <Settings />
          </Badge>
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles(() => ({
  badge: {
    backgroundColor: "red",
    fontSize: "10px !important",
    color: "#fff"
  },
  badgeWarning: {
    backgroundColor: "#ffaa00",
    fontSize: "14px !important",
    color: "#fff",
  }
}));

export default SettingsButton;

SettingsButton.propTypes = {
  setAnchorElSettings: PropTypes.func
};
