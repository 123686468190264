import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logo from "./assets/adaptive-icon.png";
import { init } from "./index";
import ApiCalls from "./utils/APIRequests";
import "./Login.css";
import AppContext from "./AppContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      top: 445
    }
  }
};

const Login = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [surnames, setSurnames] = useState("");
  const [email, setEmail] = useState("");
  const [source, setSource] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [error, setError] = useState(false);
  const { appConfig, setAppConfig, setAssessor, setControlConfig, setSkipSaveAppConfig, setCompanyTags } = useContext(AppContext);

  useEffect(() => {
    let isMounted = true;
    ApiCalls.getSources({ host: appConfig.host, company: appConfig.company })
      .then(async response => {
        let aoSources = await response.json();
        if (isMounted) {
          setSourceList(aoSources);
        }
      })
      .catch();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const user = JSON.parse(window.localStorage.getItem("user"));
      if (user && !appConfig.loggedIn) {
        setSkipSaveAppConfig(true);
        setAppConfig(oAppConfig => {
          return {
            ...oAppConfig,
            loggedIn: true,
            source: user.source,
            sourceFilter: user.source.includes(",") ? user.source.split(",")[0] : user.source,
            minimized: false,
            myUser: {
              name: user.assessor_first_name,
              lastName: user.assessor_last_name,
              compoundName: user.assessor_last_name
                ? `${user.assessor_first_name} ${user.assessor_last_name}`
                : user.assessor_first_name,
              email: user.assessor_email,
              unread: 0
            }
          };
        });

        setControlConfig(oControlConfig => {
          return {
            ...oControlConfig,
            viewUserList: true
          };
        });

        setAssessor(oAssessor => {
          return {
            ...oAssessor,
            name: user.assessor_first_name,
            lastName: user.assessor_last_name,
            email: user.assessor_email,
            compoundName: `${user.assessor_first_name}  ${user.assessor_last_name}`
          };
        });

        ApiCalls.getUserSettings({
          assessor_email: user.assessor_email,
          host: appConfig.host,
          source: user.source,
          company: appConfig.company
        })
        .then(response => response.json())
        .then(response => {
          setSkipSaveAppConfig(true);
          setAppConfig(oAppConfig => {
            return {
              ...oAppConfig,
              size: response.size,
              source: response.source,
              width: response.width,
              height: response.height,
              minimized: false,
              showUserlist: true,
              darkTheme: response.dark_theme,
              default_messages: response.default_messages,
              bUseWs: response.usews,
              notifications: typeof response.notifications != 'undefined' ? response.notifications : false
            };
          });
        });

        ApiCalls.getCompanyTags(appConfig.host, user.source, appConfig.company)
        .then(response => response.json())
        .then(response => {
          setCompanyTags(response);
        })
        .catch(() => {});
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const onHandleLogin = () => {
    if (email === "" || source === "" || name === "" || surnames === "") {
      setError(true);
    } else {
      const payload = {
        assessor_first_name: name,
        assessor_last_name: surnames,
        assessor_email: email,
        show_userlist: true,
        minimized: false,
        source: source.join()
      };
      window.localStorage.setItem("user", JSON.stringify(payload));
      init({
        assessor_first_name: name,
        assessor_last_name: surnames,
        assessor_email: email,
        minimized: false,
        show_userlist: true,
        source: source.join()
      });
    }
  };

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setSource(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img alt="logo" src={logo} className={classes.logo} />
        <Typography component="h1" variant="body1" align="center">
          {t("loginDescription")}
        </Typography>
        <div className={classes.form}>
          <TextField
            style={{ input: { color: "#000 !important" } }}
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label={t("name")}
            name="name"
            autoComplete="name"
            autoFocus
            onChange={e => setName(e.target.value)}
            error={error}
          />
          <TextField
            inputProps={{ style: { color: "#000 !important" } }}
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="surnames"
            label={t("surnames")}
            name="surnames"
            autoComplete="surnames"
            onChange={e => setSurnames(e.target.value)}
            error={error}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            onChange={e => setEmail(e.target.value)}
            error={error}
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{t("source")}</InputLabel>
            <Select
              label={t("source")}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={source}
              multiple
              onChange={handleChange}
              error={error}
              MenuProps={MenuProps}
            >
              {sourceList.map(source => {
                return (
                  <MenuItem key={source.id} value={source.id}>
                    {source.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onHandleLogin}
          >
            {t("login")}
          </Button>
        </div>
      </div>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    width: "100%"
  },
  logo: {
    width: 200
  },
  textField: {
    marginTop: 2
  }
}));

export default Login;
