import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from 'clsx';
import AppContext from "../../../AppContext";

const StatusDot = () => {
    const { online } = useContext(AppContext);
    const classes = useStyles();
    const [wsStatus, setWsStatus] = useState();

    useEffect(() => {
        if (online) {
            setWsStatus('online')
        } else {
            setWsStatus('offline')
        }
    }, [online]);

    return (<span className={clsx(classes.statusDot, wsStatus)} />);
}

export default StatusDot;

const useStyles = makeStyles(() => ({
    statusDot: {
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        verticalAlign: 'center',
        margin: '0 5px',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: 'grey',

        '&.online': {
            backgroundColor: 'green'
        },
        '&.offline': {
            backgroundColor: 'red'
        }
    }
})
);

