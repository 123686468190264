import {
  FormControl,
  Input,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  Switch,
  withStyles 
} from "@material-ui/core";
import {
  Brightness2,
  Brightness5,
  FontDownload,
  Fullscreen,
  LiveHelp,
  MenuBook
} from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";
import PropTypes from "prop-types";
import packageInfo from "../../../package.json";
import Helper from "../../utils/Helper";
import StatusDot from "./CustomElements/StatusDot";
import clsx from 'clsx';
import DialogServerNotifications from "./DialogServerNotifications";
import ApiCalls from "../../utils/APIRequests";

const PrimarySwitch = withStyles({
  switchBase: {
    color: '#718471',
    '&$checked': {
      color: '#006400',
    },
    '&$checked + $track': {
      backgroundColor: '#006400',
    },
  },
  checked: {},
  track: {},
})(Switch);

let version = packageInfo.version;

const PopoverInfoBar = ({ handleClosePopoverSettings }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { assessor, appConfig, setAppConfig, setSkipSaveAppConfig } = useContext(AppContext);
  const { constants } = Helper;
  const [openDialogServerNotifications, setOpenDialogServerNotifications] = useState(false);
  const [notificationsChecked, setNotificationsChecked] = useState(false)
 
  useEffect(() => {
    setNotificationsChecked(typeof appConfig.notifications != 'undefined' && appConfig.notifications == true ? true : false)
  }, [appConfig.notifications])

  const toggleNotificationChecked = () => {
    const conf = appConfig;
    conf.notifications = !notificationsChecked;

    // Si es negativo, es por que ha clickado cuando era negativo y lo estamos cambiando a positivo
    if (!notificationsChecked) {
      Notification.requestPermission().then(function (permission) {
        conf.notifications = false;
        if (permission === "granted") {
          conf.notifications = true;
        }
        setAppConfig((oAppConfig) => {
          return {
            ...oAppConfig,
            notifications: conf.notifications
          }
        })
      });
    }
    
    ApiCalls.saveUserSettings(assessor.email, conf);
    setNotificationsChecked((prev) => !prev);
  };

  const setFontSize = sSize => {
    setSkipSaveAppConfig(true);
    setAppConfig({
      ...appConfig,
      size: sSize
    });
    let oAppConfig = appConfig;
    oAppConfig.size = sSize;
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);
  };

  const handleChange = () => {
    let isDarkTheme = !appConfig.darkTheme
    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        darkTheme: isDarkTheme
      };
    });
    let oAppConfig = appConfig;
    oAppConfig.darkTheme = isDarkTheme;
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);
  };

  const handleOpenDialogServerNotifications = () => {
    setOpenDialogServerNotifications(true);
  };

  const showInfo = () => {
    handleClosePopoverSettings();
    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        viewInfoDialog: true
      };
    });
  };

  const showUserManual = () => {
    const userManualURL = constants.url.userManual;
    window.open(userManualURL);
  };

  const resizeScreen = () => {
    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        width: `${window.innerWidth - 70}px`,
        height: `${window.innerHeight - 40}px`
      };
    });
    handleClosePopoverSettings();
    let oAppConfig = appConfig;
    oAppConfig.width = `${window.innerWidth - 70}px`;
    oAppConfig.height = `${window.innerHeight - 40}px`;
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);
  };

  const ListNotificaciones = () => {
    return (
      <ListItem button onClick={handleOpenDialogServerNotifications}>
        <ListItemIcon>
          <Typography 
            className={clsx(classes.notificationsDot, appConfig.systemNotifications.length === 0 ? classes.grey : classes.orange ) }>
            { appConfig.systemNotifications.length }
          </Typography>
        </ListItemIcon>
        <ListItemText
          primary={t("Notificaciones")}
          className={appConfig.darkTheme ? classes.textDark : ""}
        ></ListItemText>
      </ListItem>
    );
  }

  const PrintDialogServerNotifications = () => {
    return (
      <DialogServerNotifications
        openDialogServerNotifications={openDialogServerNotifications}
        setOpenDialogServerNotifications={setOpenDialogServerNotifications}
      />
    );
  }

  return (
    <List component="nav" className={appConfig.darkTheme ? classes.listDark : ""}>
      <ListItem button>
        <ListItemIcon>
          <FontDownload className={appConfig.darkTheme ? classes.iconInfoDark : classes.iconInfo} />
        </ListItemIcon>
        <FormControl>
          <Select
            value={appConfig.size}
            input={<Input />}
            displayEmpty
            onChange={oEvent => {
              setFontSize(oEvent.target.value);
            }}
            style={{ width: 100 }}
            MenuProps={
              appConfig.darkTheme ? { classes: { list: classes.selectContainerDark } } : {}
            }
            className={appConfig.darkTheme ? classes.selectTextDark : classes.selectText}
          >
            <MenuItem className={appConfig.darkTheme ? classes.menuItemTitleDark : ""} value={""}>
              <em>{t("font.size")}</em>
            </MenuItem>
            <MenuItem className={appConfig.darkTheme ? classes.menuItemDark : ""} value={12}>
              12
            </MenuItem>
            <MenuItem className={appConfig.darkTheme ? classes.menuItemDark : ""} value={14}>
              14
            </MenuItem>
            <MenuItem className={appConfig.darkTheme ? classes.menuItemDark : ""} value={16}>
              16
            </MenuItem>
            <MenuItem className={appConfig.darkTheme ? classes.menuItemDark : ""} value={18}>
              18
            </MenuItem>
            <MenuItem className={appConfig.darkTheme ? classes.menuItemDark : ""} value={20}>
              20
            </MenuItem>
            <MenuItem className={appConfig.darkTheme ? classes.menuItemDark : ""} value={22}>
              22
            </MenuItem>
          </Select>
        </FormControl>
      </ListItem>

      <ListItem button onClick={showInfo}>
        <ListItemIcon>
          <LiveHelp className={appConfig.darkTheme ? classes.iconInfoDark : classes.iconInfo} />
        </ListItemIcon>
        <ListItemText
          primary={t("information")}
          className={appConfig.darkTheme ? classes.textDark : ""}
        ></ListItemText>
      </ListItem>

      <ListItem button onClick={showUserManual}>
        <ListItemIcon>
          <MenuBook className={appConfig.darkTheme ? classes.iconInfoDark : classes.iconInfo} />
        </ListItemIcon>
        <ListItemText
          primary={t("userManual")}
          className={appConfig.darkTheme ? classes.textDark : ""}
        ></ListItemText>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <PrimarySwitch 
            size="small" 
            checked={notificationsChecked} 
            onChange={() => {toggleNotificationChecked()}} 
            color="primary"
          />
        </ListItemIcon>
        <ListItemText
          primary={t("notifications")}
          className={appConfig.darkTheme ? classes.textDark : ""}
        ></ListItemText>
      </ListItem>

      <ListItem button onClick={() => resizeScreen()}>
        <ListItemIcon>
          <Fullscreen className={appConfig.darkTheme ? classes.iconInfoDark : classes.iconInfo} />
        </ListItemIcon>
        <ListItemText
          primary={t("resize")}
          className={appConfig.darkTheme ? classes.textDark : ""}
        ></ListItemText>
      </ListItem>

      <ListItem button onClick={handleChange}>
        <ListItemIcon>
          {appConfig.darkTheme ? (
            <Brightness2 className={classes.iconInfoDark} />
          ) : (
            <Brightness5 className={classes.iconInfo} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={appConfig.darkTheme ? t("lightMode") : t("darkMode")}
          className={appConfig.darkTheme ? classes.textDark : ""}
        ></ListItemText>
      </ListItem>

      <ListItem className={classes.version}>
        {appConfig.version !== version && (
          <Typography style={{ fontSize: 16 }} className={classes.versionLinkContainer}>
            <Link className={classes.versionLink} href={constants.url.releaseNotes} target="_blank">
              {t("newVersion")}
            </Link>
          </Typography>
        )}
        
        <Typography
          color="primary"
          className={appConfig.darkTheme ? classes.textDark : ""}
          style={{ fontSize: 14 }}
        >
          <StatusDot/> {t("version") + version}
        </Typography>
      </ListItem>

      {appConfig.systemNotifications.length == 0 ? '' : ListNotificaciones()}
      {appConfig.systemNotifications.length == 0 ? '' : PrintDialogServerNotifications()}
      
      
    </List>
  );
};

export default PopoverInfoBar;

const useStyles = makeStyles(() => ({
  version: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 5,
    marginBottom: 5
  },
  versionLink: {
    color: "#42a5f5"
  },
  versionLinkContainer: {
    marginBottom: 5
  },
  listDark: {
    backgroundColor: "#3e3e42"
  },
  textDark: {
    color: "#fff"
  },
  selectTextDark: {
    color: "#fff",
    width: 80
  },
  selectText: {
    width: 80
  },
  selectContainerDark: {
    backgroundColor: "#2d2d30 !important"
  },
  menuItemTitleDark: {
    backgroundColor: "#2d2d30 !important",
    color: "#fff",
    pointerEvents: "none"
  },
  menuItemDark: {
    backgroundColor: "#3e3e42 !important",
    color: "#fff"
  },
  iconInfo: {
    marginRight: "5%"
  },
  iconInfoDark: {
    marginRight: "5%",
    color: "#fff"
  },
  notificationsDot: {
    borderRadius: 10,
    minWidth: 20,
    minHeight: 20,
    width: "auto",
    height: 20,
    display: "block",
    textAlign: "center",
    fontSize: 14
  },
  orange: {
    backgroundColor: "#ffaa00",
    color: "#FFF"
  },
  grey: {
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    color: "#FFF"
  }
}));

PopoverInfoBar.propTypes = {
  handleClosePopoverSettings: PropTypes.func
};
