import React, { useContext, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../AppContext";
import Helper from "../../utils/Helper";
import { Container, Fade } from "@material-ui/core";
import UserInfoBar from "../UserInfoBar/UserInfoBar";
import UserListItem from "../UserListItem/UserListItem";
import UserListSkeleton from "../UserListSkeleton/UserListSkeleton";
import NewChatItem from "../NewChatItem/NewChatItem";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import PropTypes from "prop-types";
import ModalGroupInfo from "./ModalGroupInfo";
import ManageTagsDialog from "../TagsList/ManageTagsDialog";

const UserList = ({ handleConversation }) => {
  const classes = useStyles();
  const { userList, controlConfig, appConfig, filteredUserList, setFilteredUserList, newChatItem, selectedTag } =
    useContext(AppContext);
  const { filterArraySearchString, filterUsersByTag } = Helper;
  const listRef = useRef();

  useEffect(() => {
    filterUserList();
  }, [controlConfig.searchUserList, userList]);

  const filterUserList = () => {
    let filteredArr = filterArraySearchString(userList,controlConfig.searchUserList,["user", "course_name", "phone"],controlConfig.tabSelection);
    let newFilteredUserList = filterUsersByTag([...filteredArr], selectedTag)
    setFilteredUserList(newFilteredUserList);
  };

  const userListUpdate = () => {
    filterUserList();
  };

  function row(props) {
    const { index, style, data } = props;
    const item = data[index];
    return (
      <>
        {index === 0 && Object.keys(newChatItem).length > 0 ? (
          <NewChatItem key={"newChat"} />
        ) : (
          <UserListItem
            key={index}
            oUser={item}
            style={style}
            selectedIndex={appConfig.selectedIndex}
            fnUpdate={userListUpdate}
          />
        )}
      </>
    );
  }

  const resizeFontSize = (size, height) => {
    switch (size) {
      case 16:
        return height * 1.2;
      case 18:
        return height * 1.2;
      case 20:
        return height * 1.4;
      case 22:
        return height * 1.4;

      default:
        return height;
    }
  };

  const rowHeights = useMemo(() => {
    return (
      filteredUserList?.map(() => {
        let height = 70;
        if (appConfig.size) {
          height = resizeFontSize(appConfig.size, height);
        }
        return height;
      }) || []
    );
  }, [filteredUserList, appConfig.size, appConfig.width]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [rowHeights]);

  const getItemSize = index => {
    return rowHeights[index];
  };

  return (
    <>
      <Fade
        in={controlConfig.viewUserList}
        style={{ display: controlConfig.viewUserList ? "block" : "none" }}
      >
        <Container
          className={appConfig.darkTheme ? classes.usersContainerDark : classes.usersContainer}
        >
          <UserInfoBar />
          <div className={classes.usersScrollContainer} style={{ height: "80% !important" }}>
            {!controlConfig.showLoaderUserList && controlConfig.viewUserList ? (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height - 8}
                    itemCount={filteredUserList.length}
                    itemSize={getItemSize}
                    width={width}
                    itemData={filteredUserList}
                    ref={listRef}
                  >
                    {row}
                  </List>
                )}
              </AutoSizer>
            ) : (
              <UserListSkeleton />
            )}
          </div>
        </Container>
      </Fade>
      <ModalGroupInfo />
      <ManageTagsDialog handleConversation={handleConversation} />
    </>
  );
};

export default UserList;

const useStyles = makeStyles(() => ({
  usersContainer: {
    margin: "0 !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    height: "100%",
    width: "40% !important",
    alignItems: "flex-end",
    padding: "0 !important",
    boxShadow: "2px 2px 5px #0000007a"
  },
  usersContainerDark: {
    margin: "0 !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#2d2d30",
    borderRadius: "10px",
    height: "100%",
    width: "40% !important",
    alignItems: "flex-end",
    padding: "0 !important",
    boxShadow: "2px 2px 5px #0000007a"
  },
  usersScrollContainer: {
    width: "100%",
    height: "calc(100% - 160px) !important",
    padding: "0 !important"
  }
}));

UserList.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.array
};
