import React, { useContext, useEffect, useState } from "react";
import { 
  Container, 
  Fade, 
  MenuItem, 
  Popover, 
  Tab, 
  Tabs, 
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import AppContext from "../../AppContext";
import PropTypes from "prop-types";
import FolderIcon from "@material-ui/icons/Folder";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import NewTagDialog from "./NewTagDialog";
import ManageTagContactsDialog from "./ManageTagContactsDialog";
import ApiCalls from "../../utils/APIRequests";
import packageInfo from "../../../package.json";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ForumIcon from "@material-ui/icons/Forum";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Helper from "../../utils/Helper";

let version = packageInfo.version;

const TagsList = () => {
  const { appConfig, setAppConfig, companyTags, assessor, userList, setFilteredUserList, controlConfig, setSkipSaveAppConfig, selectedTag, setSelectedTag, setControlConfig } = useContext(AppContext);
  const { filterUsersByTag } = Helper;
  const { t } = useTranslation();

  const maxTagsWidth = '80';


  const useStyles = makeStyles(() => ({
    tabsContainer: {
      height: "100%",
      "& .MuiTabScrollButton-vertical": {
        height: "25px !important",
        color: appConfig.darkTheme ? "#FFFFFF" : "#000000"
      }
    },
    addNewTagComponent: {
      position: "absolute",
      bottom: 0,
      padding: 10,
      width: `${maxTagsWidth}px !important`,
      minWidth: `${maxTagsWidth}px !important`,
      "& span": {
        fontSize: `${appConfig.size - 2}px !important`,
        flexDirection: "column !important",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "2.25rem !important"
      },
      "& .MuiTab-wrapper": {
        gap: "0 !important",
      }
    },
    tabComponent: {
      padding: 10,
      width: `${maxTagsWidth}px !important`,
      minWidth: `${maxTagsWidth}px !important`,
      "& span": {
        fontSize: `${appConfig.size - 2}px !important`,
        flexDirection: "column !important"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "2.25rem !important"
      },
      "& .MuiTab-wrapper": {
        gap: "0 !important",
        width: '100%'
      },
      "& .MuiBadge-root": {
        width: '100%',
        "& .MuiSvgIcon-root": {
          alignSelf: 'center',
        },
      },
      "& .MuiBadge-badge": {
        marginTop: 3,
        marginRight: 3
      }
    },
    tagTextSpam: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      maxWidth: '100%',
      fontSize: `${appConfig.size - 2}px !important`,
    },
    tabComponentSelected: {
      backgroundColor: "rgba(0,0,0,.05)"
    },
    tagsContainer: {
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      height: "100%",
      width: `${maxTagsWidth}px !important`,
      boxShadow: "2px 2px 5px #0000007a",
      padding: 0,
      "& .MuiTabs-indicator": {
        width: 3,
        marginRight: 1
      }
    },
    tagsContainerDark: {
      backgroundColor: "#2d2d30",
      borderRadius: "10px",
      height: "100%",
      width: `${maxTagsWidth}px !important`,
      boxShadow: "2px 2px 5px #0000007a",
      padding: 0
    },
    tagsScrollContainer: {
      width: "100%",
      height: "calc(100% - 90px) !important"
    },
    menuItem: {
      display: "flex",
      gap: 10,
      margin: 1
    },
    menuItemDark: {
      display: "flex",
      gap: 10,
      backgroundColor: "#3e3e42",
      "&:hover": {
        backgroundColor: "#2d2d30"
      }
    },
  }));

  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [openNewTagDialog, setOpenNewTagDialog] = useState(false);
  const [anchorContextMenuTag, setAnchorContextMenuTag] = React.useState(null);
  const [anchorMenuTagContacts, setAnchorMenuTagContacts] = React.useState(null);
  const bOpenContextMenuTag = Boolean(anchorContextMenuTag);
  const bOpenMenuTagContacts = Boolean(anchorMenuTagContacts);

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handlePressNewTag = bOpen => {
    setOpenNewTagDialog(bOpen);
  };

  const handleOpenContextMenuTag = (event, oTag, nIndex) => {
    setSelectedTag(oTag);
    setSelectedIndex(nIndex);
    setAnchorContextMenuTag(event.currentTarget);
    event.preventDefault();
  };

  const disableContextMenu = event => {
    event.preventDefault();
  };

  const handleCloseOpenContextMenuTag = () => {
    setAnchorContextMenuTag(null);
  };

  const handleCloseOpenMenuTagContacts = () => {
    setAnchorMenuTagContacts(null);
  };

  const handleOpenMenuTagContacts = (event, selTag) => {
    handleCloseOpenContextMenuTag();
    setAnchorMenuTagContacts(event.currentTarget);
    event.preventDefault();
  };

  function removeTagFromUserTags(aoTags, sKey) {
    return aoTags.filter(oTag => {
      return oTag.key !== sKey;
    });
  }

  const handleDeleteTag = () => {
    handleCloseOpenContextMenuTag();
    ApiCalls.deleteConversationTag(
      appConfig.host,
      appConfig.source,
      appConfig.company,
      assessor.email,
      selectedTag.key
    ).then(() => {

      let oAppConfig = appConfig;
      oAppConfig.userTags = removeTagFromUserTags(appConfig.userTags, selectedTag.key)

      setSkipSaveAppConfig(true);
      setAppConfig(oAppConfig);
      ApiCalls.saveUserSettings(assessor.email, oAppConfig);

      setSelectedTag(null);
    });
  };

  const moveTagInArray = (old_index, new_index) => {
    if (appConfig?.userTags && appConfig.userTags.length > 1) {
      let clonedArray = [...appConfig.userTags];
      if (new_index >= clonedArray.length) {
        let k = new_index - clonedArray.length + 1;
        while (k--) {
          clonedArray.push(undefined);
        }
      }
      clonedArray.splice(new_index, 0, clonedArray.splice(old_index, 1)[0]);
      return clonedArray;
    }
  };

  const handleTagUp = () => {
    handleCloseOpenContextMenuTag();

    let oAppConfig = appConfig;
    oAppConfig.userTags = moveTagInArray(selectedIndex, selectedIndex - 1)

    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig);
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);
    setSelectedTag(null);
  };

  const handleTagDown = () => {
    handleCloseOpenContextMenuTag();

    let oAppConfig = appConfig;
    oAppConfig.userTags = moveTagInArray(selectedIndex, selectedIndex + 1)

    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig);
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);
    setSelectedTag(null);
  };

  const countPendingNotifications = (sTypeIn, oTagIn) => {
    let sType = sTypeIn || "all";
    let oTag = oTagIn || {};
    let nTotalUnread = 0;
    if (userList && userList.length > 0) {
      if (sType === "company") {
        userList.forEach(user => {
          if (user.tags?.company_tags && user.tags.company_tags.length > 0) {
            user.tags.company_tags.forEach(sCompanyTag => {
              if (oTag.key === sCompanyTag) {
                if (typeof user.unread != 'undefined') {
                  nTotalUnread += parseInt(user.unread);
                }                
              }
            });
          }
        });
      } else if (sType === "user") {
        userList.forEach(user => {
          if (user?.tags?.user_tags && user?.tags.user_tags.length > 0) {
            user.tags.user_tags.forEach(sUserTag => {
              if (oTag.key === sUserTag) {
                if (typeof user.unread != 'undefined') {
                  nTotalUnread += parseInt(user.unread);
                }                
              }
            });
          }
        });
      } else {
        userList.forEach(user => {
          if (typeof user?.unread != 'undefined') {
            nTotalUnread += parseInt(user.unread);
          }          
        });
      }
    }
    return nTotalUnread;
  };

  const countUnreadConversations = (sTypeIn, oTagIn) => {
    let sType = sTypeIn || "all";
    let oTag = oTagIn || {};
    let bUnreadConversations = 0;
    if (userList && userList.length > 0) {
      if (sType === "company") {
        userList.forEach(user => {
          if (user?.tags?.company_tags && user?.tags.company_tags.length > 0) {
            user.tags.company_tags.forEach(sCompanyTag => {
              if (oTag.key === sCompanyTag && user.markasunread) {
                bUnreadConversations = ' ';
              }
            });
          }
        });
      } else if (sType === "user") {
        userList.forEach(user => {
          if (user?.tags?.user_tags && user?.tags.user_tags.length > 0) {
            user.tags.user_tags.forEach(sUserTag => {
              if (oTag.key === sUserTag && user.markasunread) {
                bUnreadConversations = ' ';
              }
            });
          }
        });
      } else {
        userList.forEach(user => {
          if (typeof user?.markasunread != 'undefined' && user?.markasunread) {
            bUnreadConversations = ' ';
          }
        });
      }
    }
    return bUnreadConversations;
  };

  const updateConversationWithFilter = () => {
    let newFilteredUserList = filterUsersByTag(userList, selectedTag)
    setFilteredUserList(newFilteredUserList);
  }

  useEffect(() => {
    updateConversationWithFilter();
  }, [selectedTag])

  const handleSelectedTab = oTag => {
    setSelectedTag(oTag);
  };

  return (
    <>
      <Fade 
        in={controlConfig.viewUserList && appConfig.usetags && appConfig.expandedTagsMenu == true}
        style={{ display: controlConfig.viewUserList ? "block" : "none" }}
      >
        <Container
          className={appConfig.darkTheme ? classes.tagsContainerDark : classes.tagsContainer}
        >
          <div className={classes.tagsScrollContainer}>
            <Tabs
              className={classes.tabsContainer}
              indicatorColor="primary"
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleChangeTab}
            >
              <Tab
                onClick={() => {
                  handleSelectedTab({ key: "all" });
                }}
                onContextMenu={event => disableContextMenu(event)}
                className={clsx(classes.tabComponent, [!selectedTag || selectedTag.key == 'all' ? classes.tabComponentSelected : ''])}
                key={"all"}
                icon={
                  <Tooltip
                    title={
                      <Typography color="secondary" variant="body2">
                        {t("allTags")}
                      </Typography>
                    }
                  >
                    <Badge
                      overlap="rectangular"
                      color="error"
                      badgeContent={
                        countPendingNotifications("all") || countUnreadConversations("all")
                      }
                    >
                      <ForumIcon />
                      <Typography className={classes.tagTextSpam}>{t("allTags")}</Typography>
                    </Badge>

                  </Tooltip>
                  
                }
              />
              {companyTags?.length > 0 &&
                companyTags.map(oTag => (
                  <Tab
                    onClick={() => {
                      handleSelectedTab(oTag);
                    }}
                    onContextMenu={event => disableContextMenu(event)}
                    className={clsx(classes.tabComponent, [selectedTag && selectedTag.key == oTag.key ? classes.tabComponentSelected : ''])}
                    key={oTag.key}
                    icon={
                      <Tooltip
                        title={
                          <Typography color="secondary" variant="body2">
                            {oTag.tag}
                          </Typography>
                        }
                      >
                        <Badge
                          overlap="rectangular"
                          color="error"
                          badgeContent={
                            countPendingNotifications("company", oTag) ||
                            countUnreadConversations("company", oTag)
                          }
                        >
                          <FolderSpecialIcon />
                          <Typography className={classes.tagTextSpam}>{oTag.tag}</Typography>
                        </Badge>
                      </Tooltip>
                    }
                  />
                ))}
              {appConfig.userTags?.length > 0 &&
                appConfig.userTags.map((oTag, nIndex) => (
                  <Tab
                    onClick={() => {
                      handleSelectedTab(oTag);
                    }}
                    onContextMenu={event => handleOpenContextMenuTag(event, oTag, nIndex)}
                    className={clsx(classes.tabComponent, [selectedTag && selectedTag.key == oTag.key ? classes.tabComponentSelected : ''])}
                    index={nIndex}
                    key={oTag.key}
                    icon={
                      <Tooltip
                        title={
                          <Typography color="secondary" variant="body2">
                            {oTag.tag}
                          </Typography>
                        }
                      >
                        <Badge
                          overlap="rectangular"
                          color="error"
                          badgeContent={
                            countPendingNotifications("user", oTag) ||
                            countUnreadConversations("user", oTag)
                          }
                        >
                          <FolderIcon />
                          <Typography className={classes.tagTextSpam}>{oTag.tag}</Typography>
                        </Badge>
                      </Tooltip>
                      
                    }
                  />
                ))}
            </Tabs>
          </div>
          <Tab
            onClick={() => handlePressNewTag(true)}
            className={classes.addNewTagComponent}
            key={0}
            label={t("addNewTag")}
            icon={<AddIcon />}
          />
        </Container>
      </Fade>
      <NewTagDialog
        openNewTagDialog={openNewTagDialog}
        setOpenNewTagDialog={setOpenNewTagDialog}
      ></NewTagDialog>
      <Popover
        id="tag-context-menu"
        tag={selectedTag}
        open={bOpenContextMenuTag}
        anchorEl={anchorContextMenuTag}
        onClose={handleCloseOpenContextMenuTag}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
      >
        {selectedIndex > 0 && (
          <MenuItem
            onClick={() => handleTagUp()}
            className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
          >
            <ArrowUpwardIcon color="primary" />
            <Typography color="primary">{t("up")}</Typography>
          </MenuItem>
        )}
        {selectedIndex < appConfig.userTags?.length - 1 && (
          <MenuItem
            onClick={() => handleTagDown()}
            className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
          >
            <ArrowDownwardIcon color="primary" />
            <Typography color="primary">{t("down")}</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={event => handleOpenMenuTagContacts(event, selectedTag)}
          className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
        >
          <GroupAddIcon color="primary" />
          <Typography color="primary">{t("manageTagContacts")}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleDeleteTag()}
          className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
        >
          <FolderIcon color="primary" />
          <Typography color="primary">{t("deleteTag")}</Typography>
        </MenuItem>
      </Popover>

      <ManageTagContactsDialog 
        selectedtagname={selectedTag?.tag}
        selectedtagkey={selectedTag?.key}
        open={bOpenMenuTagContacts}
        onClose={handleCloseOpenMenuTagContacts}
      />
    </>
  );
};

TagsList.propTypes = {
  oTag: PropTypes.shape({
    key: PropTypes.string,
    tag: PropTypes.string
  })
};

export default TagsList;
