import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ApiCalls from "../../../utils/APIRequests";
import { Box, Button, Typography } from "@material-ui/core";
import Helper from "../../../utils/Helper";
import AppContext from "../../../AppContext";
import GetAppIcon from "@material-ui/icons/GetApp";

const FileItem = ({ file }) => {
  const classes = useStyles();

  const { appConfig } = useContext(AppContext);
  const { parseDate } = Helper;
  const [thumbnail, setThumbnail] = useState();
  const [fileUrl, setFileUrl] = useState();

  const parseAttachBase64 = sAttachment => {
    ApiCalls.getAttachmentsThumbnailAPI(sAttachment)
      .then(async response => {
        let sBase64 = await response.text();
        setThumbnail("data:image/jpeg;base64," + sBase64);
      })
      .catch();
  };

  const getFileUrl = sUrlAttached => {
    ApiCalls.getAttachmentsAPI(sUrlAttached).then(response => {
      setFileUrl(response);
    });
  };

  useEffect(() => {
    parseAttachBase64(file.attach);
    getFileUrl(file.attach);
  }, [file.attach]);

  const handleDownload = attach => {
    getFileUrl(attach);
    window.open(attach);
  };

  //Helpers para fechas y horas
  const addZeroAtBeginning = sValue => {
    if (!isNaN(sValue) && sValue < 10) {
      sValue = "0" + sValue;
    }
    return sValue;
  };

  const parseTime = sDate => {
    let date = new Date(sDate);
    return addZeroAtBeginning(date.getHours()) + ":" + addZeroAtBeginning(date.getMinutes());
  };

  //Obtener el nombre del fichero
  const queryString = fileUrl?.substring(fileUrl.lastIndexOf("?"), fileUrl.length);
  const urlParams = new URLSearchParams(queryString);
  const urlFile = atob(urlParams.get("url"));
  const filename = urlFile.substring(urlFile.lastIndexOf("/") + 1, urlFile.length);

  return (
    <Box className={classes.tabItem}>
      <Box className={classes.containerImageDates}>
        <img
          className={classes.thumbnail}
          src={thumbnail}
          alt={"File not found"}
          onClick={() => handleDownload(fileUrl)}
        />
        <Box className={classes.containerDates}>
          <Typography variant="h6">{filename}</Typography>
          <Typography>{parseDate(file.date_modified)}</Typography>
          <Typography>{parseTime(file.date_modified)}</Typography>
        </Box>
      </Box>
      <Button
        className={classes.downloadButton}
        onClick={() => handleDownload(fileUrl)}
        color={appConfig.darkTheme ? "secondary" : "inherit"}
        variant="contained"
      >
        <GetAppIcon />
      </Button>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  tabItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  thumbnail: {
    padding: "10px 40px",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.85"
    }
  },
  containerDates: {
    flexDirection: "column"
  },
  containerImageDates: {
    display: "flex",
    alignItems: "center"
  },
  downloadButton: {
    marginRight: "40px"
  }
}));

FileItem.propTypes = {
  file: PropTypes.object
};

export default FileItem;
