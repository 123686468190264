import { IconButton, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../AppContext";

const InfoBarMessageSearch = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig, setAppConfig } = useContext(AppContext);

  const returnMessages = () => {
    setAppConfig(appConfig => {
      return {
        ...appConfig,
        globalSearchScreen: false
      };
    });
  };

  return (
    <div className={appConfig.darkTheme ? classes.containerDark : classes.container}>
      <IconButton color="primary" component="span" onClick={returnMessages}>
        <ArrowBack />
      </IconButton>
      <Typography color="primary" className={classes.title}>
        {t("searchResults")}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ccc",
    alignItems: "center",
    width: "100%"
  },
  containerDark: {
    display: "flex",
    backgroundColor: "#2d2d30",
    borderBottom: "1px solid #2d2d30",
    alignItems: "center",
    width: "100%"
  },
  title: {
    fontSize: "1rem !important"
  }
}));

export default InfoBarMessageSearch;
