import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import InfoBar from "../InfoBar/InfoBar";
import Messages from "../Messages/Messages";
import InputChat from "../InputChat/InputChat";
import AppContext from "../../AppContext";
import MessagesSchedule from "../Messages/MessagesSchedule";
import SearchResultMessages from "../Messages/SearchResultMessages";
import InfoBarMessage from "../Messages/InfoBarMessage/InfoBarMessage";
import InfoBarMessageSchedule from "../Messages/InfoBarMessageSchedule/InfoBarMessageSchedule";
import InfoBarMessageSearch from "../Messages/InfoBarMessageSearch/InfoBarMessageSearch";

import ApiCalls from "../../utils/APIRequests";

const useStyles = makeStyles(() => ({
  messageListContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 10,
    height: "100%",
    width: "60%",
    alignItems: "flex-end",
    boxShadow: "2px 2px 5px #0000007a"
  }
}));

const MessageList = ({ setCacheUsers }) => {
  const classes = useStyles();

  const { controlConfig, appConfig, client, assessor, setMessagesSchedule } =
    useContext(AppContext);

  useEffect(() => {
    if (client.phone) {
      ApiCalls.getMessagesSchedule(appConfig.host, client.phone, assessor.email, appConfig.source, appConfig.company).then(
        async response => {
          let aoMessagesSchedule = await response.json();
          setMessagesSchedule(aoMessagesSchedule.reverse());
        }
      );
    }
  }, [appConfig.scheduleScreen]);

  return (
    <div
      className={classes.messageListContainer}
      style={{
        position: !controlConfig.viewUserList ? "absolute" : "unset",
        float: !controlConfig.viewUserList ? "right" : "unset",
        right: !controlConfig.viewUserList ? "0" : "unset"
      }}
    >
      <InfoBar />
      {appConfig.globalSearchScreen ? (
        <>
          <InfoBarMessageSearch />
          <SearchResultMessages />
        </>
      ) : appConfig.scheduleScreen ? (
        <>
          <InfoBarMessageSchedule />
          <MessagesSchedule />
        </>
      ) : (
        <>
          <InfoBarMessage />
          <Messages />
        </>
      )}
      <InputChat setCacheUsers={setCacheUsers}/>
    </div>
  );
};

MessageList.propTypes = {
  setCacheUsers: PropTypes.func
};

export default MessageList;
