import React, { useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Icon from "./Icon/Icon";
import AppContext from "../../../AppContext";
import Helper from "../../../utils/Helper";
import ApiCalls from "../../../utils/APIRequests";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TranscriptionButton from "./TranscriptionButton";
import DialogTranscription from "./DialogTranscription";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ReplyTo from "./ReplyTo/ReplyTo";

// Function React
const MessageTextDirection = ({
	inreplyto,
	replyThumbnail,
	text,
	direction,
	attach,
	date_modified,
	messagestatus,
	mime,
	thumbnail,
	audioURL,
	transcription,
	fullName,
	nameColor,
	setSUrlCompound,
	setOpenImageDialog,
	setOpenDocDialog,
	source,
	twillio_phone,
	assesor_email,
	assesor_name,
	assesor_first_name,
	assesor_last_name,
}) => {
	const { t } = useTranslation();
	const { appConfig, client, assessor } = useContext(AppContext);
	const [thumbnailOpacity, setThumbnailOpacity] = useState();
	const { constants, isSingleEmoji, parseHour } = Helper;
	const [openDialogTranscription, setOpenDialogTranscription] = useState(false);

  const [PDFContent, setPDFContent] = useState(null);
  const [cryptedPDF, setCryptedPDF] = useState(null);

	const useStyles = makeStyles(() => ({
		audio: {
			marginTop: 15,
			flexGrow: 1,
			maxWidth: 'calc(100% - 30px)'
		},
		messageGroup: {
			width: "97%",
			margin: "0 10px 10px 10px",
			display: "flex"
		},
		flexStart: {
			justifyContent: "flex-start"
		},
		flexEnd: {
			justifyContent: "flex-end"
		},
		linkTypography: {
			marginTop: 15,
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		linkContainer: {
			marginTop: 14,
			marginBottom: 14,
			overflow: "hidden",
			display: "flex",
			flexDirection: "column"
		},
		messageTextContainer: {
			display: "flex",
			flexDirection: "column",
			whiteSpace: "pre-line"
		},
		messageContent: {
			borderRadius: "16px",
			padding: "5px 20px",
			maxWidth: "70%"
		},
		messageContentGray: {
			backgroundColor: "#E2FFC7"
		},
		messageContentBlue: {
			backgroundColor: "#FEFEFE"
		},
		messageContentGrayDark: {
			backgroundColor: "#065D54",
			color: "#fff"
		},
		messageContentBlueDark: {
			backgroundColor: "#2d2d30",
			color: "#fff"
		},
		messageInfoTime: {
			marginLeft: "auto"
		},
		attachmentButton: {
			alignItems: "center",
			alignContent: "center",
			alignSelf: "center"
		},
		messageInfoContainer: {
			alignItems: "end",
			display: "flex"
		},
		thumbnail: {
			width: 240,
			cursor: "pointer",
			marginTop: 14,
			marginBottom: 14
		},

		thumbnailParent: {
			marginTop: 14,
			marginBottom: 14
		},
		thumbnailImage: {
			height: 'auto',
			width: '100%',
			minHeight: 240,
			maxHeight: '100%',
			maxWidth: 240
		},
		thumbnailSticker: {
			height: 'auto',
			width: '100%',
			minHeight: 100,
			maxWidth: 100,
			maxHeight: '100%',
		},
		singleEmoji: {
			marginTop: 14,
			marginBottom: 14,
			margin: "auto",
			fontSize: "60px !important"
		},
		singleEmojiMessageContent: {
			width: "100px !important"
		},
		paragraph: {
			wordBreak: "break-word",
			maxWidth: mime ? 240 : "none",
			"& p": {
				fontSize: `${appConfig.size}px !important`
			}
		},
		audioMessages: {
			display: "flex",
			gap: "5px",
			alignItems: "center",
			marginTop: 14,
			marginBottom: 14
		},
		senderName: {
			marginTop: 15,
			lineHeight: 12,
			marginBottom: -5,
			fontSize: 12,
			fontWeight: 800,
			color: '#267ed9',
			fontStyle: 'italic'
		}
	}));

	const classes = useStyles();

	const directionClass = sDirection => {
		return sDirection === constants.messageStatus.received
			? `${classes.messageGroup} ${classes.flexStart}`
			: `${classes.messageGroup} ${classes.flexEnd}`;
	};

	const messageContentClass = (sDirection, sText) => {
		let sClass =
			sDirection === constants.messageStatus.received
				? `${classes.messageContent} ${appConfig.darkTheme ? classes.messageContentBlueDark : classes.messageContentBlue
				}`
				: `${classes.messageContent} ${appConfig.darkTheme ? classes.messageContentGrayDark : classes.messageContentGray
				}`;
		if (isSingleEmoji(sText)) {
			sClass = `${sClass} ${classes.singleEmojiMessageContent}`;
		}
		return sClass;
	};

	const getAttachedFile = async (sAttachment, mime) => {
		if (['defaultAttach', 'defaultAudioAttach'].indexOf(sAttachment) < 0) {
			const getMimeApplication = constants.mimeTypeApplication.some(mimeType => mimeType === mime);
			const getMimeVideo = constants.mimeTypeVideo.some(mimeType => mimeType === mime);
			if (sAttachment && sAttachment.length) {
				if (!getMimeApplication && !getMimeVideo) {
					ApiCalls.getAttachmentsAPI(sAttachment).then(sUrlCompound => {
						setSUrlCompound(sUrlCompound);
						setOpenImageDialog(true);
					});
        } else {
          if (mime == 'application/pdf') {
            await openPDF(sAttachment);
  				} else {
  					ApiCalls.getAttachmentsAPI(sAttachment).then(sUrlCompound => {
  						setSUrlCompound(sUrlCompound);
  						setOpenDocDialog(true);
  					});
          }
				}
			}
		}
	};

  const openPDF = async (attach) => {
    const popupTitle = "Protected PDF";
    const popupOptions = "popup,width=550,height=550,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
    if (PDFContent == null) {
      const file = await ApiCalls.getAttachmentsAPI(attach)
        .then(async (sUrlCompound) => {
          return await fetch(`${sUrlCompound}`)
            .then(response => { return response.blob() })
            .catch(err => { console.log(err) })
        })
        .catch(err => { console.log(err) });

      const reader = new FileReader();
      await new Promise((resolve, reject) => {
        reader.onload = async () => {
          var files = new Blob([reader.result], { type: 'application/pdf' });
          files.text().then(x => {
            if (x.includes("Encrypt") || x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt")) {
              const obj = URL.createObjectURL(file);
              setCryptedPDF(obj);
              setPDFContent(true);
              window.open(obj, popupTitle, popupOptions);
              return true;
            } else {
              ApiCalls.getAttachmentsAPI(attach).then(sUrlCompound => {
                setSUrlCompound(sUrlCompound);
                setOpenDocDialog(true);
              });
            }
          });
        }
        reader.readAsArrayBuffer(file);
      });
    } else if (cryptedPDF != null) {
      window.open(cryptedPDF, popupTitle, popupOptions);
      return true;
    } 
    ApiCalls.getAttachmentsAPI(attach).then(sUrlCompound => {
      setSUrlCompound(sUrlCompound);
      setOpenDocDialog(true);
    });
  }

	const attachExists = () => {
		const getMimeAudio = constants.mimeTypeAudio.some(mimeType => mimeType === mime);
		const getMimeVideo = constants.mimeTypeVideo.some(mimeType => mimeType === mime);
		if (!!attach && attach.length) {
			if (getMimeAudio && audioURL) {
				return (
					<div className={classes.audioMessages}>
						<audio className={classes.audio} src={audioURL} controls="controls" />
						{transcription && (
							<>
								<TranscriptionButton setOpenDialogTranscription={setOpenDialogTranscription} />
								<DialogTranscription
									openDialogTranscription={openDialogTranscription}
									setOpenDialogTranscription={setOpenDialogTranscription}
									transcription={transcription}
								/>
							</>
						)}
					</div>
				);
			} else if (getMimeVideo) {
				return (
					<video className={classes.thumbnail} controls alt={t("attachment")}>
						<source src={`${attach}&jshdgfy5=1&timezone=Europe/Madrid`} />
						<img src={thumbnail} />
					</video>
				);
			} else {
				return (
					<div className={[classes.thumbnailParent ]}>
						<img
							onClick={() => getAttachedFile(attach, mime)}
							className={[mime == 'image/webp' ? classes.thumbnailSticker : classes.thumbnailImage]}
							src={thumbnail}
							alt={t("attachment")}
							onMouseEnter={() => {
								setThumbnailOpacity(0.85);
							}}
							onMouseLeave={() => {
								setThumbnailOpacity(1);
							}}
						/>
					</div>
				);
			}
		} else {
			return false;
		}
	};

	const findSourceDescription = sSource => {
		const oSource = appConfig.aoSources.find(oSource => oSource.id === sSource);
		return oSource ? oSource.description : sSource;
	};

	return (
		<div className={directionClass(direction)}>
			<div className={messageContentClass(direction, text)}>
				<div className={classes.messageTextContainer} style={{ opacity: thumbnailOpacity }}>
					{client.group && direction === constants.messageStatus.received && (
						<Typography
							variant="caption"
							style={{ fontSize: `${appConfig.size}px`, color: nameColor ? nameColor : "#E1863F" }}
						>
							{fullName}
						</Typography>
					)}
					{source !== appConfig.source && direction === constants.messageStatus.received && (
						<Typography
							variant="caption"
							style={{ fontSize: `${appConfig.size}px`, color: nameColor ? nameColor : "#E1863F" }}
						>
							{fullName} {`(${findSourceDescription(source)})`}
						</Typography>
					)}
					{inreplyto ? (
						<ReplyTo
							inreplyto={inreplyto}
							replyThumbnail={replyThumbnail}
							setSUrlCompound={setSUrlCompound}
							setOpenImageDialog={setOpenImageDialog}
							setOpenDocDialog={setOpenDocDialog}
						/>
					) : (
						""
					)}
					{direction !== "received" && assesor_email != appConfig.myUser.email && (typeof assesor_first_name != 'undefined' || typeof assesor_last_name != 'undefined') &&
						<Typography className={classes.senderName}>
							{`${(typeof assesor_first_name != 'undefined' ? assesor_first_name : '')} ${(typeof assesor_last_name != 'undefined' ? assesor_last_name : '')}`}
						</Typography>
					}
					{direction !== "received" && assesor_email != appConfig.myUser.email && (typeof assesor_email != 'undefined' && typeof assesor_first_name == 'undefined' && typeof assesor_last_name == 'undefined') &&
						<Typography className={classes.senderName}>
							{`${assesor_email}`}
						</Typography>
					}
					{attachExists()}
					{isSingleEmoji(text) ? (
						<Typography className={classes.singleEmoji}>{text}</Typography>
					) : (
						<ReactMarkdown className={classes.paragraph} remarkPlugins={[remarkGfm]}>
							{text}
						</ReactMarkdown>
					)}
				</div>
				<div className={classes.messageInfoContainer}>
					<Typography
						className={classes.messageInfoTime}
						style={{ fontSize: `${appConfig.size - 2}px` }}
					>
						{parseHour(date_modified)}
					</Typography>
				</div>
			</div>
			{direction !== "received" && <Icon messageStatus={messagestatus} twillio_phone={twillio_phone} />}
		</div>
	);
};

MessageTextDirection.propTypes = {
	inreplyto: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	replyThumbnail: PropTypes.string,
	date_modified: PropTypes.string,
	text: PropTypes.string,
	messagestatus: PropTypes.string,
	direction: PropTypes.string,
	attach: PropTypes.string,
	mime: PropTypes.string,
	thumbnail: PropTypes.string,
	audioURL: PropTypes.string,
	transcription: PropTypes.string,
	fullName: PropTypes.string,
	nameColor: PropTypes.string,
	setSUrlCompound: PropTypes.func,
	setOpenImageDialog: PropTypes.func,
	setOpenDocDialog: PropTypes.func,
	twillio_phone: PropTypes.string,
};

export default MessageTextDirection;
