import React from "react";
import ReactDOM from "react-dom";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import is_electron from "is-electron";

const ClosedButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleCloseChatComponent = () => {
    if (window && window.oChatInstantiation) {
      window.oChatInstantiation = undefined;
    }
    ReactDOM.render(null, document.getElementById("root"));
  };

  return (
    <Tooltip
      title={<Typography variant="body2">{t("close.tooltip")}</Typography>}
      className={is_electron() ? classes.invisible : ""}
    >
      <IconButton color="secondary" component="span" onClick={handleCloseChatComponent}>
        <Close />
      </IconButton>
    </Tooltip>
  );
};

export default ClosedButton;

const useStyles = makeStyles(() => ({
  invisible: {
    display: "none"
  }
}));
