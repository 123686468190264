import React, { useContext, useState } from "react";
import { IconButton, makeStyles, Snackbar, Tooltip, Typography } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import AppContext from "../../../AppContext";
import { useTranslation } from "react-i18next";
import Helper from "../../../utils/Helper";
import DialogConversationFiles from "../DialogConversationFiles";

const ClientNameCopyButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    client: { compoundName, phone, group, displayname },
    appConfig
  } = useContext(AppContext);
  const { constants } = Helper;
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [openDialogConversationFiles, setOpenDialogConversationFiles] = useState(false);

  const handleCopyPhone = () => {
    navigator?.clipboard?.writeText(phone).then(() => {
      setOpenSnackBar(true);
      setTimeout(() => {
        setOpenSnackBar(false);
      }, constants.notificationDuration);
    });
  };

  const handleOpenDialogConversationFiles = () => {
    setOpenDialogConversationFiles(true);
  };

  return (
    <div className={classes.containerTitle}>
      <IconButton
        className={classes.nameButton}
        onClick={handleOpenDialogConversationFiles}
        component="span"
      >
        <Tooltip title={<Typography variant="body2">{t("nameConversation")}</Typography>}>
          <Typography className={classes.title} style={{ fontSize: `${appConfig.size + 4}px` }}>
            {group ? displayname : compoundName}
          </Typography>
        </Tooltip>
      </IconButton>
      <DialogConversationFiles
        openDialogConversationFiles={openDialogConversationFiles}
        setOpenDialogConversationFiles={setOpenDialogConversationFiles}
      />
      <Tooltip
        className={phone && !group ? "" : classes.invisible}
        title={<Typography variant="body2">{t("tooltip.copy.phone")}</Typography>}
      >
        <IconButton
          size="small"
          className={classes.copyButton}
          color="secondary"
          component="span"
          onClick={handleCopyPhone}
        >
          <FileCopy className={classes.smallIcon} />
        </IconButton>
      </Tooltip>
      <Snackbar open={openSnackBar} message={t("copy.phone.success")} />
    </div>
  );
};

export default ClientNameCopyButton;

const useStyles = makeStyles(() => ({
  containerTitle: {
    flexGrow: 1,
    display: "flex",
    overflow: "hidden"
  },
  title: {
    color: "#fff",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer"
  },
  invisible: {
    display: "none"
  },
  copyButton: {
    marginLeft: 10
  },
  smallIcon: {
    fontSize: "1rem"
  },
  nameButton: {
    borderRadius: 0,
    padding: 0
  }
}));
