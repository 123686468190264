import React, { useContext } from "react";
import { MenuItem, Popover, Typography } from "@material-ui/core";
import { Update } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../../AppContext";

const PopoverInputChat = ({
  idOptionsInputChat,
  bOpenOptionsInputChat,
  anchorElOptionsInputChat,
  handleClosePopoverOptionsInputChat,
  setOpenDialogSchedule
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { appConfig } = useContext(AppContext);

  const handleOpenDialog = () => {
    setOpenDialogSchedule(true);
    handleClosePopoverOptionsInputChat();
  };

  return (
    <Popover
      id={idOptionsInputChat}
      open={bOpenOptionsInputChat}
      anchorEl={anchorElOptionsInputChat}
      onClose={handleClosePopoverOptionsInputChat}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    >
      <MenuItem
        className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
        onClick={handleOpenDialog}
      >
        <Update color="primary" />
        <Typography color="primary">{t("scheduleMessage")}</Typography>
      </MenuItem>
    </Popover>
  );
};

const useStyles = makeStyles(() => ({
  menuItem: {
    display: "flex",
    gap: "0.5rem"
  },
  menuItemDark: {
    backgroundColor: "#3e3e42",
    "&:hover": {
      backgroundColor: "#2d2d30"
    },
    display: "flex",
    gap: "0.5rem",
    padding: "0.5rem"
  }
}));

export default PopoverInputChat;

PopoverInputChat.propTypes = {
  idOptionsInputChat: PropTypes.string,
  bOpenOptionsInputChat: PropTypes.bool,
  anchorElOptionsInputChat: PropTypes.object,
  handleClosePopoverOptionsInputChat: PropTypes.func,
  setOpenDialogSchedule: PropTypes.func
};
