import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Typography
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";
import ApiCalls from "../../../utils/APIRequests";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../hooks/useDebounce";
import SearchBar from "material-ui-search-bar";
import AppContext from "../../../AppContext";

import EmptyTab from "../../InfoBar/TabItems/EmptyTab";
import Loader from "../../InfoBar/Loader";
import NestedMessages from "./NestedMessages";

import CodeIcon from "@material-ui/icons/Code";
import styles from "../../Chat/Customisation.module.css";

const MINLENGTH = 3;

function groupBy(accum, current, groupKey, splitBy = "") {
  const val = current[groupKey];
  const group = val === undefined ? "messages" : splitBy === "" ? val : val.split(splitBy)[0];

  // eslint-disable-next-line no-unused-vars
  const { [groupKey]: _, ...content } = current;
  if (group in accum) {
    accum[group].push(content);
  } else accum[group] = [content];
  return accum;
}

const DialogGlobalSearch = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { assessor, appConfig, setAppConfig, userList, userSelected, setSearchMessages } =
    useContext(AppContext);

  const onCloseDialog = () => {
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        openDialogGlobalSearch: false
      };
    });
  };

  const [searchInput, setSearchInput] = useState("");
  const debouncedValue = useDebounce(searchInput, 300);

  const [searchResults, setSearchResults] = useState([]);

  const [messagesResults, setMessagesResults] = useState([]);
  const [audiosResults, setAudiosResults] = useState([]);
  const [imagesResults, setImagesResults] = useState([]);

  const [tabValue, setTabValue] = useState("messages");
  const [isLoading, setIsLoading] = useState(false);

  const [searchMsg, setSearchMsg] = useState(t("addSearchCriteria"));
  const [openAll, setOpenAll] = useState(false);

  const handleChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const messageSelected = ubm_id => {
    // TODO: Verify response object structure
    ApiCalls.messageSearch(appConfig.host, assessor.email, appConfig.source, appConfig.company, ubm_id)
      .then(({ messages }) => {
        if (messages !== undefined) {
          setSearchMessages(messages);
        } else {
          setSearchMessages([]);
        }
      })
      .catch(nStatus => {
        if (nStatus === 404 || nStatus === 204 || nStatus == 'Error: 204') {
          setSearchMessages([]);
        }
      });
  };

  const handleMessageClick = useCallback((client, ubm_id) => {
    userSelected(client);
    messageSelected(ubm_id);
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        openDialogGlobalSearch: false,
        globalSearchScreen: true
      };
    });
  }, []);

  const groupByClient = list => {
    const groupedByClient = list.reduce(
      (accum, current) => groupBy(accum, current, "client_phone"),
      {}
    );

    const data = [];
    for (const [key, value] of Object.entries(groupedByClient)) {
      const contact = userList.find(element => element.phone === key);
      if (contact !== undefined) data.push({ key, contact, items: value });
    }
    return data;
  };

  useEffect(() => {
    if (debouncedValue !== "" && debouncedValue.length > MINLENGTH) {
      setIsLoading(true);
      ApiCalls.globalSearch(appConfig.host, assessor.email, appConfig.source, appConfig.company, debouncedValue)
        .then(({ messages }) => {
          if (messages !== undefined) {
            setSearchResults(messages);
          } else {
            setSearchResults([]);
            setMessagesResults([]);
            setSearchMsg(t("noSearchItems"));
          }
        })
        .catch(() => {
          console.error("Error sending message");
        });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (Array.isArray(searchResults) && searchResults.length > 0) {
      const groupedByMime = searchResults.reduce(
        (accum, current) => groupBy(accum, current, "mime", "/"),
        {}
      );
      const { ["messages"]: msgs, ["audio"]: auds, ["image"]: imgs } = groupedByMime;

      setMessagesResults(msgs);
      setAudiosResults(auds);
      setImagesResults(imgs);

      setIsLoading(false);
    }
  }, [searchResults]);

  return (
    <Dialog
      open={appConfig.openDialogGlobalSearch}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      onClose={() => onCloseDialog()}
      fullWidth={true}
      id={appConfig.darkTheme ? styles["proeduca-messenger-dark"] : styles["proeduca-messenger"]}
    >
      <DialogTitle className={classes.dialogTitle}>{t("globalSearch")}</DialogTitle>
      <SearchBar
        placeholder={t("search")}
        onChange={e => setSearchInput(e)}
        onRequestSearch={e => setSearchInput(e)}
        className={appConfig.darkTheme ? classes.searchBarDark : classes.searchBar}
        value={searchInput}
        onCancelSearch={() => {
          setSearchInput("");
          setSearchResults([]);
          setMessagesResults([]);
          setSearchMsg(t("addSearchCriteria"));
        }}
      />

      <DialogContent
        className={appConfig.darkTheme ? classes.dialogContentDark : classes.dialogContent}
      >
        <Box className={appConfig.darkTheme ? classes.tabBoxDark : ""}>
          {debouncedValue !== "" &&
          debouncedValue.length > 3 &&
          Array.isArray(messagesResults) &&
          messagesResults.length > 0 ? (
            <TabContext value={tabValue}>
              <Box>
                <TabList
                  className={classes.tabList}
                  variant="fullWidth"
                  onChange={handleChangeTabValue}
                  TabIndicatorProps={{ style: { backgroundColor: "#469d94", height: "7px" } }}
                >
                  <Tab value="messages" label={t("messages")} />
                  <Tab value="audios" label={t("audio")} />
                  <Tab value="images" label={t("images")} />
                </TabList>
              </Box>
              <Box className={classes.boxPanel}>
                <TabPanel value="messages" className={classes.containerTab}>
                  {isLoading ? (
                    <Loader />
                  ) : messagesResults?.length > 0 ? (
                    <NestedMessages
                      lists={groupByClient(messagesResults)}
                      type="message"
                      handleMessageClick={handleMessageClick}
                      openAll={openAll}
                    />
                  ) : (
                    <EmptyTab message={searchMsg} variant={"h5"} />
                  )}
                </TabPanel>

                <TabPanel value="audios" className={classes.containerTab}>
                  {isLoading ? (
                    <Loader />
                  ) : audiosResults?.length > 0 ? (
                    <NestedMessages
                      lists={groupByClient(audiosResults)}
                      type="audio"
                      handleMessageClick={handleMessageClick}
                      openAll={openAll}
                    />
                  ) : (
                    <EmptyTab message={searchMsg} variant={"h5"} />
                  )}
                </TabPanel>

                <TabPanel value="images" className={classes.containerTab}>
                  {isLoading ? (
                    <Loader />
                  ) : imagesResults?.length > 0 ? (
                    <NestedMessages
                      lists={groupByClient(imagesResults)}
                      type="image"
                      handleMessageClick={handleMessageClick}
                      openAll={openAll}
                    />
                  ) : (
                    <EmptyTab message={searchMsg} variant={"h5"} />
                  )}
                </TabPanel>
              </Box>
            </TabContext>
          ) : (
            <EmptyTab message={searchMsg} variant={"h5"} />
          )}
        </Box>
      </DialogContent>
      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
        style={{ justifyContent: "space-between" }}
      >
        <IconButton
          color="primary"
          component="span"
          onClick={() => setOpenAll(prevState => !prevState)}
          className={classes.expandIcon}
          style={{
            visibility:
              Array.isArray(messagesResults) && messagesResults.length > 0 ? "visible" : "hidden"
          }}
        >
          <CodeIcon />
        </IconButton>
        <Button onClick={() => onCloseDialog()} color="primary" className={classes.buttonClose}>
          <Typography variant="caption" color="primary">
            {t("close")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogGlobalSearch;

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  dialogContent: {
    height: "70vh",
    padding: 0,
    borderTop: "1px solid #fff"
  },
  dialogContentDark: {
    height: "70vh",
    padding: 0,
    backgroundColor: "#3e3e42",
    color: "#fff",
    borderTop: "1px solid #fff"
  },
  dialogActions: {
    borderTop: "1px solid #bdbdbd",
    padding: "0.8rem 0"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959",
    color: "#fff",
    padding: "0.8rem 0"
  },
  buttonClose: {
    marginRight: 20
  },
  tabList: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  boxPanel: {
    maxWidth: "100%",
    overflow: "hidden"
  },
  containerTab: {
    width: "100%",
    padding: 0
  },
  expandIcon: {
    transform: "rotate(90deg)",
    marginLeft: 20
  },
  searchBar: {
    borderRadius: 0,
    marginBottom: "1px !important",
    width: "100%"
  },
  searchBarDark: {
    backgroundColor: "#2d2d30",
    borderRadius: 0,
    width: "100%",
    "& input": {
      color: "#FFFFFF"
    }
  }
}));
