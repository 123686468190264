import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { ListItemText } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import AppContext from "../../AppContext";

const NewChatItem = () => {
  const classes = useStyles();

  const { newChatItem, appConfig } = useContext(AppContext);

  const checkEmptyObject = oObject => {
    //TODO: check if this is needed or we can swap this function for the standard jQuery.isEmptyObject function...
    return (
      oObject &&
      Object.keys(oObject).length === 0 &&
      Object.getPrototypeOf(oObject) === Object.prototype
    );
  };

  return (
    <div className={classes.container}>
      <ListItem
        className={checkEmptyObject(newChatItem) ? classes.invisible : ""}
        button
        selected={true}
        alignItems="flex-start"
      >
        <ListItemAvatar>
          <Badge overlap="rectangular" variant="dot" color="error">
            <Avatar>
              {newChatItem.name?.charAt(0)
                ? newChatItem.name.charAt(0)
                : newChatItem.displayname.charAt(0)}
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.name} style={{ fontSize: `${appConfig.size}px` }}>
              {newChatItem.compoundName ? newChatItem.compoundName : newChatItem.displayname}
            </Typography>
          }
          secondaryTypographyProps={{ component: "div" }}
          secondary={
            <div className={classes.course}>
              <Typography component="span" variant="body2" color="textPrimary">
                {newChatItem.phone}
              </Typography>
              <Typography component="span" variant="body2">
                {newChatItem.courseName ? " - " + newChatItem.courseName : ""}
              </Typography>
            </div>
          }
        />
      </ListItem>
      <Divider
        className={checkEmptyObject(newChatItem) ? classes.invisible : classes.customDivider}
        variant="inset"
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
    maxWidth: "100%"
  },
  customDivider: {
    marginLeft: "15px !important",
    marginRight: "15px !important"
  },
  invisible: {
    display: "none"
  },
  course: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

NewChatItem.propTypes = {
  oNewChatData: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    compoundName: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.string,
    courseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    courseName: PropTypes.string,
    phone: PropTypes.string
  })
};

export default NewChatItem;
