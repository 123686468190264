import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Speaker, Videocam } from "@material-ui/icons";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const DialogConfiguration = ({
  openDialogConfiguration,
  setOpenDialogConfiguration,
  room,
  audioParticipantRef
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { twilioVideo, appConfig } = useContext(AppContext);

  const [devicesAudio, setDevicesAudio] = useState([]);
  const [devicesSpeaker, setDevicesSpeaker] = useState([]);
  const [devicesVideo, setDevicesVideo] = useState([]);
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState("");
  const [selectedSpeakerDeviceId, setSelectedSpeakerDeviceId] = useState("");
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState("");
  const [selectedConfigurationVideoAudio, setSelectedConfigurationVideoAudio] = useState(0);

  const handleCloseDialog = () => {
    setOpenDialogConfiguration(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const listDeviceAudio = devices.filter(device => device.kind === "audioinput");
        const listDeviceSpeaker = devices.filter(device => device.kind === "audiooutput");
        const listDeviceVideo = devices.filter(device => device.kind === "videoinput");
        setDevicesAudio(listDeviceAudio);
        setDevicesVideo(listDeviceVideo);
        setDevicesSpeaker(listDeviceSpeaker);
        if (Object.keys(room).length !== 0) {
          room.localParticipant.audioTracks.forEach(publication => {
            const track = publication.track;

            setSelectedAudioDeviceId(
              listDeviceAudio.find(device => device.label === track.mediaStreamTrack.label).deviceId
            );

            selectedSpeakerDeviceId === "" &&
              setSelectedSpeakerDeviceId(listDeviceSpeaker[0].deviceId);
          });
          if (!twilioVideo.audioOnly) {
            room.localParticipant.videoTracks.forEach(publication => {
              const track = publication.track;
              setSelectedVideoDeviceId(
                listDeviceVideo.find(device => device.label === track.mediaStreamTrack.label)
                  ?.deviceId
              );
            });
          }
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [openDialogConfiguration]);

  const changeAudioTrack = async event => {
    const deviceId = event.target.value;
    room.localParticipant.audioTracks.forEach(publication => {
      const track = publication.track;
      track.restart({ deviceId: { exact: deviceId } });
    });
    setSelectedAudioDeviceId(deviceId);
  };

  const changeSpeakerTrack = event => {
    const deviceId = event.target.value;
    audioParticipantRef.current && audioParticipantRef.current?.setSinkId(deviceId);
    setSelectedSpeakerDeviceId(deviceId);
  };

  const changeVideoTrack = async event => {
    const deviceId = event.target.value;
    room.localParticipant.videoTracks.forEach(publication => {
      const track = publication.track;
      track.restart({ deviceId: { exact: deviceId } });
    });
    setSelectedVideoDeviceId(deviceId);
  };

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openDialogConfiguration}
      onClose={handleCloseDialog}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle className={classes.header}>{t("configuration")}</DialogTitle>
      <DialogContent className={appConfig.darkTheme ? classes.contentDark : classes.content}>
        <List>
          <ListItem
            button
            selected={selectedConfigurationVideoAudio === 0}
            onClick={() => setSelectedConfigurationVideoAudio(0)}
          >
            <ListItemIcon>
              <Speaker className={appConfig.darkTheme ? classes.iconDark : ""} />
            </ListItemIcon>
            <ListItemText>
              <Typography className={appConfig.darkTheme ? classes.textDark : ""}>
                {t("audio")}
              </Typography>
            </ListItemText>
          </ListItem>
          {!twilioVideo.audioOnly && (
            <ListItem
              button
              selected={selectedConfigurationVideoAudio === 1}
              onClick={() => setSelectedConfigurationVideoAudio(1)}
            >
              <ListItemIcon>
                <Videocam className={appConfig.darkTheme ? classes.iconDark : ""} />
              </ListItemIcon>
              <ListItemText>
                <Typography className={appConfig.darkTheme ? classes.textDark : ""}>
                  {t("video")}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </List>
        <Divider orientation="vertical" flexItem />
        <Grid container spacing={3}>
          {selectedConfigurationVideoAudio === 0 && (
            <>
              <Grid item xs={12}>
                <Typography className={appConfig.darkTheme ? classes.textDark : ""}>
                  {t("microphone")}
                </Typography>
                <Select
                  value={selectedAudioDeviceId}
                  onChange={event => changeAudioTrack(event)}
                  className={appConfig.darkTheme ? classes.selectedDark : classes.selected}
                  MenuProps={
                    appConfig.darkTheme ? { classes: { list: classes.selectContainerDark } } : {}
                  }
                >
                  {devicesAudio.map((device, index) => {
                    return (
                      <MenuItem key={index} value={device.deviceId}>
                        {device.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Typography className={appConfig.darkTheme ? classes.textDark : ""}>
                  {t("speakers")}
                </Typography>
                <Select
                  value={selectedSpeakerDeviceId}
                  onChange={event => changeSpeakerTrack(event)}
                  className={appConfig.darkTheme ? classes.selectedDark : classes.selected}
                  MenuProps={
                    appConfig.darkTheme ? { classes: { list: classes.selectContainerDark } } : {}
                  }
                >
                  {devicesSpeaker.map((device, index) => {
                    return (
                      <MenuItem key={index} value={device.deviceId}>
                        {device.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </>
          )}

          {selectedConfigurationVideoAudio === 1 && !twilioVideo.audioOnly && (
            <Grid item xs={12}>
              <Typography className={appConfig.darkTheme ? classes.textDark : ""}>
                {t("camera")}
              </Typography>
              <Select
                className={appConfig.darkTheme ? classes.selectedDark : classes.selected}
                value={selectedVideoDeviceId}
                onChange={changeVideoTrack}
                MenuProps={
                  appConfig.darkTheme ? { classes: { list: classes.selectContainerDark } } : {}
                }
              >
                {devicesVideo.map((device, index) => {
                  return (
                    <MenuItem key={index} value={device.deviceId}>
                      {device.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={appConfig.darkTheme ? classes.actionsDark : classes.actions}>
        <Button onClick={handleCloseDialog}>
          <Typography className={appConfig.darkTheme ? classes.textDark : ""} variant="caption">
            {t("close")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#065D54",
    color: "#fff"
  },
  content: {
    display: "flex",
    flexDirection: "row",
    gap: "2rem",
    paddingBottom: "2rem"
  },
  contentDark: {
    display: "flex",
    flexDirection: "row",
    gap: "2rem",
    paddingBottom: "2rem",
    backgroundColor: "#3e3e42"
  },
  actions: {
    borderTop: "1px solid #bdbdbd"
  },
  actionsDark: {
    borderTop: "1px solid #595959",
    backgroundColor: "#3e3e42"
  },
  selected: {
    maxWidth: 300
  },
  selectedDark: {
    maxWidth: 300,
    color: "#fff"
  },
  textDark: {
    color: "#fff"
  },
  iconDark: {
    color: "#fff"
  },
  selectContainerDark: {
    backgroundColor: "#2d2d30 !important",
    color: "#fff"
  }
}));

DialogConfiguration.propTypes = {
  openDialogConfiguration: PropTypes.bool,
  setOpenDialogConfiguration: PropTypes.func,
  room: PropTypes.object,
  connectRoom: PropTypes.func,
  audioParticipantRef: PropTypes.object
};

export default DialogConfiguration;
