import React from "react";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Mic } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MicButton = ({
  client,
  micRecording,
  setMicRecording,
  Mp3Recorder,
  disabledButtonAttachment
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const startAudioRecording = () => {
    //Borrar possible información de audios anteriores
    setMicRecording({});

    if (!micRecording.isRecording) {
      Mp3Recorder.start()
        .then(() => {
          setMicRecording(micRecording => {
            return {
              ...micRecording,
              isRecording: true
            };
          });
        })
        .catch(() => {});
    } else {
      // buffer es necesario porque son dos parametros en la función
      Mp3Recorder.stop()
        .getMp3()
        // eslint-disable-next-line
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          setMicRecording(micRecording => {
            return {
              ...micRecording,
              isRecording: false,
              blobURL: blobURL,
              blob: blob
            };
          });
        });
    }
  };

  return (
    <Tooltip
      title={
        <Typography variant="body2">
          {micRecording.isRecording ? t("save") : t("record")}
        </Typography>
      }
    >
      <span>
        <IconButton
          onClick={startAudioRecording}
          disabled={
            disabledButtonAttachment ||
            !client.phone ||
            (!client.active && client.active !== undefined)
          }
          color="primary"
        >
          <Mic className={micRecording.isRecording ? classes.micActiveIcon : ""} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default MicButton;

const useStyles = makeStyles(() => ({
  micActiveIcon: {
    color: "#ff0000"
  }
}));

MicButton.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    compoundName: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.string,
    courseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    courseName: PropTypes.string,
    phone: PropTypes.string,
    displayname: PropTypes.string,
    group: PropTypes.bool,
    active: PropTypes.bool
  }),
  micRecording: PropTypes.shape({
    isRecording: PropTypes.bool,
    blobURL: PropTypes.string,
    blob: PropTypes.object,
    isBlocked: PropTypes.bool
  }),
  setMicRecording: PropTypes.func,
  Mp3Recorder: PropTypes.object,
  disabledButtonAttachment: PropTypes.bool
};
