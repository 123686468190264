import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, List, ListItem, Typography } from "@material-ui/core";
import AudioItem from "./AudioItem";
import Helper from "../../../utils/Helper";

const AudioTab = ({ media }) => {
  const classes = useStyles();
  const { parseMonth, isDateMonthLabelNeeded } = Helper;
  let dLastAudioDate = undefined;
  const showDateLabel = date_modified => {
    const dAudioDate = new Date(date_modified);
    if (isDateMonthLabelNeeded(dAudioDate, dLastAudioDate)) {
      dLastAudioDate = dAudioDate;
      return true;
    }
    return false;
  };

  return (
    <List>
      {media?.map((audio, index) => {
        return (
          <React.Fragment key={audio.ubm_id ? audio.ubm_id : index}>
            <ListItem key={audio.ubm_id ? audio.ubm_id : index} className={classes.tabItem}>
              {showDateLabel(audio.date_modified) && (
                <Typography color="primary" className={classes.date} variant="h6">
                  {parseMonth(audio.date_modified)}
                </Typography>
              )}
              <AudioItem audio={audio} />
            </ListItem>
            <Divider variant="fullWidth" />
          </React.Fragment>
        );
      })}
    </List>
  );
};

const useStyles = makeStyles(() => ({
  tabItem: {
    padding: "10px 20px",
    flexDirection: "column",
    alignItems: "flex-start"
  }
}));

AudioTab.propTypes = {
  media: PropTypes.array
};

export default AudioTab;
