import React, { useContext } from "react";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import AppContext from "../../../AppContext";

const GlobalSearchButton = () => {
  const { t } = useTranslation();
  const { setAppConfig } = useContext(AppContext);

  const handleOpenDialogGlobalSearch = () => {
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        openDialogGlobalSearch: true
      };
    });
  };

  return (
    <Tooltip title={<Typography variant="body2">{t("globalSearch")}</Typography>}>
      <IconButton color="secondary" component="span" onClick={handleOpenDialogGlobalSearch}>
        <Search />
      </IconButton>
    </Tooltip>
  );
};

export default GlobalSearchButton;
