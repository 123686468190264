import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, ListItem, Typography } from "@material-ui/core";
import FileItem from "./FileItem";
import Helper from "../../../utils/Helper";

const FileTab = ({ media }) => {
  const classes = useStyles();
  const { parseMonth, isDateMonthLabelNeeded } = Helper;

  let dLastLinkDate = null;
  const showDateLabel = date_modified => {
    const dLinkDate = new Date(date_modified);
    if (isDateMonthLabelNeeded(dLinkDate, dLastLinkDate)) {
      dLastLinkDate = dLinkDate;
      return true;
    }
    return false;
  };

  return media?.map((file, index) => {
    return (
      <React.Fragment key={file.ubm_id ? file.ubm_id : index}>
        <ListItem key={file.ubm_id ? file.ubm_id : index} className={classes.fileItem}>
          {showDateLabel(file.date_modified) && (
            <Typography color="primary" variant="h6" className={classes.date}>
              {parseMonth(file.date_modified)}
            </Typography>
          )}
          <FileItem file={file} />
        </ListItem>
        <Divider variant="fullWidth" />
      </React.Fragment>
    );
  });
};

const useStyles = makeStyles(() => ({
  fileItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  displayFile: {
    width: "85%",
    display: "flex",
    alignItems: "center"
  },
  date: {
    marginLeft: 20
  }
}));

FileTab.propTypes = {
  media: PropTypes.array
};

export default FileTab;
