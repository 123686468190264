import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import { Skeleton } from "@material-ui/lab";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Helper from "../../utils/Helper";

const useStyles = makeStyles(() => ({
  customDivider: {
    marginLeft: "15px !important",
    marginRight: "15px !important"
  }
}));

const { constants, getRandomInt } = Helper;

const ContactListSkeleton = () => {
  const classes = useStyles();

  const nItems = getRandomInt(
    constants.contactListSkeleton.minItems,
    constants.contactListSkeleton.maxItems
  );
  const aoItems = [];

  for (let i = 0; i < nItems; i++) {
    let oItem = (
      <React.Fragment key={i}>
        <ListItem alignItems="flex-start">
          <Skeleton variant="circle" width={40} height={40} />
          <ListItemText
            secondary={
              <React.Fragment>
                <Skeleton
                  variant="rect"
                  width={252}
                  height={getRandomInt(
                    constants.contactListSkeleton.minHeight,
                    constants.contactListSkeleton.maxHeight
                  )}
                  style={{ margin: "10px" }}
                />
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider className={classes.customDivider} variant="inset" component="li" />
      </React.Fragment>
    );
    aoItems.push(oItem);
  }

  return <List component="nav">{aoItems}</List>;
};

export default ContactListSkeleton;
