import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Helper from "../../../../utils/Helper";
import ApiCalls from "../../../../utils/APIRequests";
import TranscriptionButton from "./../TranscriptionButton";
import DialogTranscription from "./../DialogTranscription";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import AppContext from "../../../../AppContext";

const ReplyTo = ({
	inreplyto,
	replyThumbnail,
	setSUrlCompound,
	setOpenImageDialog,
	setOpenDocDialog
}) => {
	const { t } = useTranslation();
	const [thumbnailOpacity, setThumbnailOpacity] = useState();
	const { constants } = Helper;
	const [openDialogTranscription, setOpenDialogTranscription] = useState(false);
	const [audioURL, setAudioURL] = useState("");
	const { appConfig } = useContext(AppContext);

	const useStyles = makeStyles(() => ({
		audio: {
			flexGrow: 1,
			maxWidth: 'calc(100% - 30px)'
		},
		replyTo: {
			padding: "10px",
			boxSizing: "border-box",
			borderLeft: "5px solid #0c5d55",
			borderRadius: "10px",
			backgroundColor: "rgba(12,93,85,.2)",
			gap: "5px",
			display: "flex",
			marginTop: "14px",
			alignItems: "center",
			"& span": {
				marginTop: "0px !important"
			}
		},
		paragraph: {
			wordBreak: "break-word",
			"& p": {
				margin: "0",
				fontSize: `${appConfig.size}px !important`
			}
		},
		thumbnail: {
			width: 220,
			cursor: "pointer"
		}
	}));

	const classes = useStyles();

	const getAttachedFile = (sAttachment, mime) => {
		if (['defaultAttach', 'defaultAudioAttach'].indexOf(sAttachment) < 0) {
			const getMimeApplication = constants.mimeTypeApplication.some(mimeType => mimeType === mime);
			const getMimeVideo = constants.mimeTypeVideo.some(mimeType => mimeType === mime);
			const getMimeText = constants.mimeTypeText.some(mimeType => mimeType === mime);
			if (sAttachment && sAttachment.length && !getMimeText) {
				if (!getMimeApplication && !getMimeVideo) {
					ApiCalls.getAttachmentsAPI(sAttachment).then(sUrlCompound => {
						setSUrlCompound(sUrlCompound);
						setOpenImageDialog(true);
					});
				} else {
					ApiCalls.getAttachmentsAPI(sAttachment).then(sUrlCompound => {
						setSUrlCompound(sUrlCompound);
						setOpenDocDialog(true);
					});
				}
			}
		}
	};

	const getAttachedAudio = async aoAttachments => {
		if (aoAttachments && aoAttachments.length) {
			await ApiCalls.getAttachmentsAPI(aoAttachments)
				.then(response => {
					setAudioURL(response);
				})
				.catch(e => console.log(e));
		}
	};

	const inReplyToAttachment = inreplyto => {
		const getMimeAudio = constants.mimeTypeAudio.some(mimeType => mimeType === inreplyto.mime);
		const getMimeVideo = constants.mimeTypeVideo.some(mimeType => mimeType === inreplyto.mime);
		if (getMimeAudio && inreplyto.content) {
			getAttachedAudio(inreplyto.content);
			return (
				<>
					{/* className={classes.audioMessages}  */}
					<audio className={classes.audio} src={audioURL} controls="controls" />
					{inreplyto.transcription && (
						<>
							<TranscriptionButton setOpenDialogTranscription={setOpenDialogTranscription} />
							<DialogTranscription
								openDialogTranscription={openDialogTranscription}
								setOpenDialogTranscription={setOpenDialogTranscription}
								transcription={inreplyto.transcription}
							/>
						</>
					)}
				</>
			);
		} else if (getMimeVideo) {
			return (
				<video className={classes.thumbnail} controls alt={t("attachment")}>
					<source src={`${inreplyto.content}&jshdgfy5=1&timezone=Europe/Madrid`} />
					<img src={replyThumbnail} />
				</video>
			);
		} else {
			return (
				<img
					onClick={() => getAttachedFile(inreplyto.content, inreplyto.mime)}
					className={classes.thumbnail}
					src={`${inreplyto.content}&jshdgfy5=1&timezone=Europe/Madrid`}
					alt={t("attachment")}
					onMouseEnter={() => {
						setThumbnailOpacity(0.85);
					}}
					onMouseLeave={() => {
						setThumbnailOpacity(1);
					}}
				/>
			);
		}
	};

	const inReplyToContent = () => {
		if (inreplyto.mime === "application/text") {
			return (
				<ReactMarkdown className={classes.paragraph} remarkPlugins={[remarkGfm]}>
					{inreplyto.content}
				</ReactMarkdown>
			);
		} else {
			return inReplyToAttachment(inreplyto);
		}
	};

	return (
		<>
			{inreplyto && inreplyto.content && inreplyto.mime && (
				<div className={classes.replyTo}>{inReplyToContent()}</div>
			)}
		</>
	);
};

export default ReplyTo;

ReplyTo.propTypes = {
	inreplyto: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	replyThumbnail: PropTypes.string,
	setSUrlCompound: PropTypes.func,
	setOpenImageDialog: PropTypes.func,
	setOpenDocDialog: PropTypes.func
};
