import React, { useContext } from "react";
import { MenuItem, Popover, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import ApiCalls from "../../../utils/APIRequests";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../../AppContext";

const PopoverMessage = ({
  idSettingsMessage,
  bOpenSettingsMessage,
  anchorElSettingsMessage,
  handleClosePopoverSettingsMessage,
  ubm_id
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { appConfig } = useContext(AppContext);

  const deleteMessage = () => {
    ApiCalls.deleteMessage(appConfig.host, ubm_id, appConfig.source, appConfig.company)
      .then(() => {
        handleClosePopoverSettingsMessage();
      })
      .catch();
  };

  return (
    <Popover
      id={idSettingsMessage}
      open={bOpenSettingsMessage}
      anchorEl={anchorElSettingsMessage}
      onClose={handleClosePopoverSettingsMessage}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      className={ubm_id ? "" : classes.hiddenPopover}
    >
      <MenuItem
        className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
        button
        onClick={deleteMessage}
      >
        <Delete color="primary" />
        <Typography color="primary">{t("remove")}</Typography>
      </MenuItem>
    </Popover>
  );
};

const useStyles = makeStyles(() => ({
  menuItem: {
    display: "flex",
    gap: "0.5rem"
  },
  menuItemDark: {
    backgroundColor: "#3e3e42",
    "&:hover": {
      backgroundColor: "#2d2d30"
    },
    display: "flex",
    gap: "0.5rem",
    padding: "1rem"
  },
  hiddenPopover: {
    display: "none"
  }
}));

export default PopoverMessage;

PopoverMessage.propTypes = {
  idSettingsMessage: PropTypes.string,
  bOpenSettingsMessage: PropTypes.bool,
  anchorElSettingsMessage: PropTypes.object,
  handleClosePopoverSettingsMessage: PropTypes.func,
  ubm_id: PropTypes.string
};
