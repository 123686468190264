import React from "react";
import { Popover } from "@material-ui/core";
import PopoverInfoBar from "./PopoverInfoBar";
import PropTypes from "prop-types";

const SettingsPopover = ({ idSettings, bOpenSettings, anchorElSettings, setAnchorElSettings }) => {
  const handleClosePopoverSettings = () => {
    setAnchorElSettings(null);
  };

  return (
    <Popover
      id={idSettings}
      open={bOpenSettings}
      anchorEl={anchorElSettings}
      onClose={handleClosePopoverSettings}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <PopoverInfoBar handleClosePopoverSettings={handleClosePopoverSettings} />
    </Popover>
  );
};

export default SettingsPopover;

SettingsPopover.propTypes = {
  idSettings: PropTypes.string,
  bOpenSettings: PropTypes.bool,
  anchorElSettings: PropTypes.object,
  setAnchorElSettings: PropTypes.func,
  setOpen: PropTypes.func
};
