import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import AppContext from "../../../AppContext";

const SearchInUserDefaultMessages = ({ filter, setFilter }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig } = useContext(AppContext);

  const onSearchDefaultList = sValue => {
    setFilter(sValue);
  };

  const onCancelSearch = () => {
    setFilter("");
  };

  return (
    <SearchBar
      placeholder={t("search")}
      onChange={onSearchDefaultList}
      onRequestSearch={onSearchDefaultList}
      className={appConfig.darkTheme ? classes.searchBarDark : classes.searchBar}
      value={filter}
      onCancelSearch={onCancelSearch}
    />
  );
};

const useStyles = makeStyles(() => ({
  searchBar: {
    borderRadius: 0,
    marginTop: 10,
    width: "100%"
  },
  searchBarDark: {
    backgroundColor: "#2d2d30",
    borderRadius: 0,
    width: "100%"
  }
}));

SearchInUserDefaultMessages.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func
};

export default SearchInUserDefaultMessages;
