import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import SearchBar from "material-ui-search-bar";
import AppContext from "../../AppContext";
import ApiCalls from "../../utils/APIRequests";
import useDebounce from "../../hooks/useDebounce";
import { Check, Close } from "@material-ui/icons";
import TabsContactListBar from "./TabsContactListBar";
import AlertTitleNotification from "../Alert/AlertTitleNotification";

import {
  AppBar,
  Box,
  IconButton,
  InputBase,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import Helper from "../../utils/Helper";
import styles from "../Chat/Customisation.module.css";

export default function ContactListBar() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const [groupName, setGroupName] = useState("");

  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const { constants } = Helper;
  const {
    setControlConfig,
    assessor,
    appConfig,
    debugApp,
    setContactList,
    setContactAssessorList,
    controlConfig,
    newContactGroupSelected,
    userList,
    setUserList,
    filteredUserList,
    setFilteredUserList,
    userSelected
  } = useContext(AppContext);

  const debouncedValue = useDebounce(filter, 300);

  useEffect(() => {
    getContactsListFiltered(debouncedValue);
  }, [debouncedValue]);

  const onSearchContactList = sValue => {
    setFilter(sValue);
  };

  const getContactsListFiltered = sFilter => {
    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        searchUserContact: sFilter,
        showLoaderContactList: true
      };
    });
    if (
      (controlConfig.isNewGroup && controlConfig.tabSelectedContactList === constants.customers) ||
      !controlConfig.isNewGroup
    ) {
      ApiCalls.getUserContacts(
        appConfig.host,
        assessor.email,
        debugApp.developer,
        appConfig.source,
        sFilter,
        appConfig.company,
        appConfig.unit
      )
        .then(response => response.json())
        .then(aoUserContacts => {
          setContactList(aoUserContacts);
        })
        .catch(error => {
          if (error === 204 || error == 'Error: 204') {
            // respuesta sin contenido
            if(appConfig.verbose) {
              console.log("Agenda sin contenido")
            }
          }
        })
        .finally(() => {
          setControlConfig(controlConfig => {
            return {
              ...controlConfig,
              showLoaderContactList: false
            };
          });
        });
    } else {
      ApiCalls.getAssessors(appConfig.host, appConfig.source, appConfig.company, sFilter)
        .then(resp => resp.json())
        .then(aoUserContacts => {
          setContactAssessorList(aoUserContacts);
          setControlConfig(controlConfig => {
            return {
              ...controlConfig,
              showLoaderContactList: false
            };
          });
        })
        .catch(() => {
          setControlConfig(controlConfig => {
            return {
              ...controlConfig,
              showLoaderContactList: false
            };
          });
        });
    }
  };

  const onCancelSearch = () => {
    setFilter("");
  };

  const createGroup = () => {
    ApiCalls.addGroup(appConfig.host, controlConfig.selectUsersGroup, groupName, appConfig.source, appConfig.company)
      .then(response => {       
        newContactGroupSelected(response); // TODO: arreglar este método (userSelected(oConversation);)
        setControlConfig(controlConfig => {
          setGroupName();
          // TODO: Revisar las notificaciones de toast
          setToastTitle("");
          setToastMessage(t("createGroupSuccess"));
          setOpenNotification(true);
          return {
            ...controlConfig,
            viewUserList: true,
            viewContactList: false
          };
        });
      })
      .catch(error => {
        setToastTitle(t("errorCreatingGroup"));
        setToastMessage(error === 500 ? t("serverError") : "");
        setOpenNotification(true);
        console.log(error);
      });
  }; 

  const editGroup = () => {
    ApiCalls.updateGroup(
      appConfig.host,
      groupName,
      appConfig.groupInfo.groupid,
      controlConfig.selectUsersGroup,
      appConfig.source,
      appConfig.company
    )
      .then(response => {
        //modificar userList
        const userListIndex = userList.findIndex(user => user.groupid === response.groupid);
        const updateGroup = {
          ...userList[userListIndex],
          displayname: response.displayname,
          date_modified: response.date_modified
        };
        let updateUserList = userList;
        updateUserList[userListIndex] = updateGroup;
        setUserList(updateUserList);

        //modificar filteredUserList
        const filteredUserListIndex = filteredUserList.findIndex(
          user => user.groupid === response.groupid
        );
        const updateGroupFiltered = {
          ...filteredUserList[filteredUserListIndex],
          displayname: response.displayname,
          date_modified: response.date_modified
        };
        let updateFilteredUserList = filteredUserList;
        updateFilteredUserList[filteredUserListIndex] = updateGroupFiltered;
        setFilteredUserList(updateFilteredUserList);

        closeNewChat();
      })
      .catch(() => {});
  };

  const closeNewChat = () => {
    setGroupName("");
    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        viewUserList: true,
        viewContactList: false,
        tabSelectedContactList: constants.customers,
        isNewGroup: false,
        isUpdateGroup: false
      };
    });
  };

  useEffect(() => {
    if (controlConfig.isUpdateGroup) {
      setGroupName(appConfig.groupInfo.displayname);
    }
  }, [controlConfig.viewContactList]);

  return (
    <div className={classes.root} style={{ width: "100%" }}>
      <AppBar position="static" className={classes.headerContactList}>
        <AlertTitleNotification
          title={toastTitle}
          message={toastMessage}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
        <Toolbar>
          {controlConfig.isNewGroup ? (
            <InputBase
              id={styles["input-group-name-contact-list-bar"]}
              type="text"
              fullWidth
              placeholder={t("groupName")}
              value={groupName}
              onChange={event => setGroupName(event.target.value)}
              className={classes.inputGroupName}
            />
          ) : controlConfig.isUpdateGroup ? (
            <InputBase
              id={styles["input-group-name-contact-list-bar"]}
              type="text"
              fullWidth
              placeholder={t("editGroup")}
              value={groupName}
              onChange={event => setGroupName(event.target.value)}
              className={classes.inputGroupName}
            />
          ) : (
            <Typography color="secondary" variant="h6" className={classes.title}>
              {t("new.chat")}
            </Typography>
          )}
          {(controlConfig.isNewGroup || controlConfig.isUpdateGroup) && (
            <Tooltip
              title={
                groupName === "" ? (
                  <Typography variant="body2">
                    {controlConfig.isNewGroup
                      ? t("createGroupTooltipNoName")
                      : t("editGroupTooltipNoName")}
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    {controlConfig.isNewGroup ? t("createGroup") : t("editGroup")}
                  </Typography>
                )
              }
            >
              <span>
                <IconButton
                  disabled={groupName === ""}
                  color="secondary"
                  onClick={controlConfig.isNewGroup ? createGroup : editGroup}
                >
                  <Check />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title={<Typography variant="body2">{t("close.tooltip")}</Typography>}>
            <IconButton color="secondary" component="span" onClick={closeNewChat}>
              <Close />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box
        className={assessor.email === appConfig.myUser.email ? classes.invisible : ""}
        sx={{
          width: "100%",
          height: 5,
          backgroundColor: "#03a9f4",
          "&:hover": {
            backgroundColor: "primary.main",
            opacity: [0.9, 0.8, 0.7]
          }
        }}
      />
      <SearchBar
        placeholder={t("search")}
        onChange={onSearchContactList}
        onRequestSearch={onSearchContactList}
        className={appConfig.darkTheme ? classes.searchBarDark : classes.searchBar}
        value={filter}
        onCancelSearch={onCancelSearch}
      />
      {(controlConfig.isNewGroup || controlConfig.isUpdateGroup) && <TabsContactListBar />}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1
  },
  searchBar: {
    borderRadius: 0,
    marginBottom: "1px !important"
  },
  searchBarDark: {
    backgroundColor: "#2d2d30",
    borderRadius: 0,
    marginBottom: "1px !important"
  },
  headerContactList: {
    backgroundColor: "#065D54 !important",
    borderRadius: "10px 10px 0 0"
  },
  invisible: {
    display: "none"
  },
  inputGroupName: {
    color: "#fff !important"
  }
}));
