import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Helper from "../../utils/Helper";
import ScrollToBottom from "react-scroll-to-bottom";
import DateLabel from "./DateLabel/DateLabel";
import Message from "./Message/Message";
import MessageSkeleton from "../MessageSkeleton/MessageSkeleton";
import AppContext from "../../AppContext";

const { isDateLabelNeeded } = Helper;

const SearchResultMessages = () => {
  const classes = useStyles();

  const [messagesWithDate, setMessagesWithDate] = useState([]);

  const { appConfig, controlConfig, searchMessages } = useContext(AppContext);

  let dLastMessageDate = undefined;

  useEffect(() => {
    const temp = searchMessages.map(oMessage => {
      let dMessageDate = new Date(oMessage.date_modified);

      if (isDateLabelNeeded(dMessageDate, dLastMessageDate)) {
        dLastMessageDate = dMessageDate;
        return Object.assign(
          {
            isDateLabel: true
          },
          oMessage
        );
      }
      return oMessage;
    });
    setMessagesWithDate(temp);
  }, [searchMessages, setMessagesWithDate]);

  return (
    <>
      <ScrollToBottom
        initialScrollBehavior={"auto"}
        className={
          appConfig.darkTheme
            ? classes.messagesScrollContainerDark
            : classes.messagesScrollContainer
        }
      >
        {controlConfig.showLoader ||
        !(Array.isArray(messagesWithDate) && messagesWithDate.length > 0) ? (
          <MessageSkeleton />
        ) : (
          messagesWithDate.map((oMessage, i) => {
            if (oMessage.isDateLabel) {
              return (
                <div key={i}>
                  <DateLabel dateLabel={oMessage.date_modified} size={appConfig.size} />
                  <Message message={oMessage} />
                </div>
              );
            } else {
              return (
                <div key={i}>
                  <Message message={oMessage} />
                </div>
              );
            }
          })
        )}
      </ScrollToBottom>
    </>
  );
};

export default SearchResultMessages;

const useStyles = makeStyles(() => ({
  messagesScrollContainer: {
    overflow: "auto",
    flex: "auto",
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "#EFE7DE"
  },
  messagesScrollContainerDark: {
    overflow: "auto",
    flex: "auto",
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "#3e3e42"
  },
  fab: {
    position: "absolute",
    right: "10px",
    bottom: "10px"
  }
}));
