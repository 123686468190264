import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import { SupervisorAccount } from "@material-ui/icons";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";

const ModalGroupInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig, setAppConfig } = useContext(AppContext);

  const handleClose = () => {
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        openDialogGroupInfo: false
      };
    });
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={appConfig.openDialogGroupInfo} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>{appConfig.groupInfo.displayname}</DialogTitle>
      <List className={appConfig.darkTheme ? classes.listDark : ""}>
        {Object.keys(appConfig.groupInfo).length !== 0 &&
          appConfig.groupInfo.members.map(member => {
            return (
              <React.Fragment key={member.phone}>
                <ListItem key={member.phone}>
                  <ListItemAvatar>
                    <Avatar>
                      {member.assesor_first_name
                        ? member.assesor_first_name.charAt(0)
                        : member.client_name
                        ? member.client_name.charAt(0)
                        : ""}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    {member.assesor_first_name ? member.assesor_first_name : member.client_name}
                  </ListItemText>
                  <ListItemText>
                    {member.assesor_last_name ? member.assesor_last_name : member.client_lastname}
                  </ListItemText>

                  <ListItemIcon>
                    {member.owner && <SupervisorAccount color="primary" />}
                  </ListItemIcon>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
      </List>
      <DialogActions className={appConfig.darkTheme ? classes.dialogActionsDark : ""}>
        <Button
          onClick={handleClose}
          className={appConfig.darkTheme ? classes.buttonDialogActionsDark : ""}
        >
          <Typography variant="caption">{t("close")}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    textAlign: "center",
    backgroundColor: "#065d54",
    color: "#fff"
  },
  listDark: {
    backgroundColor: "#3e3e42",
    color: "#fff"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42"
  },
  buttonDialogActionsDark: {
    color: "#fff"
  }
}));

export default ModalGroupInfo;
