import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Divider, List } from "@material-ui/core";

import AppContext from "../../../AppContext";
import ApiCalls from "../../../utils/APIRequests";
import ListItemDefaultMessage from "./ListItemDefaultMessage/ListItemDefaultMessage";
import ListItemDefaultAudio from "./ListItemDefaultMessage/ListItemDefaultAudio";
import TabsUserDefaultLists from "./TabsUserDefaultLists";
import SearchInUserDefaultMessages from "./SearchInUserDefaultMessages";
import Helper from "../../../utils/Helper";
import useDebounce from "../../../hooks/useDebounce";

const ListUserDefaultMessages = ({
  setInputMessage,
  setNewDefaultMessage,
  setUpdateDefaultMessageIndex,
  setTextDefaultMessage,
  setOpenDialogDefaultMessages,
  setIsNewDefaultMessageAudio,
  setInputMicRecording,
  setTextDefaultMessageTitle
}) => {
  const classes = useStyles();
  const { appConfig, setAppConfig, messages, assessor, setSkipSaveAppConfig } = useContext(AppContext);
  const nLimitHours = 24;

  const { filterArraySearchString } = Helper;

  const [disableAudio, setDisableAudio] = useState();

  //Selección de tabs y contenido
  const [tabSelected, setTabSelected] = useState("messages");
  const [listDefaultFilteredMessages, setListDefaultFilteredMessages] = useState([]);

  //Searchbar y filtrado
  const [filter, setFilter] = useState("");
  const debouncedValue = useDebounce(filter, 300);

  const deleteDefaultMessage = (e, ubmId) => {
    e.stopPropagation();
    let deleteDefaultMessage = appConfig.default_messages.filter(
      message => message.ubm_id !== ubmId
    );
    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        default_messages: deleteDefaultMessage
      };
    });
    let oAppConfig = appConfig;
    oAppConfig.default_messages = deleteDefaultMessage;
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);
};

  const deleteDefaultAudio = (e, ubmId) => {
    e.stopPropagation();
    ApiCalls.removeAudioTemplate(appConfig.host, assessor.email, ubmId, appConfig.source, appConfig.company)
      .then(response => {
        setSkipSaveAppConfig(true);
        setAppConfig(oAppConfig => {
          return {
            ...oAppConfig,
            default_messages: response.default_messages
          };
        });
        let oAppConfig = appConfig;
        oAppConfig.default_messages = response.default_messages;
        ApiCalls.saveUserSettings(assessor.email, oAppConfig);
      })
      .catch();
  };

  const editDefaultMessage = (e, ubm_id) => {
    e.stopPropagation();
    setIsNewDefaultMessageAudio(false);
    setNewDefaultMessage(true);
    setUpdateDefaultMessageIndex(ubm_id);
    setTextDefaultMessage(
      appConfig.default_messages.find(oDefaultMessage => oDefaultMessage.ubm_id === ubm_id).message
    );
    setTextDefaultMessageTitle(
      appConfig.default_messages.find(oDefaultMessage => oDefaultMessage.ubm_id === ubm_id).title
    );
  };

  const pasteDefaultMessageInput = name => {
    setInputMessage(name);
    setOpenDialogDefaultMessages(false);
  };

  const pasteDefaultAudioInput = async (title, audioURL) => {
    let blob = await fetch(audioURL).then(r => r.blob());
    const blobURL = URL.createObjectURL(blob);
    setInputMicRecording(micRecording => {
      return {
        ...micRecording,
        isRecording: false,
        blobURL: blobURL,
        blob: blob
      };
    });
    setOpenDialogDefaultMessages(false);
  };

  // Revisamos si hay mensajes en menos de 24h para activar el botón de adjuntos
  useEffect(() => {
    disableSendAudio();
  }, [messages]);

  const disableSendAudio = () => {
    if (messages.length >= 1) {
      let aoFilterReceived = messages.filter(element => element.direction === "received");
      let oLastReceived = aoFilterReceived[aoFilterReceived.length - 1];
      let nowDate = new Date();
      if (aoFilterReceived.length >= 1) {
        let nDifferenceHours = (nowDate - new Date(oLastReceived.date_modified)) / (1000 * 3600);
        if (nDifferenceHours >= nLimitHours) {
          setDisableAudio(true);
        } else {
          setDisableAudio(false);
        }
      } else {
        setDisableAudio(true);
      }
    } else {
      setDisableAudio(true);
    }
  };

  useEffect(() => {
    if (tabSelected === "messages") {
      setListDefaultFilteredMessages(
        filterArraySearchString(
          appConfig.default_messages.filter(message => !message.attach),
          debouncedValue,
          ["title"]
        )
      );
    } else {
      setListDefaultFilteredMessages(
        filterArraySearchString(
          appConfig.default_messages.filter(message => message.attach),
          debouncedValue,
          ["message"]
        )
      );
    }
  }, [tabSelected, appConfig.default_messages, debouncedValue]);

  return (
    <>
      <div
        className={appConfig.darkTheme ? classes.filtersContainerDark : classes.filtersContainer}
      >
        <SearchInUserDefaultMessages filter={filter} setFilter={setFilter} />
        <TabsUserDefaultLists tabSelected={tabSelected} setTabSelected={setTabSelected} />
      </div>
      <List className={classes.customNavContainer} component="nav">
        {listDefaultFilteredMessages.map((message, index) => (
          <React.Fragment key={index}>
            {index === 0 ? (
              ""
            ) : (
              <Divider
                className={appConfig.darkTheme ? classes.customDividerDark : classes.customDivider}
                variant="inset"
              />
            )}
            {!message.attach ? (
              <ListItemDefaultMessage
                message={message}
                index={index}
                pasteDefaultMessageInput={pasteDefaultMessageInput}
                editDefaultMessage={editDefaultMessage}
                deleteDefaultMessage={deleteDefaultMessage}
              />
            ) : (
              <ListItemDefaultAudio
                message={message}
                index={index}
                disableAudio={disableAudio}
                pasteDefaultAudioInput={pasteDefaultAudioInput}
                deleteDefaultAudio={deleteDefaultAudio}
              />
            )}
          </React.Fragment>
        ))}
      </List>
    </>
  );
};

const useStyles = makeStyles(() => ({
  customNavContainer: {
    maxHeight: 500
  },
  iconButton: {
    display: "flex"
  },
  listItem: {
    flexDirection: "column"
  },
  disabledListItem: {
    flexDirection: "column",
    color: "#9d9d9d",
    cursor: "default",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  customDivider: {
    marginLeft: "15px !important",
    marginRight: "15px !important"
  },
  customDividerDark: {
    marginLeft: "15px !important",
    marginRight: "15px !important",
    backgroundColor: "#595959"
  },
  infoControlsAudio: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 25
  },
  audioContainer: {
    width: "100%"
  },
  containerInfo24h: {
    width: "100%",
    marginTop: "0.5rem"
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  filtersContainerDark: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    color: "#fff"
  }
}));

ListUserDefaultMessages.propTypes = {
  setInputMessage: PropTypes.func,
  setNewDefaultMessage: PropTypes.func,
  setUpdateDefaultMessageIndex: PropTypes.func,
  setTextDefaultMessage: PropTypes.func,
  setOpenDialogDefaultMessages: PropTypes.func,
  setIsNewDefaultMessageAudio: PropTypes.func,
  setInputMicRecording: PropTypes.func,
  setTextDefaultMessageTitle: PropTypes.func
};

export default ListUserDefaultMessages;
