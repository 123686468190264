import { IconButton, makeStyles, Snackbar, Tooltip, Typography } from "@material-ui/core";
import { Call, Videocam } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import SearchBar from "material-ui-search-bar";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../AppContext";
import Helper from "../../../utils/Helper";
import TwilioVideo from "../../Twilio/TwilioVideo";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../hooks/useDebounce";
import DialogPermissionDenied from "../../Twilio/DialogPermissionDenied";

const InfoBarMessage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { conditionsSources } = Helper.constants;
  const { setControlConfig, client, setTwilioVideo, appConfig } = useContext(AppContext);
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const debouncedValue = useDebounce(filter, 300);
  const [openDialogPermissionDenied, setOpenDialogPermissionDenied] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
    return () => {
      isMounted = false;
    };
  }, [open]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setControlConfig(controlConfig => {
        return {
          ...controlConfig,
          searchMessage: debouncedValue
        };
      });
    }
    return () => {
      isMounted = false;
    };
  }, [debouncedValue]);

  const onCancelSearchMessage = () => {
    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        searchMessage: ""
      };
    });
  };

  // Call functions
  const onHandleCall = audioOnly => {
    initializeCallOrVideoCall(audioOnly);
  };

  const onHandleVideoCall = audioOnly => {
    initializeCallOrVideoCall(audioOnly);
  };

  const initializeCallOrVideoCall = audioOnly => {
    setTwilioVideo(twilioVideo => {
      return {
        ...twilioVideo,
        openModal: true,
        audioOnly: audioOnly
      };
    });
  };
  const buttonsVisibleBySources = type => {
    if (
      typeof appConfig.clientConfig == 'object' &&
      Object.keys(appConfig.clientConfig).length !== 0 &&
      typeof appConfig.clientConfig.sources != 'undefined' &&
      appConfig.clientConfig.sources.length > 0 &&
      appConfig.source !== "" &&
      appConfig.source !== undefined
    ) {
      const oSource = appConfig.clientConfig.sources.find(source =>
        source.source === appConfig.source.includes(",")
          ? appConfig.source.substring(0, appConfig.source.indexOf(","))
          : appConfig.source
      );
      if (type === conditionsSources.has_video_calls) {
        return oSource.has_video_calls;
      } else if (type === conditionsSources.has_audio_calls) {
        return oSource.has_audio_calls;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const errorConnectRoom = () => {
    setOpen(true);
    setTwilioVideo(twilioVideo => {
      return {
        ...twilioVideo,
        openModal: false
      };
    });
  };

  const handleCloseOpenDialogPermissionDenied = () => {
    setOpenDialogPermissionDenied(false);
  };

  return (
    <div
      className={
        appConfig.darkTheme ? classes.searchIconsContainerDark : classes.searchIconsContainer
      }
    >
      <SearchBar
        placeholder={t("search")}
        onChange={e => setFilter(e)}
        onRequestSearch={e => setFilter(e)}
        value={filter}
        onCancelSearch={onCancelSearchMessage}
        className={appConfig.darkTheme ? classes.searchBarDark : classes.searchBar}
      />
      <Tooltip title={<Typography variant="body2">{t("videoCall")}</Typography>}>
        <IconButton
          className={
            buttonsVisibleBySources(Helper.constants.conditionsSources.has_video_calls) &&
            client.phone !== "" &&
            !client.group
              ? ""
              : classes.invisible
          }
          color="inherit"
          component="span"
          onClick={() => onHandleVideoCall(false)}
        >
          <Videocam />
        </IconButton>
      </Tooltip>
      <Tooltip title={<Typography variant="body2">{t("call")}</Typography>}>
        <IconButton
          className={
            buttonsVisibleBySources(Helper.constants.conditionsSources.has_audio_calls) &&
            client.phone !== "" &&
            !client.group
              ? ""
              : classes.invisible
          }
          color="inherit"
          component="span"
          onClick={() => onHandleCall(true)}
        >
          <Call />
        </IconButton>
      </Tooltip>
      <TwilioVideo
        errorConnectRoom={errorConnectRoom}
        setOpenDialogPermissionDenied={setOpenDialogPermissionDenied}
      />
      <DialogPermissionDenied
        openDialogPermissionDenied={openDialogPermissionDenied}
        handleCloseOpenDialogPermissionDenied={handleCloseOpenDialogPermissionDenied}
      />
      <Snackbar open={open} autoHideDuration={5000}>
        <Alert severity="error">{t("errorConnectRoom")}</Alert>
      </Snackbar>
    </div>
  );
};

export default InfoBarMessage;

const useStyles = makeStyles(() => ({
  searchBar: {
    borderRadius: 0,
    marginBottom: "1px !important",
    width: "100%"
  },
  searchBarDark: {
    backgroundColor: "#2d2d30",
    borderRadius: 0,
    width: "100%"
  },
  invisible: {
    display: "none"
  },
  searchIconsContainer: {
    display: "flex",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ccc",
    width: "100%"
  },
  searchIconsContainerDark: {
    display: "flex",
    backgroundColor: "#2d2d30",
    borderBottom: "1px solid #3e3e42",
    width: "100%"
  }
}));

InfoBarMessage.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func
};
