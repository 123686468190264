import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { Checkbox, ListItemText } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AppContext from "../../AppContext";

const ContactListItem = ({ oContactUser, checked, newChatOrNewGroup }) => {
  const classes = useStyles();
  const { appConfig, controlConfig } = useContext(AppContext);
  return (
    <React.Fragment>
      <ListItem
        key={oContactUser}
        button
        onClick={() => newChatOrNewGroup(oContactUser)}
        alignItems="flex-start"
      >
        {(controlConfig.isNewGroup || controlConfig.isUpdateGroup) && (
          <Checkbox
            edge="start"
            checked={checked.some(
              checkedContactUser => checkedContactUser.client_email === oContactUser.client_email
            )}
            tabIndex={-1}
            disableRipple
          />
        )}
        <ListItemAvatar>
          <Avatar>{oContactUser.client_name.charAt(0)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            oContactUser.client_lastname ? (
              <Typography component="span" style={{ fontSize: `${appConfig.size}px` }}>
                {oContactUser.client_name + " " + oContactUser.client_lastname}
              </Typography>
            ) : (
              <Typography component="span" style={{ fontSize: `${appConfig.size}px` }}>
                {oContactUser.client_name}
              </Typography>
            )
          }
          secondary={
            <>
              <Typography
                component="span"
                style={{ fontSize: `${appConfig.size}px` }}
                className={classes.inline}
                color="textPrimary"
              >
                {oContactUser.phone}
              </Typography>
              <Typography component="span" style={{ fontSize: `${appConfig.size}px` }}>
                {oContactUser.course_name ? " - " + oContactUser.course_name : ""}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider
        className={appConfig.darkTheme ? classes.customDividerDark : classes.customDivider}
        variant="inset"
        component="li"
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles(() => ({
  inline: {
    display: "inline"
  },
  customDivider: {
    marginLeft: "15px !important",
    marginRight: "15px !important"
  },
  customDividerDark: {
    marginLeft: "15px !important",
    marginRight: "15px !important",
    backgroundColor: "#595959"
  }
}));

ContactListItem.propTypes = {
  oContactUser: PropTypes.shape({
    assessor_email: PropTypes.string,
    assessor_first_name: PropTypes.string,
    assessor_last_name: PropTypes.string,
    client_email: PropTypes.string,
    client_familiarname: PropTypes.string,
    client_lastname: PropTypes.string,
    client_name: PropTypes.string,
    country: PropTypes.string,
    course_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    course_name: PropTypes.string,
    date_modified: PropTypes.string,
    filter: PropTypes.bool,
    language: PropTypes.string,
    phone: PropTypes.string,
    source: PropTypes.string,
    unread: PropTypes.number,
    user: PropTypes.string
  }),
  checked: PropTypes.array,
  newChatOrNewGroup: PropTypes.func
};

export default ContactListItem;
