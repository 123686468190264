import React, { useContext, useEffect, useState } from "react";
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../../AppContext";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Subject } from "@material-ui/icons";
import SearchBar from "material-ui-search-bar";
import CloseIcon from "@material-ui/icons/Close";
import ApiCalls from "./../../../utils/APIRequests";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";


const ListUserCompanyDefaultMessages = ({
    defaultMessages,
    setInputMessage,
    setOpenDialogDefaultMessages,
    client
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appConfig, setAppConfig, assessor, setSelectedDefaultMessage } = useContext(AppContext);
    const [filterText, setFilterText] = useState("");

    const [filteredDefaultMessages, setFilteredDefaultMessages] = useState([]);

    const filterMessages = () => {
        let messages = [];
        if (client?.company && client?.source) {
            messages = defaultMessages.filter(item => { return item.parents?.includes(client.company.toUpperCase()) && item.parents?.includes(client.source.toUpperCase()); });
        } else if (!client?.company && client?.source) {
            messages = defaultMessages.filter(item => item.parents?.includes(client.source.toUpperCase()));
        } else {
            messages = defaultMessages;
        }
        if (appConfig.defaultCompanyMessagesfilters?.length) {
            appConfig.defaultCompanyMessagesfilters?.map(filter => {
                messages = messages.filter(item =>
                    item.text?.toLowerCase().includes(filter.text.toLowerCase())
                    || item.title?.toLowerCase().includes(filter.text.toLowerCase())
                    || item.parents?.indexOf(filter.text.toUpperCase()) >= 0
                    || item.parents?.join(" ").toLowerCase().includes(filter.text.toLowerCase())
                );
            });
        }
        if (filterText.length == 0) {
            return messages;
        }
        return messages.filter(item =>
            item.text?.toLowerCase().includes(filterText.toLowerCase())
            || item.title?.toLowerCase().includes(filterText.toLowerCase())
            || item.parents?.indexOf(filterText.toUpperCase()) >= 0
            || item.parents?.join(" ").toLowerCase().includes(filterText.toLowerCase())
        );
    };

    useEffect(() => {
        const messages = filterMessages();
        setFilteredDefaultMessages(messages);
    }, [defaultMessages, filterText, appConfig.defaultCompanyMessagesfilters]);

    const onSearchDefaultList = sValue => {
        setFilterText(sValue);
    };

    const createFilter = (text) => {
        let findFilter = appConfig.defaultCompanyMessagesfilters?.find(filter => filter.text.toLowerCase() == text.toLowerCase());
        if (!findFilter) { // Evitar guardar filtros duplicados.
            setAppConfig(oAppConfig => {
                const newConfig = {
                    ...oAppConfig,
                    defaultCompanyMessagesfilters: [{ text: text.toLowerCase() }, ...oAppConfig.defaultCompanyMessagesfilters ?? []]
                };
                ApiCalls.saveUserSettings(assessor.email, newConfig);
                return newConfig;
            });
            setFilterText("");
        }
    };

    const removeFilter = (text) => {
        setAppConfig(oAppConfig => {
            const newConfig = {
                ...oAppConfig,
                defaultCompanyMessagesfilters: oAppConfig.defaultCompanyMessagesfilters?.filter(filter => filter.text.toLowerCase() != text.toLowerCase()) ?? []
            };
            ApiCalls.saveUserSettings(assessor.email, newConfig);
            return newConfig;
        });
    };

    const onCancelSearch = () => {
        setFilterText("");
    };

    return (
        <>
            <SearchBar
                placeholder={t("search")}
                onChange={onSearchDefaultList}
                onRequestSearch={(text) => { onSearchDefaultList(text); createFilter(text); }}
                className={appConfig.darkTheme ? classes.searchBarDark : classes.searchBar}
                value={filterText}
                onCancelSearch={onCancelSearch}
            />
            {appConfig.defaultCompanyMessagesfilters?.length > 0 && (
                <div className={classes.filtersContainer}>
                    {appConfig.defaultCompanyMessagesfilters?.map((filter, index) => {
                        if (filter.text) {
                            return (
                                <div className={classes.filter} key={index} onClick={() => removeFilter(filter.text)}>
                                    <Typography className={classes.filterText}>{filter.text}</Typography>
                                    <CloseIcon className={classes.filterIcon} />
                                </div>
                            );
                        }
                    })}
                </div>
            )}
            {filteredDefaultMessages.map((item, index) => {
                if (item.type == "system_message") {
                    return (
                        <div key={index} style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", height: "100%", overflowY: "scroll" }}>
                            <Accordion style={{ flexGrow: 2, backgroundColor: "transparent" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel-header-{index}"
                                >
                                    <div style={{ flexWrap: "wrap", flexDirection: "column" }}>
                                        <Typography aria-controls="panel1-content" style={{ fontWeight: "bold" }}>{item.title}</Typography>
                                        <Typography aria-controls="panel1-content" style={{ fontSize: 10 }}>{item.parents.join(" - ")}</Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{flexDirection: 'column'}}>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {item.text}
                                    </ReactMarkdown>
                                </AccordionDetails>
                            </Accordion>
                            <div style={{ margin: 15 }}>
                                <Subject
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setInputMessage(item.text);
                                        setOpenDialogDefaultMessages(false);
                                        setSelectedDefaultMessage(item);
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
            })}
        </>
    );
};

const useStyles = makeStyles(() => ({
    searchBar: {
        borderRadius: 0,
        marginTop: 10,
        marginBottom: 10,
        width: "100%"
    },
    searchBarDark: {
        backgroundColor: "#2d2d30",
        borderRadius: 0,
        width: "100%"
    },
    filtersContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        overflowX: "scroll",
        paddingBottom: 6,
        "&::-webkit-scrollbar": {
            height: "8px !important",
            width: "8px !important"
        }
    },
    filter: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        border: "1px solid black",
        borderRadius: 5,
        marginRight: 5,
        padding: 3,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "red",
            color: "white"
        }
    },
    filterText: {
        fontSize: "12px !important",
        lineHeight: "12px !important",
        verticalAlign: "middle",
        whiteSpace: "nowrap"
    },
    filterIcon: {
        fontSize: 12,
        verticalAlign: "middle",
        lineHeight: 12
    }
}));

export default ListUserCompanyDefaultMessages;