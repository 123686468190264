import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ApiCalls from "../../../utils/APIRequests";
import DialogImagesViewer from "../../Dialogs/DialogImagesViewer";

const ImageItem = ({ image }) => {
  const classes = useStyles();

  const [openImageDialog, setOpenImageDialog] = React.useState(false);
  const [thumbnail, setThumbnail] = useState();
  const [imageUrl, setImageUrl] = useState();

  const openDialog = () => {
    setOpenImageDialog(true);
  };

  const parseAttachBase64 = sAttachment => {
    if (sAttachment && sAttachment.length) {
      ApiCalls.getAttachmentsThumbnailAPI(sAttachment).then(async response => {
        let sBase64 = await response.text();
        setThumbnail("data:image/jpeg;base64," + sBase64);
      });
    }
  };

  const getImageUrl = sUrlAttached => {
    ApiCalls.getAttachmentsAPI(sUrlAttached).then(response => {
      setImageUrl(response);
    });
  };

  useEffect(() => {
    parseAttachBase64(image.attach);
    getImageUrl(image.attach);
  }, [image.attach]);

  return (
    <>
      <img
        src={thumbnail}
        alt={"Image not found"}
        className={classes.itemImage}
        onClick={openDialog}
      />

      <DialogImagesViewer
        open={openImageDialog}
        setOpen={setOpenImageDialog}
        sUrlCompound={imageUrl}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  itemImage: {
    height: "200px",
    width: "25%",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.85"
    }
  }
}));

ImageItem.propTypes = {
  image: PropTypes.object
};

export default ImageItem;
