import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";

import {  
  Comment,
  Event,
  VerticalAlignTop
} from "@material-ui/icons";

const DialogServerNotifications = ({
  openDialogServerNotifications,
  setOpenDialogServerNotifications
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig } = useContext(AppContext);

  const handleClose = () => {
    setOpenDialogServerNotifications(false);
  };

  const parseText = (text)  => { 
    return (<ReactMarkdown remarkPlugins={[remarkGfm]} linkTarget="_blank">{text}</ReactMarkdown>)
  }

  return (
    <Dialog open={openDialogServerNotifications} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle className={classes.dialogTitle}>{t("serverNotifications")}</DialogTitle>

      <DialogContent
        className={appConfig.darkTheme ? classes.dialogContentDark : classes.dialogContent}
      >
        <List component="nav" className={appConfig.darkTheme ? classes.listDark : ""}>
        {appConfig.systemNotifications.map(function(item, key) {
            const fromDate = new Date(item.dateBegin);//2023-10-21T10:00:00+02:00
            const fromTime = fromDate.toLocaleTimeString().split(':')
            const toDate = new Date(item.dateEnd);
            const toTime = toDate.toLocaleTimeString().split(':')
            return (
              <ListItem key={`notification-${item.id}`}>
                <ListItemIcon style={{alignSelf:'start', marginTop:12}} >
                  <Event/>
                </ListItemIcon>
                <ListItemText
                  primary={`Desde el ${fromDate.toLocaleDateString()} a las ${fromTime[0]}:${fromTime[1]} hasta el ${toDate.toLocaleDateString()} a las ${toTime[0]}:${toTime[1]}`}
                  secondary={parseText(item.text)}
                />
              </ListItem>
            );
        })}
        </List>
      </DialogContent>

      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
      >
        <Button onClick={handleClose} color="primary" className={classes.buttonClose}>
          <Typography variant="caption" color="primary">
            {t("close")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  dialogContent: {
    height: "70vh",
    padding: 0,
    borderTop: "1px solid #fff"
  },
  dialogContentDark: {
    height: "70vh",
    padding: 0,
    backgroundColor: "#3e3e42",
    color: "#fff",
    borderTop: "1px solid #fff"
  },
  dialogActions: {
    borderTop: "1px solid #bdbdbd",
    padding: "0.8rem 0"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959",
    color: "#fff",
    padding: "0.8rem 0"
  },
  buttonClose: {
    marginRight: "10px"
  }
}));

DialogServerNotifications.propTypes = {
    openDialogServerNotifications: PropTypes.bool,
    setOpenDialogServerNotifications: PropTypes.func
};

export default DialogServerNotifications;
