import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { Update } from "@material-ui/icons";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../AppContext";

const ScheduleMessageButton = () => {
  const { t } = useTranslation();
  const { setAppConfig } = useContext(AppContext);

  const handleScheduleMessagesScreen = () => {
    setAppConfig(appConfig => {
      return {
        ...appConfig,
        scheduleScreen: true
      };
    });
  };
  return (
    <Tooltip title={<Typography variant="body2">{t("scheduleMessages")}</Typography>}>
      <span>
        <IconButton component="span" onClick={handleScheduleMessagesScreen}>
          <Update />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ScheduleMessageButton;
