import React, { useContext } from "react";
import { Popover } from "@material-ui/core";
import Picker from "emoji-picker-react";
import PropTypes from "prop-types";
import AppContext from "../../../AppContext";

const PopoverEmoji = ({
  popoverId,
  open,
  anchorEl,
  setAnchorEl,
  inputMessage,
  setInputMessage
}) => {
  const { appConfig } = useContext(AppContext);
  const onSelectEmoji = oEvent => {
    setInputMessage(inputMessage + oEvent.emoji);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <Picker
        theme={appConfig.darkTheme ? "dark" : "light"}
        onEmojiClick={onSelectEmoji}
        previewConfig={{
          showPreview: false
        }}
        skinTonesDisabled={true}
      />
    </Popover>
  );
};

export default PopoverEmoji;

PopoverEmoji.propTypes = {
  popoverId: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  setOpenDialogSchedule: PropTypes.func,
  inputMessage: PropTypes.string,
  setInputMessage: PropTypes.func
};
