import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MicButton from "../Buttons/MicButton";
import CancelMicButton from "../Buttons/CancelMicButton";
import MicRecorder from "mic-recorder-to-mp3";

import AppContext from "../../../AppContext";
import { Box } from "@material-ui/core";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const AudioController = ({ micRecording, setMicRecording }) => {
  const classes = useStyles();
  const { client } = useContext(AppContext);

  return (
    <Box className={classes.containerControllers}>
      <MicButton
        client={client}
        Mp3Recorder={Mp3Recorder}
        micRecording={micRecording}
        setMicRecording={setMicRecording}
      />
      {(micRecording.isRecording || micRecording.blobURL) && (
        <CancelMicButton
          micRecording={micRecording}
          setMicRecording={setMicRecording}
          Mp3Recorder={Mp3Recorder}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  containerControllers: {
    display: "flex"
  }
}));

AudioController.propTypes = {
  micRecording: PropTypes.shape({
    isRecording: PropTypes.bool,
    blobURL: PropTypes.string,
    blob: PropTypes.any,
    isBlocked: PropTypes.bool
  }),
  setMicRecording: PropTypes.func
};

export default AudioController;
