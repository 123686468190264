import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, ListItem, makeStyles, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import ApiCalls from "../../../../utils/APIRequests";

const ListItemDefaultAudio = ({
  message,
  index,
  disableAudio,
  pasteDefaultAudioInput,
  deleteDefaultAudio
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [audioURL, setAudioURL] = useState("");

  useEffect(() => {
    if (message.attach) {
      getAttachedAudio(message.attach);
    }
  }, [message.attach]);

  const getAttachedAudio = async attachURL => {
    if (attachURL) {
      await ApiCalls.getAttachmentsAPI(attachURL).then(response => {
        setAudioURL(response);
      });
    }
  };

  return (
    <ListItem
      key={index}
      className={disableAudio ? classes.disabledListItem : classes.listItem}
      onClick={() => !disableAudio && pasteDefaultAudioInput(message.message, audioURL)}
      button
    >
      <div className={classes.infoControlsAudio}>
        <div className={classes.audioContainer}>
          <Typography variant="body2">{message.message}</Typography>
          <audio className={classes.audio} src={audioURL} controls="controls" />
        </div>
        <div className={classes.iconButton}>
          <IconButton onClick={e => deleteDefaultAudio(e, message.ubm_id)}>
            <Delete color="primary" />
          </IconButton>
        </div>
      </div>
      <div className={classes.containerInfo24h}>
        {disableAudio && (
          <Typography variant="caption" color="error">
            {t("attachment.icon.input.tooltip")}
          </Typography>
        )}
      </div>
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  iconButton: {
    display: "flex"
  },
  listItem: {
    flexDirection: "column"
  },
  disabledListItem: {
    flexDirection: "column",
    color: "#9d9d9d",
    cursor: "default",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  infoControlsAudio: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 25
  },
  audioContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: "100%"
  },
  containerInfo24h: {
    width: "100%",
    marginTop: "0.5rem"
  }
}));

ListItemDefaultAudio.propTypes = {
  message: PropTypes.object,
  index: PropTypes.number,
  disableAudio: PropTypes.bool,
  pasteDefaultAudioInput: PropTypes.func,
  deleteDefaultAudio: PropTypes.func
};

export default ListItemDefaultAudio;
