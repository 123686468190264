import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Rnd } from "react-rnd";
import UserList from "../UserList/UserList";
import ContactList from "../ContactList/ContactList";
import MessageList from "../MessageList/MessageList";
import { Fade } from "@material-ui/core";
import AppContext from "../../AppContext";
import ApiCalls from "../../utils/APIRequests";

import is_electron from "is-electron";
import TagsList from "../TagsList/TagsList";

const MaximizedChat = ({ setCacheUsers, handleConversation }) => {
  if (is_electron()) {
    document.querySelector("#root").style.width = "100%";
  }
  const classes = useStyles();
  const { assessor, appConfig, setAppConfig, setSkipSaveAppConfig } = useContext(AppContext);
  const [ rndSize, setRndSize ] = useState({})
  const [ manualChange, setManualChange ] = useState(false)

  const fitScreen = () => {
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        width: `${window.innerWidth - 70}px`,
        height: `${window.innerHeight - 40}px`
      };
    });
  };

  //Atajo de teclado para ajustar la pantalla a la ventana del navegador
  const detectKeyDown = e => {
    if (e.altKey && String.fromCharCode(e.keyCode) === "R") {
      fitScreen();
    }
  };

  // Obtenemos los valores de width y height de appConfig. Si son mayores que el tamaño de pantalla, redimensionamos.
  const getWidthHeightFromAppConfig = () => {
    let calculatedSize = {
      width: typeof appConfig != 'undefined' && appConfig.hasOwnProperty('width') ? appConfig.width : '850px',
      height: typeof appConfig != 'undefined' && appConfig.hasOwnProperty('height') ? appConfig.height : '650px'
    }
    
    if (parseInt(calculatedSize.width) > parseInt(`${window.innerWidth - 70}`)) {
      calculatedSize.width = `${window.innerWidth - 70}px`
    }

    if (parseInt(calculatedSize.height) > parseInt(`${window.innerHeight - 40}`)) {
      calculatedSize.height = `${window.innerHeight - 40}px`
    }
    return calculatedSize;
  }

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    let calculatedSize = getWidthHeightFromAppConfig();
    setManualChange(false);
    setRndSize(calculatedSize);
  }, []);

  useEffect(()=>{
    if (manualChange) {
      let oAppConfig = appConfig;
      oAppConfig.width= rndSize.width;
      oAppConfig.height= rndSize.height;
      setSkipSaveAppConfig(true);
      setAppConfig(oAppConfig);
      ApiCalls.saveUserSettings(assessor.email, oAppConfig);
    }
    setManualChange(false);
  }, [rndSize])

  useEffect(() => {
    let calculatedSize = getWidthHeightFromAppConfig();
    setManualChange(false);
    setRndSize(calculatedSize);
  }, [appConfig.width, appConfig.height])

  return (
    <Fade in={!appConfig.minimized} style={{ display: !appConfig.minimized ? "block" : "none" }}>
      <Rnd
        id={"rndContainer"}
        className={classes.maximizedChatResizableContainer}
        disableDragging={true}
        default={{
          width: rndSize.width,
          height: rndSize.height
        }}
        size={{
          width: rndSize.width,
          height: rndSize.height
        }}
        onResizeStop={(e, direction, ref, delta, position)  => {
          setManualChange(true)
          setRndSize({
            width: ref.style.width,
            height: ref.style.height
          });
        }}
      >
        <TagsList />
        <UserList handleConversation={handleConversation} />
        <ContactList />
        <MessageList setCacheUsers={setCacheUsers} />
      </Rnd>
    </Fade>
  );
};

const useStyles = makeStyles(() => ({
  maximizedChatResizableContainer: {
    display: "flex !important",
    width: is_electron() ? "100% !important" : "850px",
    height: is_electron() ? "100% !important" : "60%",
    minWidth: "32rem",
    minHeight: "18rem !important",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    right: is_electron() ? "auto" : "50px !important",
    left: "auto !important",
    bottom: is_electron() ? "auto" : "20px !important",
    top: "auto !important",
    zIndex: "1000",
    position: "fixed !important"
  }
}));

MaximizedChat.propTypes = {
  setCacheUsers: PropTypes.func
};

export default MaximizedChat;
