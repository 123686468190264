import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { People } from "@material-ui/icons";
import { Avatar, ListItemAvatar, Tooltip, Typography } from "@material-ui/core";

import MessageTextDirection from "../../Messages/Message/MessageTextDirection";
import DateLabel from "../../Messages/DateLabel/DateLabel";
import AudioItem from "../../InfoBar/TabItems/AudioItem";
import ImageItem from "../../InfoBar/TabItems/ImageItem";
import AppContext from "../../../AppContext";

const styles = theme => ({
  root: {
    width: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0
  }
});

const convertEmToMd = str => {
  return str.replaceAll("<em>", "*").replaceAll("</em>", "*");
};

const NestedMessages = ({ lists, type, handleMessageClick, classes, openAll = false }) => {
  const [opened, setOpened] = useState({});
  const { appConfig } = useContext(AppContext);

  const handleClick = key => () => {
    setOpened(prevState => ({
      ...prevState,
      [key]: !prevState[key] || false
    }));
  };

  useEffect(() => {
    setOpened(lists.reduce((o, { key }) => ({ ...o, [key]: openAll }), {}));
  }, [lists, setOpened, openAll]);

  return (
    <div className={classes.root}>
      <List component="nav">
        {lists &&
          lists.map(({ key, contact, items }) => {
            const isOpen = opened[key] || false;
            return (
              <div key={key}>
                <ListItem button onClick={handleClick(key)}>
                  <ListItemAvatar>
                    <Avatar>
                      {contact && contact.group ? <People /> : contact.client_name?.charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.itemText}
                    primary={
                      contact.group ? (
                        <div className={classes.listItemTextContainer}>
                          <Typography component="span" style={{ fontSize: `${appConfig.size}px` }}>
                            {contact.displayname}
                          </Typography>
                        </div>
                      ) : (
                        <Typography
                          component="span"
                          className={classes.name}
                          style={{ fontSize: `${appConfig.size}px` }}
                        >
                          {contact.client_lastname
                            ? contact.client_name + " " + contact.client_lastname
                            : contact.client_name}
                        </Typography>
                      )
                    }
                    secondaryTypographyProps={{ component: "div" }}
                    secondary={
                      <div className={classes.course}>
                        <Typography
                          component="span"
                          color="textPrimary"
                          style={{ fontSize: `${appConfig.size}px` }}
                        >
                          {contact.group ? "" : contact.phone}
                        </Typography>
                        {!contact.group && (
                          <Tooltip
                            placement="top-start"
                            title={
                              <Typography
                                component="span"
                                style={{ fontSize: `${appConfig.size}px` }}
                              >
                                {contact.course_name}
                              </Typography>
                            }
                          >
                            <Typography
                              component="span"
                              variant="body2"
                              style={{ fontSize: `${appConfig.size}px` }}
                            >
                              {contact.course_name && " - " + contact.course_name}
                            </Typography>
                          </Tooltip>
                        )}
                      </div>
                    }
                  />
                  {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {items.map(item => {
                      const { ubm_id, text, date_modified } = item;
                      return (
                        <ListItem
                          key={ubm_id}
                          button
                          className={classes.nested}
                          onClick={() => handleMessageClick(contact, ubm_id)}
                        >
                          {
                            {
                              message: (
                                <Fragment>
                                  <DateLabel dateLabel={date_modified} size={appConfig.size} />
                                  <MessageTextDirection
                                    text={convertEmToMd(text)}
                                    direction={"send"}
                                    date_modified={date_modified}
                                  />
                                </Fragment>
                              ),
                              audio: (
                                <AudioItem
                                  audio={{ ...item, transcription: convertEmToMd(text) }}
                                />
                              ),
                              image: <ImageItem image={item} />
                            }[type]
                          }
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
            );
          })}
      </List>
    </div>
  );
};

NestedMessages.propTypes = {
  classes: PropTypes.object.isRequired,
  lists: PropTypes.array,
  type: PropTypes.string,
  handleMessageClick: PropTypes.func,
  openAll: PropTypes.bool
};

export default withStyles(styles)(NestedMessages);
