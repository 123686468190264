import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Helper from "../../utils/Helper";
import Message from "./Message/Message";
import Template from "./Templates/Template";
import DateLabel from "./DateLabel/DateLabel";
import MessageSkeleton from "../MessageSkeleton/MessageSkeleton";
import AppContext from "../../AppContext";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiCalls from "../../utils/APIRequests";
import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const { filterArraySearchString, isDateLabelNeeded } = Helper;

const Messages = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig, controlConfig, messages, setMessages, client, assessor } =
    useContext(AppContext);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const aoMessagesWithDate = [];
  let dLastMessageDate = undefined;

  filterArraySearchString(messages, controlConfig.searchMessage, ["text"]).forEach(oMessage => {
    let dMessageDate = new Date(oMessage.date_modified);
    
    if (!isNaN(oMessage.date_modified)) {
      oMessage.date_modified = dMessageDate.toISOString();
    }
    

    if (isDateLabelNeeded(dMessageDate, dLastMessageDate)) {
      dLastMessageDate = dMessageDate;
      aoMessagesWithDate.push(
        Object.assign(
          {
            isDateLabel: true
          },
          oMessage
        )
      );
    }
    aoMessagesWithDate.push(oMessage);
  });

  const openSnackBar = () => {
    setShowSnackBar(true);
    setTimeout(() => {
      setShowSnackBar(false);
    }, 3000);
  };

  const getMoreMessages = () => {
    const company = typeof client.company != 'undefined' && client.company.length > 0 ? client.company : appConfig.company;
    ApiCalls.getMessagesScrollAPI(
      appConfig.host,
      client.phone,
      appConfig.source,
      company,
      assessor.email,
      messages.length !== 0
        ? new Date(messages[0].date_modified).toISOString()
        : new Date().toISOString(),
      appConfig.encryptionkey
    )
      .then(async response => {
        if (response.status === 404 || response.status === 418 || response.status === 204) {
          openSnackBar();
        } else {
          let aoMessages = await response.json();
          setMessages(aoMessages.concat(messages));
        }
      })
      .catch(error => {
        if (error === 404 || error === 418 || error === 204 || error == 'Error: 204') {
          openSnackBar();
        }
      });
  };

  return (
    <div
      id="messagesContainer"
      className={
        appConfig.darkTheme ? classes.messagesScrollContainerDark : classes.messagesScrollContainer
      }
      style={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse"
      }}
    >
      <InfiniteScroll
        dataLength={aoMessagesWithDate.length}
        scrollableTarget="messagesContainer"
        inverse={true}
        hasMore={true}
        next={getMoreMessages}
        style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
      >
        {controlConfig.showLoaderMessage ? (
          <MessageSkeleton />
        ) : (
          aoMessagesWithDate.reverse().map((oMessage, i) => {
            if (oMessage.isDateLabel) {
              return (
                <div key={i}>
                  <DateLabel dateLabel={oMessage.date_modified} />
                </div>
              );
            } else if (oMessage.template_data) {
              return(
                <div key={i}>
                  <Template message={oMessage} />
                </div>
              )
            } else {
              return (
                <div key={i}>
                  <Message message={oMessage} />
                </div>
              );
            }
          })
        )}
      </InfiniteScroll>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Alert severity="warning">{t("noMoreMessages")}</Alert>
      </Snackbar>
    </div>
  );
};

export default Messages;

const useStyles = makeStyles(() => ({
  messagesScrollContainer: {
    overflow: "auto",
    flex: "auto",
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "#EFE7DE"
  },
  messagesScrollContainerDark: {
    overflow: "auto",
    flex: "auto",
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "#3e3e42"
  }
}));
