import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Helper from "../../utils/Helper";
import ScrollToBottom from "react-scroll-to-bottom";
import DateLabel from "./DateLabel/DateLabel";
import MessageSkeleton from "../MessageSkeleton/MessageSkeleton";
import AppContext from "../../AppContext";
import jQuery from "jquery";
import MessageSchedule from "./MessageSchedule/MessageSchedule";

const { isDateLabelNeeded } = Helper;

const MessagesSchedule = () => {
  const classes = useStyles();

  const { appConfig, controlConfig, messagesSchedule, client } = useContext(AppContext);

  let aoMessagesWithDate = [];
  let dLastMessageDate = undefined;
  messagesSchedule.forEach(oMessage => {
    let dMessageDate = new Date(oMessage.at);

    if (isDateLabelNeeded(dMessageDate, dLastMessageDate)) {
      dLastMessageDate = dMessageDate;
      aoMessagesWithDate.push(
        Object.assign(
          {
            isDateLabel: true
          },
          oMessage
        )
      );
    }
    aoMessagesWithDate.push(oMessage);
  });

  useEffect(() => {
    jQuery("button[class*=react-scroll-to-bottom]").click();
  }, [client]);

  return (
    <ScrollToBottom
      initialScrollBehavior={"auto"}
      className={
        appConfig.darkTheme ? classes.messagesScrollContainerDark : classes.messagesScrollContainer
      }
    >
      {controlConfig.showLoader ? (
        <MessageSkeleton />
      ) : (
        aoMessagesWithDate.map((oMessage, i) => {
          if (oMessage.isDateLabel) {
            return (
              <div key={i}>
                <DateLabel dateLabel={oMessage.at} size={appConfig.size} />
              </div>
            );
          } else {
            return (
              <div key={i}>
                <MessageSchedule message={oMessage} />
              </div>
            );
          }
        })
      )}
    </ScrollToBottom>
  );
};

export default MessagesSchedule;

const useStyles = makeStyles(() => ({
  messagesScrollContainer: {
    overflow: "auto",
    flex: "auto",
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "#EFE7DE"
  },
  messagesScrollContainerDark: {
    overflow: "auto",
    flex: "auto",
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "#3e3e42"
  }
}));
