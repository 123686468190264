import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Helper from "../../../utils/Helper";
import AppContext from "../../../AppContext";

const { parseDate } = Helper;

const DateLabel = ({ dateLabel }) => {
  const { appConfig } = useContext(AppContext);

  const useStyles = makeStyles(() => ({
    dateLabelContainer: {
      display: "flex",
      justifyContent: "center",
      margin: "10px 0"
    },
    customChip: {
      backgroundColor: "#dff4fc !important",
      paddingLeft: `${appConfig.size + 5}px`,
      paddingRight: `${appConfig.size + 5}px`,
      paddingTop: `${appConfig.size}px`,
      paddingBottom: `${appConfig.size}px`,
      "& span": {
        fontSize: `${appConfig.size}px !important`
      }
    },
    customChipDark: {
      backgroundColor: "#2d2d30 !important",
      paddingLeft: `${appConfig.size + 5}px`,
      paddingRight: `${appConfig.size + 5}px`,
      paddingTop: `${appConfig.size}px`,
      paddingBottom: `${appConfig.size}px`,
      "& span": {
        fontSize: `${appConfig.size}px !important`
      }
    }
  }));
  const classes = useStyles();

  return (
    <div className={classes.dateLabelContainer}>
      <Chip
        className={appConfig.darkTheme ? classes.customChipDark : classes.customChip}
        label={parseDate(dateLabel)}
      />
    </div>
  );
};

DateLabel.propTypes = {
  dateLabel: PropTypes.string
};

export default DateLabel;
