import React, { useContext } from "react";
import { MenuItem, Popover, Typography } from "@material-ui/core";
import { Delete, Restore } from "@material-ui/icons";
import ApiCalls from "../../../utils/APIRequests";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../../AppContext";

const PopoverMessage = ({
  idSettingsMessage,
  bOpenSettingsMessage,
  anchorElSettingsMessage,
  handleClosePopoverSettingsMessage,
  setOpenDialogSchedule,
  ubm_id
}) => {
  const { t } = useTranslation();
  const { client, assessor, setMessagesSchedule, appConfig } = useContext(AppContext);
  const classes = useStyles();
  const deleteMessage = () => {
    ApiCalls.deleteMessageSchedule(appConfig.host, ubm_id, appConfig.source, appConfig.company)
      .then(() => {
        handleClosePopoverSettingsMessage();
        setTimeout(() => {
          ApiCalls.getMessagesSchedule(appConfig.host, client.phone, assessor.email, appConfig.source, appConfig.company).then(
            async response => {
              let aoMessagesSchedule = await response.json();
              setMessagesSchedule(aoMessagesSchedule.reverse());
            }
          );
        }, 1000);
      })
      .catch();
  };

  const updateMessage = () => {
    handleClosePopoverSettingsMessage();
    setOpenDialogSchedule(true);
  };

  return (
    <Popover
      id={idSettingsMessage}
      open={bOpenSettingsMessage}
      anchorEl={anchorElSettingsMessage}
      onClose={handleClosePopoverSettingsMessage}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <MenuItem
        className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
        button
        onClick={updateMessage}
      >
        <Restore color="primary" />
        <Typography color="primary">{t("reschedule")}</Typography>
      </MenuItem>
      <MenuItem
        className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
        button
        onClick={deleteMessage}
      >
        <Delete color="primary" />
        <Typography color="primary">{t("remove")}</Typography>
      </MenuItem>
    </Popover>
  );
};

const useStyles = makeStyles(() => ({
  menuItem: {
    display: "flex",
    gap: "0.5rem"
  },
  menuItemDark: {
    backgroundColor: "#3e3e42",
    "&:hover": {
      backgroundColor: "#2d2d30"
    },
    display: "flex",
    gap: "0.5rem",
    padding: "1rem"
  }
}));

export default PopoverMessage;

PopoverMessage.propTypes = {
  idSettingsMessage: PropTypes.string,
  bOpenSettingsMessage: PropTypes.bool,
  anchorElSettingsMessage: PropTypes.object,
  handleClosePopoverSettingsMessage: PropTypes.func,
  setOpenDialogSchedule: PropTypes.func,
  ubm_id: PropTypes.string
};
