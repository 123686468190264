import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { IconButton, ListItem, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import AppContext from "../../../../AppContext";

const ListItemDefaultMessage = ({
  message,
  index,
  pasteDefaultMessageInput,
  editDefaultMessage,
  deleteDefaultMessage
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { appConfig, setSelectedDefaultMessage } = useContext(AppContext);

  return (
    <ListItem
      key={index}
      className={classes.listItem}
      onClick={() => {
        pasteDefaultMessageInput(message.message);
        setSelectedDefaultMessage(null);
        // De momento los mensajes de usuario van como null
        // setSelectedDefaultMessage(message);
      }}
      button
    >
      <div className={classes.infoControlsAudio}>
        {message.title ? (
          <Tooltip title={<Typography variant="body2">{message.message}</Typography>}>
            <Typography className={classes.title} variant="body2">
              {message.title}
            </Typography>
          </Tooltip>
        ) : (
          <div>
            <Typography
              variant="subtitle1"
              className={appConfig.darkTheme ? classes.infoNoTitleDark : classes.infoNoTitle}
            >
              {t("infoEditTitle")}
            </Typography>
            <Typography variant="body2">{message.message}</Typography>
          </div>
        )}
        <div className={classes.iconButton}>
          <IconButton onClick={e => editDefaultMessage(e, message.ubm_id)}>
            <Edit color="primary" />
          </IconButton>
          <IconButton onClick={e => deleteDefaultMessage(e, message.ubm_id)}>
            <Delete color="primary" />
          </IconButton>
        </div>
      </div>
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  iconButton: {
    display: "flex"
  },
  listItem: {
    flexDirection: "column"
  },
  infoControlsAudio: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 25
  },
  title: {
    width: "100%"
  },
  infoNoTitle: {
    marginBottom: 10,
    color: "#595959"
  },
  infoNoTitleDark: {
    marginBottom: 10,
    color: "#999999"
  }
}));

ListItemDefaultMessage.propTypes = {
  message: PropTypes.object,
  index: PropTypes.number,
  pasteDefaultMessageInput: PropTypes.func,
  editDefaultMessage: PropTypes.func,
  deleteDefaultMessage: PropTypes.func
};

export default ListItemDefaultMessage;
