import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Badge,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from "@material-ui/core";
import AppContext from "../../AppContext";
import { Https, People } from "@material-ui/icons";
import ApiCalls from "../../utils/APIRequests";
import PopoverUserListItem from "./PopoverUserListItem";
import { useTranslation } from "react-i18next";
import Helper from "../../utils/Helper";

const UserListItem = ({ oUser, style, selectedIndex, fnUpdate }) => {
  const classes = useStyles();
  const { appConfig, setAppConfig, assessor, setMessages, setControlConfig, client } =
    useContext(AppContext);
  const [anchorMoreOptions, setAnchorMoreOptions] = React.useState(null);
  const bOpenMoreOptions = Boolean(anchorMoreOptions);
  const idMoreOptions = bOpenMoreOptions ? "simple-popover" : undefined;
  const { userSelected, setMessagesSchedule } = useContext(AppContext);
  const { t } = useTranslation();
  const { constants } = Helper;

  const handleOpenPopoverMore = event => {
    setAnchorMoreOptions(event.currentTarget);
  };

  const handleClosePopoverMore = () => {
    setAnchorMoreOptions(null);
  };

  const handleMarkUnread = oUser => {
    console.log(oUser)
    oUser.markasunread = true;
    handleClosePopoverMore();
    fnUpdate();
    ApiCalls.markAsUnread(appConfig.host, assessor.email, oUser.phone, oUser.source, oUser.company)
      .then(() => {
        //refreshUsers();
      })
      .catch(() => {
        //do nothing
      });
  };

  const handleMarkRead = oUser => {
    oUser.markasunread = false;
    handleClosePopoverMore();
    fnUpdate();
    ApiCalls.markReadMessages(
      appConfig.host,
      oUser.phone,
      oUser.source,
      oUser.course_id,
      appConfig.company,
      appConfig.unit,
      assessor.email
    )
      .then(() => {
        //refreshUsers();
      })
      .catch(() => {
        //do nothing
      });
  };

  const setShowLoaderUserList = bShowLoaderList => {
    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        showLoaderUserList: bShowLoaderList
      };
    });
  };

  const selectUser = () => {
    // if oUser has no key, do nothing since it is a broken user.
    if (oUser.key) {
      if (client.key !== oUser.key) {
        //setShowLoaderUserList(true);
        userSelected(oUser);
        setMessages([]);
        setAppConfig(appConfig => {
          return {
            ...appConfig,
            scheduleScreen: false,
            globalSearchScreen: false
          };
        });
        scheduleChatRoutine();
      } else {
        // handleMarkRead(oUser);
      }
    }
  };

  const scheduleChatRoutine = () => {
    ApiCalls.getMessagesSchedule(appConfig.host, oUser.phone, assessor.email, appConfig.source, appConfig.company)
      .then(async response => {
        let aoMessagesSchedule = await response.json();
        setMessagesSchedule(aoMessagesSchedule.reverse());
      })
      .catch(nStatus => {
        if (nStatus === 404 || nStatus === 204 || nStatus == 'Error: 204') {
          setMessagesSchedule([]);
        }
      });
  };

  const getSentimentClass = sentiment => {
    switch (sentiment) {
      case constants.sentimentTag.positive.key:
        return `${constants.sentimentTag.positive.colour}`;
      case constants.sentimentTag.negative.key:
        return `${constants.sentimentTag.negative.colour}`;
      case constants.sentimentTag.neutral.key:
        return `${constants.sentimentTag.neutral.colour}`;
      default:
        break;
    }
  };

  const getSentimentTitle = sentiment => {
    switch (sentiment) {
      case constants.sentimentTag.positive.key:
        return t("positive");
      case constants.sentimentTag.negative.key:
        return t("negative");
      case constants.sentimentTag.neutral.key:
        return t("neutral");
      default:
        return "";
    }
  };

  return (
    <div
      style={style}
      onContextMenu={e => {
        handleOpenPopoverMore(e);
        e.preventDefault();
      }}
    >
      <div className={classes.container}>
        <ListItem
          button
          selected={selectedIndex === oUser.phone}
          onClick={selectUser}
          alignItems="flex-start"
          classes={{ selected: appConfig.darkTheme ? classes.selectedItemDark : "" }}
        >
          {!!oUser.sentiment && (
            <>
              <Tooltip title={getSentimentTitle(oUser.sentiment)}>
                <div
                  className={classes.sentimentIndicator}
                  style={{ backgroundColor: getSentimentClass(oUser.sentiment) }}
                />
              </Tooltip>
            </>
          )}
          <ListItemAvatar>
            <Badge
              overlap="rectangular"
              badgeContent={oUser.unread ? oUser.unread : " "}
              variant={"standard"}
              color="error"
              invisible={oUser.unread === 0 && oUser.markasunread === false}
              showZero
            >
              <Avatar>{oUser.group ? <People /> : oUser.client_name?.charAt(0)}</Avatar>
            </Badge>
          </ListItemAvatar>
          <ListItemText
            className={classes.itemText}
            primary={
              oUser.group ? (
                <div className={classes.listItemTextContainer}>
                  <Typography component="span" className={classes.groupname} style={{ fontSize: `${appConfig.size}px` }}>
                    {oUser.displayname}
                  </Typography>
                </div>
              ) : (
                <Typography
                  component="span"
                  className={classes.name}
                  style={{ fontSize: `${appConfig.size}px` }}
                >
                  {oUser.client_lastname
                    ? oUser.client_name + " " + oUser.client_lastname
                    : oUser.client_name}
                </Typography>
              )
            }
            secondaryTypographyProps={{ component: "div" }}
            secondary={
              <div className={appConfig.darkTheme ? classes.courseDark : classes.course}>
                <Typography
                  component="span"
                  color="textPrimary"
                  style={{ fontSize: `${appConfig.size}px` }}
                >
                  {oUser.group ? "" : oUser.phone}
                </Typography>
                {!oUser.group && (
                  <Tooltip
                    placement="top-start"
                    title={
                      <Typography component="span" style={{ fontSize: `${appConfig.size}px` }}>
                        {oUser.course_name}
                      </Typography>
                    }
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ fontSize: `${appConfig.size}px` }}
                    >
                      {oUser.course_name && " - " + oUser.course_name}
                    </Typography>
                  </Tooltip>
                )}
              </div>
            }
          />
          <div>
            <Typography color="primary" className={classes.listItemIcon}>
              {oUser.displaydate}
            </Typography>
            <Tooltip title={<Typography variant="body2">{t("lockedGroup")}</Typography>}>
              <ListItemIcon className={classes.listItemIcon}>
                {oUser.group && !oUser.active && <Https color="primary" fontSize="small" />}
              </ListItemIcon>
            </Tooltip>
          </div>
        </ListItem>
        <PopoverUserListItem
          oUser={oUser}
          idMoreOptions={idMoreOptions}
          bOpenMoreOptions={bOpenMoreOptions}
          anchorMoreOptions={anchorMoreOptions}
          handleClosePopoverMore={handleClosePopoverMore}
          handleMarkRead={handleMarkRead}
          handleMarkUnread={handleMarkUnread}
        />
      </div>
      <Divider
        className={appConfig.darkTheme ? classes.customDividerDark : classes.customDivider}
        variant="inset"
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  buttonOptions: {
    position: "absolute",
    right: 10,
    height: 24,
    width: 24
  },
  container: {
    display: "flex",
    alignItems: "center"
  },
  selectedItemDark: {
    backgroundColor: "#242426 !important"
  },
  course: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  courseDark: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#FFFFFF"
  },
  customDivider: {
    marginLeft: "15px !important",
    marginRight: "15px !important"
  },
  customDividerDark: {
    marginLeft: "15px !important",
    marginRight: "15px !important",
    backgroundColor: "#595959"
  },
  invisible: {
    display: "none"
  },
  itemText: {
    marginRight: "44px"
  },
  listItemIcon: {
    display: "flex",
    justifyContent: "flex-end"
  },
  listItemTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
    maxWidth: "100%"
  },
  sentimentIndicator: {
    height: "100%",
    width: 8,
    position: "absolute",
    left: 1,
    top: 0
  },
  groupname: {
    display: '-webkit-box',
    maxHeight: '3em',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

UserListItem.propTypes = {
  selectedIndex: PropTypes.string,
  oUser: PropTypes.shape({
    assessor_email: PropTypes.string,
    assessor_first_name: PropTypes.string,
    assessor_last_name: PropTypes.string,
    client_email: PropTypes.string,
    client_familiarname: PropTypes.string,
    client_lastname: PropTypes.string,
    client_name: PropTypes.string,
    country: PropTypes.string,
    course_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    course_name: PropTypes.string,
    date_modified: PropTypes.string,
    filter: PropTypes.bool,
    language: PropTypes.string,
    phone: PropTypes.string,
    source: PropTypes.string,
    unread: PropTypes.number,
    user: PropTypes.string,
    markasunread: PropTypes.bool,
    displaydate: PropTypes.string,
    group: PropTypes.bool,
    active: PropTypes.bool,
    displayname: PropTypes.string,
    sentiment: PropTypes.string,
    key: PropTypes.string
  }),
  fnUpdate: PropTypes.func,
  style: PropTypes.object
};

export default UserListItem;
