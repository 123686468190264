import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Popover,
  Typography,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../../AppContext";
import { useTranslation } from "react-i18next";
import InputBaseDefaultMessages from "./InputBaseDefaultMessages";
import ListUserDefaultMessages from "./ListUserDefaultMessages";
import InputBaseDefaultAudio from "./InputBaseDefaultAudio";
import ApiCalls from "../../../utils/APIRequests";
import MicIcon from "@material-ui/icons/Mic";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MessageIcon from "@material-ui/icons/Message";
import styles from "../../Chat/Customisation.module.css";
import { v4 as uuidv4 } from "uuid";
import TabsUserCompany from "./TabsUserCompany";
import { Subject } from "@material-ui/icons";
import ListUserCompanyDefaultMessages from "./ListUserCompanyDefaultMessages";

const DialogTemplateDefaultMessages = ({
  openDialogDefaultMessages,
  setOpenDialogDefaultMessages,
  setInputMessage,
  setInputMicRecording,
  client
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig, 
    setAppConfig, 
    assessor, 
    setSkipSaveAppConfig, 
    defaultMessages
  } = useContext(AppContext);
  const [newDefaultMessage, setNewDefaultMessage] = useState(false);
  const [isNewDefaultMessageAudio, setIsNewDefaultMessageAudio] = useState();
  const [updateDefaultMessageIndex, setUpdateDefaultMessageIndex] = useState(null);
  const [textDefaultMessage, setTextDefaultMessage] = useState("");
  const [textDefaultMessageTitle, setTextDefaultMessageTitle] = useState("");
  const [anchorElNewDefaultMessage, setAnchorElNewDefaultMessage] = useState();
  const [hasTitle, setHasTitle] = useState(true);
  const [selectedTab, setSelectedTab] = useState('editables')

  const bOpenPopoverDefaultMessages = Boolean(anchorElNewDefaultMessage);
  const idNewDefaultMessages = bOpenPopoverDefaultMessages ? "newDefalut-popover" : undefined;

  // Propiedades del grabar audio
  const [micRecording, setMicRecording] = useState({
    isRecording: false,
    blobURL: "",
    blob: undefined,
    isBlocked: false
  });

  // Ojo al como modificamos la lista del appConfig, que a veces el useEffect no se entera y no lanza la api de save
  const saveDefaultMessage = () => {
    // Cogemos los mensajes que teniamos guardados
    let newDefaultMessages = [];
    // Miramos si queremos un mensaje nuevo o editar un mensaje
    if (updateDefaultMessageIndex !== null) {
      newDefaultMessages = appConfig.default_messages.map(oMessage =>
        oMessage.ubm_id === updateDefaultMessageIndex
          ? { ...oMessage, message: textDefaultMessage, title: textDefaultMessageTitle }
          : oMessage
      );
    } else {
      newDefaultMessages = appConfig.default_messages;
      newDefaultMessages = appConfig.default_messages.concat({
        message: textDefaultMessage,
        title: textDefaultMessageTitle,
        ubm_id: uuidv4()
      });
    }

    setSkipSaveAppConfig(true);
    let oAppConfig = appConfig;
    oAppConfig.default_messages = newDefaultMessages;
    setAppConfig(oAppConfig);
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);

    setNewDefaultMessage(false);
    setTextDefaultMessage("");
    setTextDefaultMessageTitle("");
    setUpdateDefaultMessageIndex(null);
  };

  const saveDefaultAudio = () => {
    const audioFile = new File([micRecording.blob], "audio_" + textDefaultMessage);
    ApiCalls.postDefaultAudio(appConfig.host, assessor.email, textDefaultMessage, audioFile, appConfig.source, appConfig.company)
      .then(response => {
        setSkipSaveAppConfig(true);
        let oAppConfig = appConfig;
        oAppConfig.default_messages = response.default_messages;
        setAppConfig(oAppConfig);
        ApiCalls.saveUserSettings(assessor.email, oAppConfig);

        setNewDefaultMessage(false);
        setTextDefaultMessage("");
        setUpdateDefaultMessageIndex(null);
        setHasTitle(true);
      })
      .catch(() => {});
  };

  const handleCancel = () => {
    if (newDefaultMessage) {
      setNewDefaultMessage(false);
      setUpdateDefaultMessageIndex(null);
      setTextDefaultMessage("");
      setTextDefaultMessageTitle("");
      setHasTitle(true);
    } else {
      setUpdateDefaultMessageIndex(null);
      setTextDefaultMessage("");
      setTextDefaultMessageTitle("");
      setOpenDialogDefaultMessages(false);
      setHasTitle(true);
    }
  };

  const handleClosePopover = () => {
    setAnchorElNewDefaultMessage(null);
    setTextDefaultMessage("");
    setTextDefaultMessageTitle("");
    setMicRecording({});
  };

  const handleOpenPopover = event => {
    setAnchorElNewDefaultMessage(event.currentTarget);
  };

  const openMessagesTemplate = () => {
    setIsNewDefaultMessageAudio(false);
    setNewDefaultMessage(true);
    handleClosePopover();
  };

  const openMessagesTemplateAudio = () => {
    setIsNewDefaultMessageAudio(true);
    setNewDefaultMessage(true);
    handleClosePopover();
  };

  const handleNoAudioTitle = () => {
    setHasTitle(false);
  };

  return (
    <Dialog
      open={openDialogDefaultMessages}
      onClose={(e, action) => {
        if (action !== "backdropClick") {
          setOpenDialogDefaultMessages(false);
        }
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      id={appConfig.darkTheme ? styles["proeduca-messenger-dark"] : styles["proeduca-messenger"]}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        {t("default_messages")}
      </DialogTitle>
      <div style={{backgroundColor: appConfig.darkTheme ? "#3e3e42" : 'transparent'}}>
        <TabsUserCompany tabSelected={selectedTab} setTabSelected={setSelectedTab} />
      </div>
      <DialogContent className={appConfig.darkTheme ? classes.dialogDark : classes.dialog}>
        
        <div className={classes.tabsContent}>
        {selectedTab == 'editables' ? (
          newDefaultMessage ? (
            isNewDefaultMessageAudio ? (
              <InputBaseDefaultAudio
                textDefaultMessage={textDefaultMessage}
                setTextDefaultMessage={setTextDefaultMessage}
                micRecording={micRecording}
                setMicRecording={setMicRecording}
                hasTitle={hasTitle}
                setHasTitle={setHasTitle}
              />
            ) : (
              <InputBaseDefaultMessages
                textDefaultMessage={textDefaultMessage}
                setTextDefaultMessage={setTextDefaultMessage}
                textDefaultMessageTitle={textDefaultMessageTitle}
                setTextDefaultMessageTitle={setTextDefaultMessageTitle}
              />
            )
          ) : (
            <ListUserDefaultMessages
              setInputMessage={setInputMessage}
              setNewDefaultMessage={setNewDefaultMessage}
              setUpdateDefaultMessageIndex={setUpdateDefaultMessageIndex}
              setTextDefaultMessage={setTextDefaultMessage}
              setOpenDialogDefaultMessages={setOpenDialogDefaultMessages}
              setIsNewDefaultMessageAudio={setIsNewDefaultMessageAudio}
              setInputMicRecording={setInputMicRecording}
              setTextDefaultMessageTitle={setTextDefaultMessageTitle}
            />
          )
        ) : (
              <ListUserCompanyDefaultMessages 
                defaultMessages={defaultMessages}
                setInputMessage={setInputMessage}
                setOpenDialogDefaultMessages={setOpenDialogDefaultMessages}
                client={client}
              />
        )}
        </div>
      </DialogContent>

      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
      >
        <Button onClick={handleCancel} color="primary">
          <Typography variant="body1" color="primary">
            {newDefaultMessage ? t("cancel") : t("close")}
          </Typography>
        </Button>
        {selectedTab == 'editables' && (
          <>
            <Button
              color="primary"
              className={classes.buttonNewDefaultMessage}
              onClick={
                !newDefaultMessage
                  ? handleOpenPopover
                  : isNewDefaultMessageAudio
                  ? textDefaultMessage === ""
                    ? handleNoAudioTitle
                    : saveDefaultAudio
                  : saveDefaultMessage
              }
            >
              <Typography variant="body1" color="secondary">
                {newDefaultMessage ? t("save") : t("newTemplate")}
              </Typography>
            </Button>

            <Popover
              id={idNewDefaultMessages}
              open={bOpenPopoverDefaultMessages}
              anchorEl={anchorElNewDefaultMessage}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <MenuItem
                className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
                onClick={openMessagesTemplate}
              >
                <MessageIcon color="primary" />
                <Typography color="primary" variant="body1">
                  {t("newDefaultMessage")}
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
                onClick={openMessagesTemplateAudio}
              >
                <MicIcon color="primary" />
                <Typography color="primary" variant="body1">
                  {t("newDefaultAudio")}
                </Typography>
              </MenuItem>
            </Popover>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogTemplateDefaultMessages;

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff",
    minWidth: "500px"
  },
  dialog: {
    padding: 25,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  dialogDark: {
    backgroundColor: "#3e3e42",
    color: "#fff",
    padding: 25,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  dialogActions: {
    borderTop: "1px solid #bdbdbd",
    padding: "0.8rem 0",
    boxSizing: 'border-box',
    paddingRight: 15
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959",
    padding: "0.8rem 0",
    paddingRight: 15
  },
  buttonNewDefaultMessage: {
    backgroundColor: "#065d54",
    padding: "0.5rem 0.8rem",
    "&:hover": {
      backgroundColor: "#207060",
      opacity: [0.9, 0.8, 0.7]
    }
  },
  menuItem: {
    display: "flex",
    gap: "0.5rem",
    padding: "1rem 0.7rem"
  },
  menuItemDark: {
    backgroundColor: "#3e3e42",
    "&:hover": {
      backgroundColor: "#2d2d30"
    },
    display: "flex",
    gap: "0.5rem",
    padding: "1rem 0.7rem"
  },
  info24h: {
    display: "flex",
    justifySelf: "left"
  },
  tabsContent: {
    padding: '15px',
    boxSizing: 'border-box',
  }
}));

DialogTemplateDefaultMessages.propTypes = {
  openDialogDefaultMessages: PropTypes.bool,
  setOpenDialogDefaultMessages: PropTypes.func,
  setInputMessage: PropTypes.func,
  setUrlDefaultAudio: PropTypes.func,
  setMicRecording: PropTypes.func,
  setInputMicRecording: PropTypes.func
};
