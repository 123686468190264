import React from "react";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SubtitlesIcon from "@material-ui/icons/Subtitles";

const TranscriptionButton = ({ setOpenDialogTranscription }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOpenDialogTranscription = () => {
    setOpenDialogTranscription(true);
  };

  return (
    <Tooltip title={<Typography variant="body2">{t("transcription")}</Typography>}>
      <IconButton
        color="primary"
        component="span"
        className={classes.iconTranslation}
        onClick={handleOpenDialogTranscription}
        size="small"
      >
        <SubtitlesIcon />
      </IconButton>
    </Tooltip>
  );
};

export default TranscriptionButton;

const useStyles = makeStyles(() => ({
  iconTranslation: {
    alignSelf: "center",
    marginTop: "5%"
  }
}));

TranscriptionButton.propTypes = {
  setOpenDialogTranscription: PropTypes.func
};
