import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import clsx from 'clsx';
import {
	Typography,
	Tooltip,
	Snackbar
} from "@material-ui/core";
// import { IconButton, makeStyles, Snackbar, Tooltip, Typography } from "@material-ui/core";

import {
	Grade,
	Person
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import AppContext from "../../../AppContext";
import CloseIcon from '@material-ui/icons/Close';
import ApiCalls from "./../../../utils/APIRequests";

const DialogDefaultMessages = ({
	inputText,
	setInputText,
	openDialogDefaultMessages,
	setOpenDialogDefaultMessages
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [messageHover, setMessageHover] = useState(0);
	const {
		appConfig,
		setAppConfig, 
		defaultMessages,
		client,
		setSelectedDefaultMessage,
		assessor
	} = useContext(AppContext);
	const [filteredDefaultMessages, setFilteredDefaultMessages] = useState([]);

	// Crear Ref para messageHover
	const messageHoverRef = useRef(messageHover);

	// Crear Ref para filteredDefaultMessages
	const filteredDefaultMessagesRef = useRef(filteredDefaultMessages);

	// Ref para los eventos de onkeydown
	const openDialogDefaultMessagesRef = useRef(openDialogDefaultMessages);

	// Ref para el contenedor de mensajes
	const messagesContainerRef = useRef(null);

	// Refs para cada mensaje
	const messageRefs = useRef([]);

	useEffect(() => {
		let messages = filterMessagesByCompanyAndSource(defaultMessages);
		messages = filterMessagesByCompanyFilters(messages);
		setFilteredDefaultMessages(messages);
	}, [defaultMessages, client, appConfig.defaultCompanyMessagesfilters]);

	useEffect(() => {
		setOpenDialogDefaultMessages((inputText.length > 0 && inputText[0] == '/') ? true : false);
		let findText = inputText.slice(1).toLowerCase();
		let filteredByCompanyAndSource = filterMessagesByCompanyAndSource(defaultMessages);
		if (inputText.length >= 1 && inputText[1] != '/' ){
			filteredByCompanyAndSource = filterMessagesByCompanyFilters(filteredByCompanyAndSource);
		} else if (inputText.length >= 1 && inputText[1] == '/' ) {
			findText = inputText.slice(2).toLowerCase();
		}
		if (findText.length > 0) {
			let messages = filteredByCompanyAndSource?.filter(item => 
				item.text?.toLowerCase().includes(findText) || item.title?.toLowerCase().includes(findText)
			);
			setFilteredDefaultMessages(messages)
		} else {
			setFilteredDefaultMessages(filteredByCompanyAndSource)
		}
	}, [inputText]);

	const filterMessagesByCompanyAndSource = () => {
		let messages = [];
		if (typeof client != 'undefined'
			&& client.company?.length > 0
			&& client.source?.length > 0
		) {
			messages = defaultMessages.filter(message => {
				if (message.type == 'user_message') {
					return message;
				} else if (
					message.type == 'system_message'
					&& message.parents.length > 0
					&& message.parents.indexOf(client.company.toUpperCase()) >= 0
					&& message.parents.indexOf(client.source.toUpperCase()) >= 0
				) {
					return message;
				}
			})
		}
		return messages;
	}

	const filterMessagesByCompanyFilters = (messages) => {
		if (appConfig.defaultCompanyMessagesfilters?.length) {
            appConfig.defaultCompanyMessagesfilters.map(filter => {
                messages = messages.filter(item =>{
					return item.type == 'user_message' 
					|| ( item.type == 'system_message'
						&& (
							item.text?.toLowerCase().includes(filter.text.toLowerCase())
							|| item.title?.toLowerCase().includes(filter.text.toLowerCase())
							|| item.parents?.indexOf(filter.text.toUpperCase()) >= 0
							|| item.parents?.join(' ').toLowerCase().includes(filter.text.toLowerCase())
						)
					)
				})
            })
        }
		return messages;
	}

	const handleKeyDown = useCallback((ev) => {
		// Acceder a los valores actuales a través de los Refs
		let currentMessageHover = messageHoverRef.current ?? 0;
		const currentFilteredDefaultMessages = filteredDefaultMessagesRef.current;

		if (openDialogDefaultMessagesRef.current) {
			switch (ev.key) {
				case 'Tab':
				case 'ArrowDown':
					{
						ev.preventDefault();
						if (ev.key == 'Tab' && ev.shiftKey) { // shift + tab == retroceder
							if (currentMessageHover - 1 >= 0) {
								currentMessageHover = currentMessageHover - 1;
							} else {
								currentMessageHover = currentFilteredDefaultMessages.length - 1;
							}
						} else { // tab || flecha abajo == avanzar
							if (currentMessageHover + 1 <= currentFilteredDefaultMessages.length - 1) {
								currentMessageHover = currentMessageHover + 1;
							} else {
								currentMessageHover = 0;
							}
						}
						setMessageHover(currentMessageHover);
						messageRefs.current[currentMessageHover]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
						break;
					}
				case 'ArrowUp':
					{
						ev.preventDefault();
						if (currentMessageHover - 1 >= 0) {
							currentMessageHover = currentMessageHover - 1;
						} else {
							currentMessageHover = currentFilteredDefaultMessages.length - 1;
						}
						setMessageHover(currentMessageHover);
						messageRefs.current[currentMessageHover]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
						break;
					}
				case 'Enter':
					{
						ev.preventDefault();
						if (currentMessageHover != null && typeof currentFilteredDefaultMessages[currentMessageHover] != 'undefined' && typeof currentFilteredDefaultMessages[currentMessageHover].text != 'undefined') {
							setInputText(currentFilteredDefaultMessages[currentMessageHover].text);
							if (currentFilteredDefaultMessages[currentMessageHover]?.type == 'system_message') {
								setSelectedDefaultMessage(currentFilteredDefaultMessages[currentMessageHover]);
							} else {
								setSelectedDefaultMessage(null);
							}
						}
						break;
					}
				default:
					break;
			}
		}

	}, []);

	useEffect(() => {
		openDialogDefaultMessagesRef.current = openDialogDefaultMessages;

		if (openDialogDefaultMessages) {
			document.addEventListener('keydown', handleKeyDown);
		} else {
			document.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		}
	}, [openDialogDefaultMessages, handleKeyDown]); // Dependencia actualizada

	useEffect(() => {
		messageHoverRef.current = messageHover;
	}, [messageHover]);

	useEffect(() => {
		filteredDefaultMessagesRef.current = filteredDefaultMessages;
	}, [filteredDefaultMessages]);

    const removeFilter = (text) => {
        setAppConfig(oAppConfig => {
            const newConfig = {
                ...oAppConfig,
              defaultCompanyMessagesfilters: oAppConfig.defaultCompanyMessagesfilters?.filter(filter => filter.text.toLowerCase() != text.toLowerCase()) ?? []
            }
            ApiCalls.saveUserSettings(assessor.email, newConfig);
            return newConfig;
        }); 
    }
	
	return (
		<div
			className={clsx(
				classes.mainContainer,
				openDialogDefaultMessages == false ? classes.defaultMessagesContainerHidden : '',
				appConfig.darkTheme ? classes.defaultMessagesContainerBGDark : classes.defaultMessagesContainerBG,
			)}
		>
			{filteredDefaultMessages && appConfig.defaultCompanyMessagesfilters && appConfig.defaultCompanyMessagesfilters.length > 0 && (
				<div className={classes.filtersContainer}>
					{appConfig.defaultCompanyMessagesfilters.map((filter, index) => {
						if (filter.text) {
							return (
								<div className={classes.filter} key={index} onClick={() => removeFilter(filter.text)}>
									<Typography className={classes.filterText}>{filter.text}</Typography>
									<CloseIcon className={classes.filterIcon}/>
								</div>
							);
						}
					})}
				</div>
			)}
			<div
				ref={messagesContainerRef}
				className={clsx(
					classes.defaultMessagesContainer,
					
				)}
			>
				
				{filteredDefaultMessages && filteredDefaultMessages.map((item, index) => {
					return (
						<div key={`default-message-box-${index}`}>
							{index > 0 && (
								<div className={classes.separator}>
									<svg className={classes.separatorSVG} width="100%" height="1px" viewBox="0 0 1000 1">
										<rect className={classes.separatorLine} width="100%" height="1px" />
									</svg>
								</div>
							)}
							<div
								ref={el => messageRefs.current[index] = el}
								key={`default-message-${index}`}
								tabIndex={item.focus}
								onMouseEnter={() => { setMessageHover(index) }}
								onMouseLeave={() => { setMessageHover(null) }}
								onFocus={() => { setMessageHover(index) }}
								className={
									clsx(
										classes.defaultMessageDiv,
										messageHover == index ? classes.defaultMessageDivHover : ''
									)}
								onClick={() => { setInputText(item.text); setSelectedDefaultMessage(item); }}
							>

								{item.text.length > 150 ? (
									<Tooltip className={clsx(classes.defaultMessageText, item.type == 'system_message' && classes.defaultSystemMessagesBackground)} title={<Typography fontSize={appConfig.size - 2}>{item.text}</Typography>} interactive>
										<Typography>
											{item.type == 'system_message' && (
												<Grade className={classes.messageTypeIcon} />
											)}
											{item.type == 'user_message' && (
												<Person className={classes.messageTypeIcon} />
											)}<b>{item.title}</b> - {item.text.substring(0, 150)}...
										</Typography>
									</Tooltip>
								) : (
									<Typography className={clsx(classes.defaultMessageText, item.type == 'system_message' && classes.defaultSystemMessagesBackground)}>
										{item.type == 'system_message' && (
											<Grade className={classes.messageTypeIcon} />
										)}
										{item.type == 'user_message' && (
											<Person className={classes.messageTypeIcon} />
										)}
										<b>{item.title}</b> - {item.text}
									</Typography>
								)}

							</div>
						</div>
					)
				})}
			</div>
		</div>
	);
};

export default DialogDefaultMessages;

const useStyles = makeStyles(() => ({
	mainContainer: {
		boxSizing: 'border-box',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		height: 'auto',
		minHeight: 0,
		paddingBottom: 0,
		paddingTop: 0,
	},
	separator: {
		width: '100%',
		height: '1px',
		position: 'relative',
		overflow: 'hidden',
		backgroundColor: 'transparent',
		display: 'block',
		margin: 0,
		padding: 0
	},
	separatorSVG: {
		position: 'relative',
		display: 'block'
	},
	separatorLine: {
		fill: '#333'
	},
	defaultMessagesContainer: {
		boxSizing: 'border-box',
		width: '100%',
		maxHeight: '100%',
		paddingLeft: 10,
		paddingRight: 10,
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	defaultMessagesContainerBG: {
		backgroundColor: '#f1f1f1',
		color: '#585858'
	},
	defaultMessagesContainerBGDark: {
		backgroundColor: '#29292b'
	},
	defaultMessagesContainerHidden: {
		display: 'none'
	},
	defaultMessageDiv: {
		display: 'block',
		position: 'relative',
		textAlign: 'start',
		cursor: 'pointer',
		boxSizing: 'border-box',
		padding: 5
	},
	defaultMessageDivHover: {
		backgroundColor: 'rgba(0,0,0,.08)'
	},
	defaultMessageText: {
		flexGrow: 1
	},
	messageTypeIcon: {
		fontSize: 12,
		verticalAlign: 'middle',
		marginRight: 5
	},
	defaultSystemMessagesBackground: {
		backgroundColor: '#97e6de33'
	},
	filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        margin: 5,
		backgroundColor: 'transparent',
		boxSizing: 'border-box',
		flexGrow: 1,
		minHeight: 20,
        '&::-webkit-scrollbar': {
            height: '8px !important',
            width: '8px !important',
        }
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        border: '1px solid black',
        borderRadius: 5,
        marginRight: 5,
        padding: 3,
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: 'red',
            color: 'white'
        }
    },
    filterText: {
        fontSize: '12px !important',
        lineHeight: '12px !important',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap'
    },
    filterIcon: {
        fontSize: 12,
        verticalAlign: 'middle',
        lineHeight: 12
    },
}));