import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const EmptyTab = ({ message, variant }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography variant={variant === undefined ? "h4" : variant} className={classes.message}>
      {message === undefined ? t("emptyTabMessage") : message}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  message: {
    padding: "25% 0",
    textAlign: "center"
  }
}));

EmptyTab.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string
};

export default EmptyTab;
