import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppContext from "../../../AppContext";
import Helper from "../../../utils/Helper";
import ApiCalls from "../../../utils/APIRequests";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReplyIcon from '@material-ui/icons/Reply';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Icon from "./../../Messages/Message/Icon/Icon";
import {
    AppBar,
    Box,
    IconButton,
    InputBase,
    Toolbar,
    Tooltip,
    Typography
  } from "@material-ui/core";

const Template = ({ message }) => {
    const {
        text,
        date_modified,
        direction,
        messagestatus,
        attach,
        mime,
        ubm_id,
        full_name,
        name_color,
        transcription,
        source,
        inreplyto,
        twillio_phone,
        assesor_email,
        assesor_name,
        assesor_first_name,
        assesor_last_name,
        template,
        template_data,
        template_params
    } = message;

    const { appConfig, client, assessor } = useContext(AppContext);
    const classes = useStyles({ appConfig, direction });
    const { t } = useTranslation();
    const [parsedParams, setParsedParams] = useState({});
    const [expandedHeaderImageContainer, setExpandedHeaderImageContainer] = useState(true);

    useEffect(() => {
        /* let auxTemplateParams = `
        {
            "body": {
            },
            "footer": {
            },
            "button": [
                {
                    "text": "Visítanos",
                    "url": "https://estudiar.unir.net/mx/mx-gen-ma-gene-maestrias-sep-mexico-tamaulipas/"
                },
                {
                    "text": "botón 2",
                    "url": "https://estudiar.unir.net/mx/mx-gen-ma-gene-maestrias-sep-mexico-tamaulipas/"
                }
            ],
            "header": [
                {
                    "format": "text",
                    "text": "¿Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dapibus ante ut fermentum fermentum. Suspendisse faucibus fringilla ultrices. In luctus eleifend neque molestie posuere.?"
                }
            ]
        }
        `; */
        // console.log(template_params)
        // setParsedParams(JSON.parse(auxTemplateParams));
        setParsedParams(JSON.parse(template_params));
    }, [template_params]);

    const renderHeader = (header) => (
        header.map((item, index) => {
            switch (item.format) {
                case 'image':
                    return (
                        <Tooltip
                            key={index} 
                            title={
                                (<Typography variant="body2">
                                    {expandedHeaderImageContainer
                                    ? t('templateHeaderExpandTooltip')
                                    : t('templateHeaderContractTooltip')}
                                </Typography>)
                            }
                        >
                            <div
                                
                                className={expandedHeaderImageContainer ? classes.templateHeaderImageContainer : classes.templateHeaderImageContainerSmall }
                                onClick={ () => setExpandedHeaderImageContainer(!expandedHeaderImageContainer) }
                            >
                                <div className={expandedHeaderImageContainer ? classes.templateHeaderImageExpandContainer : classes.templateHeaderImageExpandContainerHidden}>
                                    <Typography variant="body2" className={classes.templateHeaderImageExpandText}>{t('templateHeaderExpand')}</Typography>
                                </div>
                                <img className={classes.templateHeaderImage} src={item.link} alt="header" />
                            </div>
                        </Tooltip>
                    );
                case 'video':
                    return (
                        <Tooltip
                            key={index} 
                            title={
                                (<Typography variant="body2">
                                    {expandedHeaderImageContainer
                                    ? t('templateHeaderExpandTooltip')
                                    : t('templateHeaderExpandedVideoTooltip')}
                                </Typography>)
                            }
                        >
                            <div
                                
                                className={expandedHeaderImageContainer ? classes.templateHeaderImageContainer : classes.templateHeaderImageContainerSmall }
                                onClick={ (ev) => {if (expandedHeaderImageContainer) {ev.preventDefault(); setExpandedHeaderImageContainer(!expandedHeaderImageContainer)}} }
                            >
                                <div className={expandedHeaderImageContainer ? classes.templateHeaderImageExpandContainer : classes.templateHeaderImageExpandContainerHidden}>
                                    <Typography variant="body2" className={classes.templateHeaderImageExpandText}>{t('templateHeaderExpand')}</Typography>
                                </div>
                                <video key={index} className={classes.templateHeaderVideo} controls>
                                    <source src={item.link} />
                                </video>
                            </div>
                        </Tooltip>
                    );
                case 'document':
                    return (
                        <a key={index} href={item.link} className={classes.templateHeaderDocument} target="_blank" rel="noreferrer">
                            <div className={classes.templateHeaderDocumentTextContainer}>
                                <CloudDownloadIcon className={classes.templateHeaderDocumentIcon} />
                                <span className={classes.templateHeaderDocumentText}>{t('templateHeaderDocumentText')}</span>
                            </div>
                        </a>
                    )
                case 'text':
                    return (
                        <div key={index}>
                            <ReactMarkdown
                                key={index}
                                className={classes.templateHeaderText}
                                remarkPlugins={[remarkGfm]}
                            >
                                {item.text}
                            </ReactMarkdown>
                        </div>
                    )
                default:
                    return null;
            }
        })
    );

    const renderBody = (body) => (
        Object.values(body).map((item, index) => (
            item.text && (
                <ReactMarkdown
                    key={index}
                    className={classes.templateBodyText}
                    remarkPlugins={[remarkGfm]}
                >
                    {item.text}
                </ReactMarkdown>
            )
        ))
    );

    const renderFooter = (footer) => (
        Object.values(footer).map((item, index) => (
            item.text && (
                <ReactMarkdown
                    key={index}
                    className={classes.templateFooterText}
                    remarkPlugins={[remarkGfm]}
                >
                    {item.text}
                </ReactMarkdown>
            )
        ))
    );

    const renderButton = (button) => (
        Object.values(button).map((item, index) => (
            item.text && (
                <a key={index} className={appConfig.darkTheme ? classes.templateButtonLink : classes.templateButtonLinkLight} href={item.url} target="_blank" rel="noreferrer">
                    {item.url?.length > 0 ? (
                        <OpenInNewIcon className={classes.templateButtonLinkIcon} />
                    ) : (
                        <ReplyIcon className={classes.templateButtonLinkIcon} />
                    )}
                    {item.text}
                </a>
            )
        ))
    );

    return (
        <div className={clsx(classes.messageContainer, direction !== 'received' ? classes.flexEnd : classes.flexStart)}>
            <div className={clsx(classes.messageContent, appConfig.darkTheme ? classes.messageContentDark : classes.messageContentLight )}>
                {parsedParams.header && renderHeader(parsedParams.header)}
                {parsedParams.body && <div className={classes.templateBody}>{renderBody(parsedParams.body)}</div>}
                {parsedParams.footer && <div className={classes.templateFooter}>{renderFooter(parsedParams.footer)}</div>}
                {parsedParams.button && <div className={classes.templateButton}>{renderButton(parsedParams.button)}</div>}
            </div>
            {direction !== "received" && <div className={classes.statusIcon}><Icon messageStatus={messagestatus} twillio_phone={twillio_phone} /></div>}
        </div>
    );
};

Template.propTypes = {
    message: PropTypes.shape({
        text: PropTypes.string,
        date_modified: PropTypes.string,
        direction: PropTypes.string,
        messagestatus: PropTypes.string,
        attach: PropTypes.string,
        mime: PropTypes.string,
        ubm_id: PropTypes.string,
        full_name: PropTypes.string,
        name_color: PropTypes.string,
        transcription: PropTypes.string,
        source: PropTypes.string,
        inreplyto: PropTypes.string,
        twillio_phone: PropTypes.string,
        assesor_email: PropTypes.string,
        assesor_name: PropTypes.string,
        assesor_first_name: PropTypes.string,
        assesor_last_name: PropTypes.string,
        template: PropTypes.string,
        template_data: PropTypes.object,
        template_params: PropTypes.string
    }).isRequired
};

export default Template;

const useStyles = makeStyles(() => ({
    messageContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
    },
    flexStart: {
        justifyContent: "flex-start"
    },
    flexEnd: {
        justifyContent: "flex-end"
    },
    statusIcon: {
        alignSelf: 'flex-end',
        paddingBottom: 5
    },
    messageContent: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: "16px",
        padding: "15px 20px 5px 20px",
        maxWidth: "70%",
        margin: '0px 0px 10px 10px' // falta el icono de sltatus
    },
    messageContentLight:  {
        backgroundColor: (props) => props.direction == 'send' ? '#E2FFC7' : '#FEFEFE',
    },
    messageContentDark:  {
        backgroundColor: (props) => props.direction == 'send' ? '#065D54' : '#2d2d30',
    },
    templateHeader: {
        alignSelf: "center",
        marginTop: "5%"
    },
    templateHeaderVideo: {
        width: '100%',
        height: 'auto'
    },
    templateHeaderDocument: {
        textDecoration: 'none',
        padding: '10px 0',
        borderRadius: 5,
        backgroundColor: '#cacaca',
        "&:hover": {
            backgroundColor: '#a7a7a7',
        }
    },
    templateHeaderDocumentTextContainer: {
        borderRadius: 5,
        textAlign: 'center',
        
    },
    templateHeaderDocumentText: {
        fontSize: 30,
        textDecoration: 'none',
        color: '#ededed'
    },
    templateHeaderDocumentIcon: {
        verticalAlign: 'middle',
        fontSize: 40,
        color: '#ededed',
        marginRight: 10
    },
    templateHeaderImage: {
        width: '100%',
        height: 'auto'
    },
    templateHeaderImageContainer: {
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        height: 60,
        borderRadius: 5,
        textAlign: 'center'
    },
    templateHeaderImageContainerSmall: {
        height: 'auto',
        cursor: 'pointer',
        borderRadius: 0,
    },
    templateHeaderImageExpandContainer:{
        position: 'absolute',
        width: '100%',
        height: 60,
        backgroundColor: 'rgba(80, 80, 80, 0.8)',
        color: '#FFF'
    },
    templateHeaderImageExpandContainerHidden: {
        display: 'none'
    },
    templateHeaderImageExpandText:{
        fontSize: '30px !important',
        color: '#FFF',
        lineHeight: '60px !important',
        textAlign: 'center'
    },
    templateHeaderText:  {
        wordBreak: "break-word",
        maxWidth: "none",
        "& p": {
            fontSize: (props) => `${props.appConfig.size}px !important`,
            fontWeight: 'bold !important'
        }
    },
    templateBody: {},
    templateBodyText: {
        wordBreak: "break-word",
        maxWidth: "none",
        "& p": {
            fontSize: (props) => `${props.appConfig.size}px !important`
        }
    },
    templateFooter: {},
    templateFooterText: {
        wordBreak: "break-word",
        maxWidth: "none",
        "& p": {
            fontSize: (props) => `${props.appConfig.size - 2}px !important`,
            fontStyle: 'italic !important',
            color: '#a1a1a1'
        }
    },
    templateButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        borderTop: '1px solid #333',
        padding: '10px 0'
    },
    templateButtonLink: {
        textDecoration: 'none',
        fontSize: (props) => `${props.appConfig.size + 4}px !important`,
        margin: '5px 0',
        border: '1px solid #848484',
        borderRadius: 5,
        backgroundColor: 'rgba(80, 80, 80, 0.8)',
    },
    templateButtonLinkLight: {
        textDecoration: 'none',
        fontSize: (props) => `${props.appConfig.size + 4}px !important`,
        margin: '5px 0',
        border: '1px solid #c5c5c5',
        borderRadius: 5,
        backgroundColor: 'rgba(220, 220, 220, 0.8)',
    },
    templateButtonLinkIcon: {
        verticalAlign: 'middle',
        paddingRight: 5,
        fontSize: (props) => `${props.appConfig.size + 4}px !important`,
        alignSelf: 'flex-start'
    }
}));