import React, { useContext, useEffect, useState } from "react";
import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
import ApiCalls from "../../utils/APIRequests";
import LinksTab from "./TabItems/LinksTab";
import AudioTab from "./TabItems/AudioTab";
import FileTab from "./TabItems/FileTab";
import ImagesTab from "./TabItems/ImagesTab";
import EmptyTab from "./TabItems/EmptyTab";
import Loader from "./Loader";

const TabsAttachedFiles = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { client, assessor, appConfig } = useContext(AppContext);

  const [tabValue, setTabValue] = useState("images");
  const [media, setMedia] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getMediaUser = newValue => {
    ApiCalls.postMediaUser(appConfig.host, assessor.email, appConfig.source, appConfig.company, newValue, client.phone)
      .then(response => {
        setMedia(response);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleChangeTabValue = (event, newValue) => {
    setIsLoading(true);
    setTabValue(newValue);
    setMedia([]);
    getMediaUser(newValue);
  };

  useEffect(() => {
    getMediaUser(tabValue);
  }, []);

  return (
    <Box className={appConfig.darkTheme ? classes.tabBoxDark : ""}>
      <TabContext value={tabValue}>
        <Box>
          <TabList
            className={classes.tabList}
            variant="fullWidth"
            onChange={handleChangeTabValue}
            TabIndicatorProps={{ style: { backgroundColor: "#469d94", height: "7px" } }}
          >
            <Tab value="images" label={t("images")} />
            <Tab value="file" label={t("documents")} />
            <Tab value="audio" label={t("audio")} />
            <Tab value="link" label={t("links")} />
          </TabList>
        </Box>
        <Box className={classes.boxPanel}>
          <TabPanel value="images" className={classes.containerTab}>
            {isLoading ? (
              <Loader />
            ) : media?.length > 0 ? (
              <ImagesTab media={media} />
            ) : (
              <EmptyTab />
            )}
          </TabPanel>

          <TabPanel value="file" className={classes.containerTab}>
            {isLoading ? <Loader /> : media?.length > 0 ? <FileTab media={media} /> : <EmptyTab />}
          </TabPanel>

          <TabPanel value="audio" className={classes.containerTab}>
            {isLoading ? <Loader /> : media?.length > 0 ? <AudioTab media={media} /> : <EmptyTab />}
          </TabPanel>

          <TabPanel value="link" className={classes.containerTab}>
            {isLoading ? <Loader /> : media?.length > 0 ? <LinksTab media={media} /> : <EmptyTab />}
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  tabBoxDark: {
    backgroundColor: "#3e3e42"
  },
  tabList: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  boxPanel: {
    maxWidth: "100%",
    overflow: "hidden"
  },
  containerTab: {
    width: "100%",
    padding: 0
  }
}));

export default TabsAttachedFiles;
