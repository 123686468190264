import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, ListItem, Typography } from "@material-ui/core";
import Linkify from "react-linkify";
import Helper from "../../../utils/Helper";

const LinksTab = ({ media }) => {
  const classes = useStyles();
  const { parseMonth, isDateMonthLabelNeeded } = Helper;

  let dLastLinkDate = undefined;
  const showDateLabel = date_modified => {
    const dLinkDate = new Date(date_modified);
    if (isDateMonthLabelNeeded(dLinkDate, dLastLinkDate)) {
      dLastLinkDate = dLinkDate;
      return true;
    }
    return false;
  };

  return media?.map((link, index) => {
    return (
      <React.Fragment key={link.ubm_id ? link.ubm_id : index}>
        <ListItem key={link.ubm_id ? link.ubm_id : index} className={classes.tabItem}>
          {showDateLabel(link.date_modified) && (
            <Typography color="primary" variant="h6" className={classes.date}>
              {parseMonth(link.date_modified)}
            </Typography>
          )}

          <Typography className={classes.linkItem}>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  <br />
                  {decoratedText}
                </a>
              )}
            >
              {link.text}
            </Linkify>
          </Typography>
        </ListItem>
        <Divider variant="fullWidth" />
      </React.Fragment>
    );
  });
};

const useStyles = makeStyles(() => ({
  tabItem: {
    padding: "10px 40px",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  linkItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  },
  date: {
    marginBottom: "20px"
  }
}));

LinksTab.propTypes = {
  media: PropTypes.array
};

export default LinksTab;
