import React from "react";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

const CancelMicButton = ({ micRecording, setMicRecording, Mp3Recorder }) => {
  const { t } = useTranslation();

  const handleCancelRecording = () => {
    !micRecording.blobURL && Mp3Recorder.stop().getMp3();

    setMicRecording(micRecording => {
      return {
        ...micRecording,
        blobURL: undefined,
        isRecording: false
      };
    });
  };
  return (
    <Tooltip title={<Typography variant="body2">{t("cancelRecording")}</Typography>}>
      <span>
        <IconButton component="span" color="primary" onClick={handleCancelRecording}>
          <Cancel />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default CancelMicButton;

CancelMicButton.propTypes = {
  micRecording: PropTypes.shape({
    isRecording: PropTypes.bool,
    blobURL: PropTypes.string,
    blob: PropTypes.object,
    isBlocked: PropTypes.bool,
    setDefaultAudioSent: PropTypes.func
  }),
  setMicRecording: PropTypes.func,
  Mp3Recorder: PropTypes.object
};
