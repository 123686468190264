import React, { useContext } from "react";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import AppContext from "../../../AppContext";

import is_electron from "is-electron";

const LogoutButton = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setAppConfig } = useContext(AppContext);

  const handleLogout = () => {
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        loggedIn: false,
        darkTheme: false
      };
    });

    window.localStorage.removeItem("user");
  };

  return (
    <Tooltip
      title={<Typography variant="body2">{t("logout")}</Typography>}
      className={is_electron() ? "" : classes.invisible}
    >
      <IconButton color="secondary" component="span" onClick={handleLogout}>
        <ExitToApp />
      </IconButton>
    </Tooltip>
  );
};

export default LogoutButton;

const useStyles = makeStyles(() => ({
  invisible: {
    display: "none"
  }
}));
