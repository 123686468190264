import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import ChatIcon from "@material-ui/icons/Chat";
import { Fade } from "@material-ui/core";
import AppContext from "../../AppContext";
import ApiCalls from "../../utils/APIRequests";

const useStyles = makeStyles(() => ({
  botIcon: {
    position: "fixed !important",
    right: "35px",
    bottom: "35px",
    backgroundColor: "#145D54 !important",
    color: "#fff !important",
    boxShadow: "2px 2px 5px #0000007a",
    zIndex: "1000",
    height: "65px",
    width: "65px"
  },
  badgeColor: {
    backgroundColor: "#4fc3f7"
  },
  invisible: {
    display: "none"
  }
}));

const MinimizedChat = () => {
  const classes = useStyles();

  const { userList, appConfig, setAppConfig, delegates, assessor, setSkipSaveAppConfig } = useContext(AppContext);

  const handleMaximize = () => {
    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        minimized: false
      };
    });
  };

  const countPendingNotifications = () => {
    let nTotalUnread = 0;
    if (userList && userList.length > 0) {
      userList.forEach(user => {
        if (typeof user?.unread != 'undefined') {
          nTotalUnread += user.unread;
        }
      });
    }

    return nTotalUnread;
  };

  const countUnreadConversations = () => {
    let bUnreadConversations = false;
    if (userList && userList.length > 0) {
      userList.forEach(user => {
        if (typeof user?.unread != 'undefined' && user.unread) {
          bUnreadConversations = true;
        }
      });
    }

    return bUnreadConversations;
  };

  const countUnreadDelegates = () => {
    let nCount = 0;
    delegates.forEach(oDelegate => {
      if (oDelegate.unread > 0) {
        nCount = nCount + oDelegate.unread;
      }
    });

    return nCount;
  };

  return (
    <Fade className={classes.botIcon} in={appConfig.minimized}>
      <IconButton onClick={handleMaximize}>
        <Badge
          overlap="rectangular"
          badgeContent={countPendingNotifications() || countUnreadConversations()}
          color="error"
        >
          <Badge
            badgeContent={countUnreadDelegates()}
            overlap="rectangular"
            classes={{ badge: classes.badgeColor }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
            <ChatIcon fontSize="large" style={{ width: 35, height: 35 }} />
          </Badge>
        </Badge>
      </IconButton>
    </Fade>
  );
};

export default MinimizedChat;
