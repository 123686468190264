import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ApiCalls from "../../../utils/APIRequests";
import { useTranslation } from "react-i18next";

const AudioItem = ({ audio }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [audioURL, setAudioURL] = useState();

  const getAudioUrl = sUrlAttached => {
    ApiCalls.getAttachmentsAPI(sUrlAttached).then(response => {
      setAudioURL(response);
    });
  };

  useEffect(() => {
    getAudioUrl(audio.attach);
  }, [audio.attach]);

  return (
    <>
      <audio className={classes.audioItem} src={audioURL} controls="controls" />
      {audio.transcription && (
        <Typography color="primary" className={classes.date} variant="body2">
          {t("transcription")}: {audio.transcription}
        </Typography>
      )}
    </>
  );
};

AudioItem.propTypes = {
  audio: PropTypes.object
};

const useStyles = makeStyles(() => ({
  audioItem: {
    padding: "10px 20px",
    cursor: "pointer"
  },
  date: {
    marginLeft: "20px"
  }
}));

export default AudioItem;
