import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const AttachmentButton = ({ client, disabledButtonAttachment, setAttachmentDialogOpen }) => {
  const { t } = useTranslation();

  const openAttachmentDialog = () => {
    if (client.phone) {
      setAttachmentDialogOpen(true);
      setTimeout(() => {
        const paragraph = document.createElement("p");
        paragraph.textContent = t("attachment.dialog.subtitle");
        const dropzoneTitle = document.querySelector("h2");
        dropzoneTitle.appendChild(paragraph);
      }, 500);
    }
  };

  return (
    <Tooltip
      title={
        <Typography variant="body2">
          {disabledButtonAttachment ||
          !client.phone ||
          (!client.active && client.active !== undefined)
            ? t("attachment.icon.input.tooltip")
            : t("attach")}
        </Typography>
      }
    >
      <span>
        <IconButton
          disabled={
            disabledButtonAttachment ||
            !client.phone ||
            (!client.active && client.active !== undefined)
          }
          component="span"
          onClick={openAttachmentDialog}
        >
          <AttachFile />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default AttachmentButton;

AttachmentButton.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    compoundName: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.string,
    courseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    courseName: PropTypes.string,
    phone: PropTypes.string,
    displayname: PropTypes.string,
    group: PropTypes.bool,
    active: PropTypes.bool
  }),
  disabledButtonAttachment: PropTypes.bool,
  setAttachmentDialogOpen: PropTypes.func
};
