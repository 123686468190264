import React, { useContext } from "react";
import PropTypes from "prop-types";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";

const sToken = "bb326e6a60e51ea332f02208ee39a1ed5a0543269ae79";
const headers = {
  token: sToken
};

const DialogDocumentViewer = ({ open, setOpen, sUrlCompound }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig } = useContext(AppContext);
  const queryString = sUrlCompound?.substring(sUrlCompound.lastIndexOf("?"), sUrlCompound.length);
  const urlParams = new URLSearchParams(queryString);
  const urlFile = atob(urlParams.get("url"));
  const filename = urlFile.substring(urlFile.lastIndexOf("/") + 1, urlFile.length);

  const documents = [{ uri: sUrlCompound, fileName: filename }];

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    window.open(sUrlCompound);
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth={true}
      onClose={handleClose}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogTitle}>{t("preview")}</DialogTitle>

      <DialogContent
        className={appConfig.darkTheme ? classes.dialogContentDark : classes.dialogContent}
      >
        <DocViewer
          documents={documents}
          pluginRenderers={DocViewerRenderers}
          prefetchMethod="GET"
          requestHeaders={headers}
        />
      </DialogContent>
      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
      >
        <Button onClick={handleClose} color="primary">
          <Typography variant="caption" color="primary">
            {t("cancel")}
          </Typography>
        </Button>
        <Button onClick={handleDownload} className={classes.buttonCallToAction} color="primary">
          <Typography variant="caption" color="secondary">
            {t("download")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  dialogContent: {
    height: "100vh"
  },
  dialogContentDark: {
    height: "100vh",
    backgroundColor: "#3e3e42",
    color: "#fff"
  },
  dialogActions: {
    borderTop: "1px solid #bdbdbd",
    padding: "0.8rem 0"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959",
    color: "#fff",
    padding: "0.8rem 0"
  },
  buttonCallToAction: {
    backgroundColor: "#065d54",
    padding: "0.5rem 0.8rem",
    marginRight: "1.5rem",
    "&:hover": {
      backgroundColor: "#207060",
      opacity: [0.9, 0.8, 0.7]
    }
  }
}));

DialogDocumentViewer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  sUrlCompound: PropTypes.string
};

export default React.memo(DialogDocumentViewer);
