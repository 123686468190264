import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { Snackbar, Button, IconButton } from "@material-ui/core";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  snackbar: {
    zIndex: 2000,
    margin: "1rem",
    marginTop: "3.5rem",
    width: "30%",
    minWidth: 300,
    maxWidth: 450,
    boxShadow: "0 0 10px 3px rgba(0,0,0,.5)",
    overflow: "hidden",
    boxSizing: "border-box"
  },
  alert: {
    color: "#fff",
    backgroundColor: "#065D54",
    width: "100%",
    lineBreak: "anywhere",
    boxSizing: "border-box",
    "& .MuiAlert-icon": {
      display: "flex",
      flexGrow: 1
    },
    "& .MuiAlert-message": {
      display: "flex",
      flexGrow: 3,
      flexDirection: "row",
      flexWrap: "wrap",
      width: "calc(75% - 16px)",
      maxHeight: 140,
      lineClamp: 3,
      textWrap: "wrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "& .MuiAlertTitle-root": {
        textWrap: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }
    },
    "& .MuiAlert-action": {
      display: "flex",
      flexGrow: 2,
      boxSizing: "border-box"
    },
    
  },
  actions: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignContent: "space-between",
    boxSizing: "border-box",
    "& button": {
      display: "flex",
      alignSelf: "flex-end"
    }
  }
}));

const AlertTitleNotification = ({ title, message, openNotification, setOpenNotification, clientPhone }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenNotification(false);
  };

  const handleClick = () => {
    window.selectConversation(clientPhone, false, true);
  }

  const __action = (
    <React.Fragment>
      <div  className={classes.actions}>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Button color="secondary" variant="outlined" size="small" onClick={handleClick}>
          Ir
        </Button>
      </div>
    </React.Fragment>
  );

  const __message = (
    <Alert
        icon={<NotificationsActiveIcon style={{ color: "#fff" }} />}
        className={classes.alert}
        action={__action}
      >
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
  );

  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={openNotification}
      autoHideDuration={6000}
      onClose={handleClose}
    >
        {__message}
    </Snackbar>
  );
};

AlertTitleNotification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  openNotification: PropTypes.bool,
  setOpenNotification: PropTypes.func,
  clientPhone: PropTypes.string
};

export default AlertTitleNotification;
