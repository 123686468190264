import { useContext, useEffect, useState } from "react";
import PahoMQTT from "paho-mqtt";
import { v4 as uuidv4, v5 as uuidv5 } from "uuid";
import ApiCalls from "../utils/APIRequests";
import AppContext from "../AppContext";
import jQuery, { type } from "jquery";
import Helper from "../utils/Helper";
import packageInfo from "../../package.json";

//TODO: Only throw console logs if developer mode is enabled.

const ConnectionStatusEnum = {
	// notstarted: the component is not started yet
	NotStarted: "notStarted",
	// connecting: the component is connecting
	Connecting: "connecting",
	// success: the websocket connection is established and working
	Success: "success",
	// lost: the websocket connection was lost, the code is trying to re-connect again. If successfully, this will become success, if not, failure.
	Lost: "lost",
	// failure: something was failing, so we are going to move the component to polling strategy (intervals)
	Failure: "failure",
	// retry: the websocket connection failed one time, the code is trying to re-connect again opnce. If successfully, this will become success, if not, failure.
	Retry: "retry",
	// removed: the websocket was removed/disconnected
	Removed: "removed"
};

const useMQTT = ({ onMessageReceived, refreshUsers, refreshMessages }) => {
	const {
		assessor,
		appConfig,
		setAppConfig,
		client,
		updateComponent,
		initNewMQTTWS,
		setInitNewMQTTWS,
		MQTTConnectionsList,
		setMQTTConnectionsList,
		setNeedUpdateUserLayout,
		setOnline
	} = useContext(AppContext);
	const [connectionStatus, setConnectionStatus] = useState(ConnectionStatusEnum.NotStarted);
	// const [connectionRetries, setConnectionRetries] = useState(0);
	let WSClient;
	let tabID = null;
	let connectionLost = null;
	const verbose = appConfig.verbose;

	useEffect(() => {
		if (appConfig.usews === false) handleRemoveWebsocket();
		else handleInitWebsocket();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

/* 	useEffect(() => {
		// checkSocketConnection();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionStatus]);
 */
	useEffect(() => {
		if (verbose) {
			console.log(`%cMQTT: initNewMQTTWS: ${initNewMQTTWS}`, `color: yellow;`)
		}
		if (initNewMQTTWS == true) {
			handleInitWebsocket();
		}
		setInitNewMQTTWS(false);
	}, [initNewMQTTWS]);

	// Iniciar conexiones pendientes
	useEffect(() => {
		MQTTConnectionsList.to_start.map(conn => {
			if (conn.retries <= Helper.constants.websocketRetries) {
				initWebSocket(conn.mqtt_data);
			} else {
				let aux = MQTTConnectionsList;
				aux.to_start = MQTTConnectionsList.to_start.filter(elem => elem.routingKey != conn.routingKey);
				setMQTTConnectionsList(aux);
			}
		});
	}, [MQTTConnectionsList.to_start])

	// Iniciar conexiones fallidas
	useEffect(() => {
		MQTTConnectionsList.failed.map(conn => {
			let aux = MQTTConnectionsList;
			aux.to_start = MQTTConnectionsList.to_start.filter(elem => elem.routingKey != conn.routingKey);
			aux.failed = MQTTConnectionsList.failed.filter(elem => elem.routingKey != conn.routingKey);
			if (conn.retries < Helper.constants.websocketRetries) {
				aux.to_start.push(conn);
			} else {
				console.log('useEffect: MQTTConnectionsList.failed.map: ', conn);
				handleDisconnection(conn);
			}
			setMQTTConnectionsList(aux);
		});
	}, [MQTTConnectionsList.failed]);

	// Iniciar conexiones perdidas
	useEffect(() => {
		MQTTConnectionsList.lost.map(conn => {
			let aux = MQTTConnectionsList;
			aux.to_start = MQTTConnectionsList.to_start.filter(elem => elem.routingKey != conn.routingKey)
			let _retries = typeof conn.retries != 'undefined' && !isNaN(conn.retries) ? conn.retries + 1 : 0;
			if (conn.retries < Helper.constants.websocketRetries) {
				aux.to_start.push({ ...conn, retries: _retries })
			} else {
				handleDisconnection(conn);
			}
			aux.lost = MQTTConnectionsList.lost.filter(elem => elem.routingKey != conn.routingKey);
			setMQTTConnectionsList(aux);
		});
	}, [MQTTConnectionsList.lost]);

	// Terminar conexiones
	useEffect(() => {
		MQTTConnectionsList.to_disconnect.map(conn => {
			let aux_conn = MQTTConnectionsList.connected.find(elem => elem.email == conn.email);
			cleanOpenedMQTTConnections(aux_conn);
		});
	}, [MQTTConnectionsList.to_disconnect]);

	const cleanOpenedMQTTConnections = (conn) => {
		try {
			conn.mqtt_client.disconnect();
			let aux = MQTTConnectionsList;
			aux.connected = MQTTConnectionsList.connected.filter(elem => elem.email != conn.email);
			aux.to_disconnect = MQTTConnectionsList.to_disconnect.filter(elem => elem.email != conn.email);
			setMQTTConnectionsList(aux);
			if (verbose) {
				console.log(`%cMQTT: ${conn.email} disconnected`, `color: yellow;`);
			}
		} catch (e) {
			if (verbose) {
				console.log(e)
			}
			return false;
		}
	}

	const getWebsocketParameters = async () => {
		let mqttData;

		if (verbose) {
			console.log(`%cMQTT: Websocket: Request MQTT parameters.`, `color: yellow;`);
		}
		//clientId is built as source:email:origin:tabId where:
		// Source
		// Assessor e-mail
		// Origin: Web
		// TabID: Web Browser Tab Unique uuid

		//Para generar este UUID la primera vez: console.log(uuidv4())
		const MY_NAMESPACE = '79091e8a-c11c-49b3-a757-68b215742aa8';

		const time = Date.now();

		let myClientID = tabID
			? uuidv5(`${appConfig.source}:${assessor.email}:web:${tabID}${time}`, MY_NAMESPACE)
			: uuidv4();

		if (appConfig.currentTabId == "" || appConfig.currentTabId != myClientID) {
			appConfig.currentTabId = myClientID;
			setAppConfig((oAppConfig) => { return { ...oAppConfig, currentTabId: myClientID } });
		}

		// Nos aseguramos de que uuidv4 no nos devuelve un asterisco, que rompería las colas de rabbit
		myClientID = myClientID === "*" ? uuidv4() : myClientID;
		if (verbose) {
			console.log(`%cMQTT: Websocket: Client ID: ${myClientID}`, `color: yellow;`)
		}
		const mqttProps = {
			assesor_email: assessor.email,
			source: appConfig.source,
			company: appConfig.company,
			clientId: myClientID,
			host: appConfig.host
		};

		mqttData = await ApiCalls.getMqttData(mqttProps); // add
		mqttData = await mqttData.json();
		mqttData.assesor_email = assessor.email;

		return mqttData;
	};

	const getWebsocketOptions = mqttDataResponse => {
		const options = {
			timeout: 5,
			keepAliveInterval: mqttDataResponse.keepalive ?? 20,
			userName: `${mqttDataResponse.vhost}:${mqttDataResponse.username}`,
			password: mqttDataResponse.password,
			useSSL: true,
			reconnect: true
		};
		// console.log("Websocket options: ", options);
		return options;
	};

	const checkSocketConnection = () => {
		/* if (verbose) {
			console.log(`%cWebsocket: Connection Status: ${connectionStatus} - Retries: ${connectionRetries}`, `color: yellow;`);
		} */
		// If its failed first time, then we are going to retry one time more.
		/* if (
			connectionStatus === ConnectionStatusEnum.Failure &&
			connectionRetries < Helper.constants.websocketRetries
		) {
			handleConnectionRetry();
			handleResetWebsocket();
		}
		if (connectionStatus === ConnectionStatusEnum.Lost) {
			handleResetWebsocket();
		} */
	};

	const getConnByRoutingKey = (routingKey) => {
		let conn = null;
		for (const [key, items] of Object.entries(MQTTConnectionsList)) {
			conn = items.find(elem => elem.routingKey == routingKey);
			if (conn != null && typeof conn != 'undefined') {
				return {
					connection: conn,
					list: key
				};
			}
		}
		return null;
	}

	const initWebSocket = (mqttData) => {
		if (verbose) {
			console.log(`%cMQTT: Levantando conexión para ${mqttData.routingKey}`, `color: yellow;`)
		}
		let aux = MQTTConnectionsList;
		let found = getConnByRoutingKey(mqttData.routingKey);
		let __retries = 0;
		if (found != null) {
			__retries = typeof found.connection.mqtt_data != 'undefined' && typeof found.connection.mqtt_data.retries != 'undefined' && !isNaN(found.connection.mqtt_data.retries) ? found.connection.mqtt_data.retries + 1 : 0;
			if (__retries >= Helper.constants.websocketRetries) {
				if (verbose) {
					console.log(`%cMQTT: cancelada conexión para ${mqttData.routingKey} con ${__retries} reintentos`, `color: yellow;`)
				}
				handleDisconnection(found.connection);
				return false;
			}
		}
		mqttData.retries = __retries;
		aux.connected = MQTTConnectionsList.connected.filter(elem => elem.routingKey != mqttData.routingKey)
		aux.to_start = MQTTConnectionsList.to_start.filter(elem => elem.routingKey != mqttData.routingKey)
		aux.failed = MQTTConnectionsList.failed.filter(elem => elem.routingKey != mqttData.routingKey)
		aux.lost = MQTTConnectionsList.lost.filter(elem => elem.routingKey != mqttData.routingKey)
		setMQTTConnectionsList(aux);

		WSClient = new PahoMQTT.Client(
			mqttData.host,
			mqttData.port,
			"/ws",
			`${mqttData.queueName}.`
		);

		try{
			WSClient.disconnect()
		} catch (e) {
			if (verbose) {
				console.log(`%cConnection already disconnected`, `color: yellow;`)
			}
		}
		
		// Connect and subscribe the events to our handlers
		WSClient.connect({
			...getWebsocketOptions(mqttData),
			onSuccess: () => handleConnectionSuccess(mqttData, WSClient),
			onFailure: (oObject) => handleConnectionFailure(mqttData, WSClient, oObject),
		});
		WSClient.onMessageArrived = oObject => handleMessageArrived(oObject);
		WSClient.onConnectionLost = (oObject) => { handleConnectionLost(mqttData, WSClient, oObject); };
	}

	const handleInitWebsocket = () => {
		if (MQTTConnectionsList.connected.filter(elem => elem.email == assessor.email).length > 0 || MQTTConnectionsList.to_start.filter(elem => elem.email == assessor.email).length > 0) {
			if (verbose) {
				console.log(`%cMQTT: No abrir WS`, `color: yellow;`)
			}
			return false;
		}

		let aux = MQTTConnectionsList;
		aux.to_start = MQTTConnectionsList.to_start.filter(elem => elem.email != assessor.email)

		// Cuando abrimos una conexión, nos aseguramos de cerrar las que no necesitamos
		aux.connected.map(conn => {
			if (conn.email != assessor.email && conn.email != appConfig.myUser.email) {
				cleanOpenedMQTTConnections(conn)
			}
		});

		setMQTTConnectionsList(aux);

		setConnectionStatus(ConnectionStatusEnum.Connecting);

		tabID =
			sessionStorage && sessionStorage.tabID && sessionStorage.closedLastTab !== "2"
				? sessionStorage.tabID
				: (sessionStorage.tabID = uuidv4());

		if (verbose) {
			console.log(`%cMQTT: Websocket: Web Browser Tab UID: ${tabID}`, `color: yellow;`);
		}

		//Closed Last Tab is used to avoid duplicates when the user actually duplicates the browser tab
		sessionStorage.closedLastTab = "2";

		addEventListener("unload", () => {
			sessionStorage.closedLastTab = "1";
		});

		addEventListener("beforeunload", () => {
			sessionStorage.closedLastTab = "1";
		});

		// Creamos un intervalo cada 10 segungdos para obtener los datos de conexión por WS.
		let __retries = 0;
		const WSParamsInterval = setInterval(() => {
			// Logica si falla
			getWebsocketParameters()
				.then(mqttDataResponse => {
					if (mqttDataResponse) {
						clearInterval(WSParamsInterval);
						initWebSocket(mqttDataResponse);
					}
				})
				.catch(e => {
					__retries++;
					if (__retries < 5) {
						if (verbose) {
							console.log(`%cError en la solicitud de getWebsocketParameters. Intento ${__retries}.`, 'color:yellow', e);
						}
					} else {
						clearInterval(WSParamsInterval);
						if (verbose) {
							console.log(`%cError en la solicitud de getWebsocketParameters. Intento ${__retries}. %cCancelando la conexión por WS.`, 'color:yellow', 'color:red', e);
							// Si hay 5 intentos fallidos de conexión con el servidor, reintentar la conexión WS dentro de 5 min.
							setTimeout(handleInitWebsocket, (5 * 60 * 1000));
						}
					}
				});
		}, 10000);
	};

	const handleRemoveWebsocket = () => {
		if (WSClient && !jQuery.isEmptyObject(WSClient)) {
			try {
				WSClient.disconnect();
			} catch (error) {
				if (verbose) {
					console.log(error);
				}
			}
			WSClient = undefined;
		}
		setConnectionStatus(ConnectionStatusEnum.Removed);
	};

	const handleResetWebsocket = () => {
		setTimeout(() => {
			// When the websocket is lost, refresh the users and messages if exist an open chat
			refreshUsers();
			if (client?.phone) {
				refreshMessages(false, () => { });
			}
			handleInitWebsocket();
		}, 1000);
	};

	const handleConnectionSuccess = (mqttDataResponse, oWSClient) => {
		let aux = MQTTConnectionsList;
		aux.to_start = MQTTConnectionsList.to_start.filter(elem => elem.email != assessor.email)
		aux.connected = [
			...MQTTConnectionsList.connected.filter(elem => elem.email != assessor.email),
			{ email: assessor.email, mqtt_client: oWSClient, routingKey: mqttDataResponse.routingKey, mqtt_data: mqttDataResponse, retries: mqttDataResponse.retries, details: '' }
		];
		aux.failed = MQTTConnectionsList.failed.filter(elem => elem.email != assessor.email);
		aux.lost = MQTTConnectionsList.lost.filter(elem => elem.email != assessor.email);
		setMQTTConnectionsList(aux);

		oWSClient.subscribe(mqttDataResponse.routingKey, mqttDataResponse.subscribe_options);
		if (connectionLost === true) {
			setNeedUpdateUserLayout(true);
			connectionLost = false;
			/* setAppConfig(oAppConfig => {
				return {
					...oAppConfig,
					usews: true,
				};
			}); */
		}

		setOnline(true);
		setConnectionStatus(ConnectionStatusEnum.Success);
		if (verbose) {
			console.log(`%cMQTT: ${mqttDataResponse.routingKey} success`, `color: yellow;`);
			console.log('%cCurrent active connections: ', 'color: yellow', MQTTConnectionsList.connected);
		}
	};

	const handleConnectionLost = (mqttData, oWSClient, oObject) => {
		/* let aux = MQTTConnectionsList;
		aux.to_start = MQTTConnectionsList.to_start.filter(elem => elem.email != mqttData.routingKey)
		aux.connected = MQTTConnectionsList.connected.filter(elem => elem.email != mqttData.routingKey);
		aux.failed = MQTTConnectionsList.failed.filter(elem => elem.email != mqttData.routingKey);
		aux.lost = [
			...MQTTConnectionsList.lost.filter(elem => elem.email != mqttData.routingKey),
			{ email: mqttData.routingKey, mqtt_client: oWSClient, routingKey: mqttData.routingKey, mqtt_data: mqttData, retries: mqttData.retries, details: `connection lost: - error code: ${oObject.errorCode}:  - error message: ${oObject.errorMessage}` }
		];
		setMQTTConnectionsList(aux); */
		localStorage.removeItem("userKeyGetMessages");
		localStorage.removeItem("userPhoneGetMessages");
		localStorage.removeItem("userCourseGetMessages");
		setConnectionStatus(ConnectionStatusEnum.Lost);
		connectionLost = true;
		setOnline(false);
/* 		setAppConfig(oAppConfig => {
			return {
				...oAppConfig,
				usews: false
			};
		}); */
		if (verbose) {
			console.log(`%cMQTT: ${mqttData.routingKey} Lost`, `color: yellow;`)
		}
	};

	const handleConnectionFailure = (mqttDataResponse, oWSClient, oObject) => {
		let aux = MQTTConnectionsList;
		aux.to_start = MQTTConnectionsList.to_start.filter(elem => elem.email != assessor.email);
		aux.to_start = aux.to_start.filter(elem => elem.routingKey != mqttDataResponse.routingKey);
		aux.connected = MQTTConnectionsList.connected.filter(elem => elem.email != assessor.email);
		aux.failed = [
			...MQTTConnectionsList.failed.filter(elem => elem.email != assessor.email),
			{ email: assessor.email, mqtt_client: oWSClient, routingKey: mqttDataResponse.routingKey, mqtt_data: mqttDataResponse, retries: mqttDataResponse.retries, details: `connection failed - error code: ${oObject.errorCode} - error message: ${oObject.errorMessage}` }
		];
		aux.lost = MQTTConnectionsList.lost.filter(elem => elem.email != assessor.email);
		setMQTTConnectionsList(aux);
		setConnectionStatus(ConnectionStatusEnum.Failure);
		connectionLost = true;
		setOnline(false);
/* 		setAppConfig(oAppConfig => {
			return {
				...oAppConfig,
				usews: false
			};
		}); */
		if (verbose) {
			console.log(`%cMQTT: ${mqttDataResponse.routingKey} failed`, `color: yellow;`)
		}
	};

	const handleConnectionRetry = () => {
		setConnectionStatus(ConnectionStatusEnum.Retry);
		setConnectionRetries(connectionRetries + 1);
	};

	const handleMessageArrived = (oObject) => {
		if (connectionStatus !== ConnectionStatusEnum.Removed) {
			if (verbose) {
				console.log(`%cMQTT: Websocket: Object arrived`, `color: yellow;`);
				console.log(oObject);
			}
			try {
				const oParsedObject = JSON.parse(oObject.payloadString);
				if (verbose) {
					console.log(`%cMQTT: Websocket: JSON parsed object`, `color: yellow;`);
					console.log(oParsedObject);
				}
				onMessageReceived?.(oParsedObject);

				if (typeof oParsedObject.forceReload !== 'undefined') {
					if (verbose) {
						console.log(`%cMQTT: Websocket: forceReload`, `color: yellow;`)
					}
					ApiCalls.getLastWebComponentVersion(appConfig.host)
						.then(response => response.json())
						.then(response => {
							const componentVersionInServer = response.version;
							if (componentVersionInServer != packageInfo.version) {
								if (verbose) {
									console.log(`%cMQTT: Api version: ${componentVersionInServer} Front version: ${packageInfo.version}`, `color: yellow;`)
								}
								let reloadInterval = setInterval(() => {
									if (updateComponent.isSendingMessage == false && updateComponent.isRecordingAudio == false) {
										clearInterval(reloadInterval);
										window.location.reload();
									} else if (updateComponent.isSendingMessage) {
										if (verbose) {
											console.log(`%cMQTT: Waiting for user stop typing`, `color: yellow;`)
										}
									} else if (updateComponent.isRecordingAudio) {
										if (verbose) {
											console.log(`%cMQTT: Waiting for user stop recording message`, `color: yellow;`)
										}
									}
								}, 1000);
							}
						})
						.catch(e => {
							if (verbose) {
								console.error(e);
							}
						})
				}
			} catch (e) {
				if (verbose) {
					console.error(e);
				}
			}
		}
	};

	const handleDisconnection = (conn) => {
		const params = {
			assesor_email: conn.mqtt_data.assesor_email,
			source: appConfig.source,
			company: appConfig.company,
			clientId: conn.mqtt_data.clientId,
			host: appConfig.host,
			details: typeof conn.details != 'undefined' && conn.details.length > 0 ? conn.details : ''
		}
		ApiCalls.disconnectedMQTT(params)
			.then(res => {
				if (verbose) {
					console.log('%c/v3/ws/disconnected called!', 'color: yellow');
				}
			})
			.catch(err => {
				if (verbose) {
					console.log('%c/v3/ws/disconnected call failed!', 'color: yellow');
				}
			})
	}
};

export default useMQTT;
