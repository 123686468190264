import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
import styles from "../Chat/Customisation.module.css";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import packageInfo from "../../../package.json";
import ApiCalls from "../../utils/APIRequests";

let version = packageInfo.version;

const NewTagDialog = ({ openNewTagDialog, setOpenNewTagDialog }) => {
  const classes = useStyles();
  const { appConfig, setAppConfig, assessor, setSkipSaveAppConfig } = useContext(AppContext);
  const { t } = useTranslation();

  const [tagValue, setTagValue] = useState("");

  const handleClose = () => {
    setTagValue("");
    setOpenNewTagDialog(false);
  };

  const handleSaveNewTag = () => {
    let oNewTag = {
      key: uuidv4(),
      tag: tagValue
    };

    let oAppConfig = appConfig;
    if (typeof oAppConfig.userTags == 'undefined') {
      oAppConfig.userTags = [];
    }
    oAppConfig.userTags.push(oNewTag)

    setSkipSaveAppConfig(true);
    setAppConfig(oAppConfig);
    ApiCalls.saveUserSettings(assessor.email, oAppConfig);

    handleClose();
  };

  return (
    <Dialog
      open={openNewTagDialog}
      maxWidth="sm"
      id={appConfig.darkTheme ? styles["proeduca-messenger-dark"] : styles["proeduca-messenger"]}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        {t("addNewTagAction")}
      </DialogTitle>

      <DialogContent className={appConfig.darkTheme ? classes.dialogDark : classes.dialog}>
        <InputBase
          className={classes.customInput}
          value={tagValue}
          onChange={event => setTagValue(event.target.value)}
          placeholder={t("writeTag")}
          inputProps={
            appConfig.darkTheme
              ? {
                  className: classes.inputDark
                }
              : { className: classes.input }
          }
          autoFocus
        />
      </DialogContent>

      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
      >
        <Button onClick={handleClose} className={classes.dialogButton} color="primary">
          <Typography variant="body1" color="primary">
            {t("cancel")}
          </Typography>
        </Button>
        <Button
          onClick={handleSaveNewTag}
          className={classes.dialogButtonPrimaryAction}
          color="primary"
        >
          <Typography variant="body1" color="secondary">
            {t("save")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewTagDialog;

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff",
    minWidth: "500px"
  },
  dialog: {
    padding: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  dialogDark: {
    backgroundColor: "#3e3e42",
    color: "#fff",
    padding: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  dialogActions: {
    borderTop: "1px solid #bdbdbd",
    padding: "0.8rem 0"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959",
    padding: "0.8rem 0"
  },
  dialogButton: {
    padding: "0.5rem 0.8rem",
    marginRight: "1.5rem"
  },
  dialogButtonPrimaryAction: {
    backgroundColor: "#065d54",
    padding: "0.5rem 0.8rem",
    marginRight: "1.5rem",
    "&:hover": {
      backgroundColor: "#207060",
      opacity: [0.9, 0.8, 0.7]
    }
  },
  customInput: {
    border: "1px solid #f9f9f9",
    borderRadius: 5,
    minWidth: 500,
    marginBottom: 15,
    fontSize: 20,
    paddingLeft: 15
  }
}));

NewTagDialog.propTypes = {
  openNewTagDialog: PropTypes.bool,
  setOpenNewTagDialog: PropTypes.func
};
