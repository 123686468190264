import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const SWPath = 'https://wa.unir.net/firebase-messaging-sw.js';
const SWvapidKey = "BLpHCF0KeXa6v_cH4dChaE4nW9xhr3VbmoYoJgZvUoBYkEVFxtySNlAVO4049sDm6y_x_XQPHuSqiNXSEtxVqkE";
const SWScope = 'firebase-cloud-messaging-push-scope'

const firebaseConfig = {
	apiKey: "AIzaSyC_GNDvsrbKacIOW2aejIA-peL67m0ZECc",
	authDomain: "chat-app-proeduca.firebaseapp.com",
	projectId: "chat-app-proeduca",
	storageBucket: "chat-app-proeduca.appspot.com",
	messagingSenderId: "1019683924942",
	appId: "1:1019683924942:web:3e7bff252150077e3e999f"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const getSW = async () => {
	if ("serviceWorker" in navigator && typeof window.navigator.serviceWorker !== "undefined") {
		try { 
			const serviceWorker = await window.navigator.serviceWorker.getRegistration(SWScope);
			if (serviceWorker) return serviceWorker;
		} catch (err) {
			console.error('Se ha producido un error al registrar el SW: ', err)
		}
	}
	return navigator.serviceWorker.register(SWPath, {scope: SWScope});
}

export const fetchToken = async () => {
	try {
		if (messaging) {
			const SW = await getSW();
			const permission = await Notification.requestPermission();
			const GET_TOKEN_PARAMS =  { 
				vapidKey: SWvapidKey,
				serviceWorkerRegistration: SW
			};
			return getToken(messaging, GET_TOKEN_PARAMS)
		}
	} catch (error) {
		console.log("An error occurred while retrieving token. ", error);
	}
	return false;
};

export const onMessageListener = () => {
	return new Promise(resolve => {
		onMessage(messaging, payload => {
			if (typeof payload.data != 'undefined' && typeof payload.data.assesor_email != 'undefined') {
				if (window.checkAsessorEmail(payload.data.assesor_email)) {
					resolve(payload);
				}
			} else {
				resolve(payload);
			}
		});
	});
}