import { Avatar, Dialog, IconButton, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, createLocalTracks } from "twilio-video";
import AppContext from "../../AppContext";
import ApiCalls from "../../utils/APIRequests";
import { CallEnd, Mic, MicOff, Videocam, VideocamOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PopoverOptionsConfiguration from "./PopoverOptionsConfiguration";
import DialogConfiguration from "./DialogConfiguration";

const TwilioVideo = ({ errorConnectRoom, setOpenDialogPermissionDenied }) => {
  const { twilioVideo, setTwilioVideo, client, assessor, appConfig } = useContext(AppContext);
  const { t } = useTranslation();
  const videoRef = useRef();
  const audioRef = useRef();
  const classes = useStyles();
  const [room, setRoom] = useState({});
  const videoParticipantRef = useRef();
  const audioParticipantRef = useRef();
  const [videoParticipant, setVideoParticipant] = useState(false);
  const [disableVideo, setDisableVideo] = useState(false);
  const [disableAudio, setDisableAudio] = useState(false);
  const [iconMicParticipantVisible, setIconMicParticipantVisible] = useState(false);
  const [avatarVideoParticipantVisible, setAvatarVideoParticipantVisible] = useState(false);

  // Popover Options Configuration
  const [anchorEl, setAnchorEl] = useState(null);

  // Dialog Configuration
  const [openDialogConfiguration, setOpenDialogConfiguration] = useState(false);

  useEffect(() => {
    // Solo cuando abrimos el modal
    if (twilioVideo.openModal) {
      if (twilioVideo.audioOnly) {
        // Creacion de local tracks para solo audio
        createLocalTracks({
          audio: true,
          video: false
        })
          .then(stream => {
            // Añadimos los local tracks en la sala que vamos a crear
            addRoom(stream);
          })
          .catch(() => {
            setTwilioVideo(twilioVideo => {
              return {
                ...twilioVideo,
                openModal: false
              };
            });
            setOpenDialogPermissionDenied(true);
          });
      } else {
        // Creacion de local tracks para audio y video
        createLocalTracks({
          audio: true,
          video: { height: 720, frameRate: 24, width: 1280 }
        })
          .then(stream => {
            // Añadimos los local tracks en la sala que vamos a crear
            addRoom(stream);
          })
          .catch(() => {
            setTwilioVideo(twilioVideo => {
              return {
                ...twilioVideo,
                openModal: false
              };
            });
            setOpenDialogPermissionDenied(true);
          });
      }
    }
  }, [twilioVideo.openModal]);

  const addRoom = stream => {
    const oRoomVideoCallData = {
      phone: client.phone,
      course_id: client.courseId,
      client_name: client.name,
      client_lastname: client.lastName,
      client_email: client.email,
      assesor_name: assessor.name,
      assesor_lastname: assessor.lastName,
      assesor_email: assessor.email,
      country: client.country,
      language: appConfig.language,
      source: appConfig.source,
      company: appConfig.company,
      developer: appConfig.developer,
      audioOnly: twilioVideo.audioOnly,
      host: appConfig.host
    };

    ApiCalls.addRoomVideoCall(oRoomVideoCallData)
      .then(response => {
        setTwilioVideo(twilioVideo => {
          return {
            ...twilioVideo,
            url: response.assesor_url,
            assesorEmail: response.assesor_data_email,
            clientEmail: response.client_email,
            roomName: response.room.room_name,
            uniq: response.uniq_assesor
          };
        });
        ApiCalls.getAccessTokenTwilioVideo(
          appConfig.host,
          response.assesor_data_email,
          response.room.room_name,
          response.uniq_assesor,
          appConfig.source,
          appConfig.company,
        )
          .then(token => {
            setTwilioVideo(twilioVideo => {
              return {
                ...twilioVideo,
                roomToken: token
              };
            });
            connectRoom(token, stream);
          })
          .catch();
      })
      .catch();
  };

  const connectRoom = (token, tracks) => {
    connect(`${token}`, {
      name: twilioVideo.roomName,
      tracks: tracks
    }).then(
      room => {
        setRoom(room);
        const localParticipant = room.localParticipant;

        setTwilioVideo(twilioVideo => {
          return {
            ...twilioVideo,
            isConnected: true
          };
        });

        // Asignamos el audio y el video a la refirencia de la etiqueta HTML
        localParticipant.tracks.forEach(publication => {
          const track = publication.track;
          track.kind === "video" && !twilioVideo.audioOnly && track.attach(videoRef.current);
          track.kind === "audio" && track.attach(audioRef.current);
          track.enable();
        });

        // Detectamos cuando se conecta un participante
        room.on("participantConnected", participant => {
          // Recorremos sus dispositivos que va a usar para la llamada y nos suscribimos + añadimos al html
          participant.tracks.forEach(publication => {
            publication.on("subscribed", track => {
              setVideoParticipant(true);
              track.kind === "video" &&
                !twilioVideo.audioOnly &&
                track.attach(videoParticipantRef.current);
              track.kind === "audio" && track.attach(audioParticipantRef.current);
            });

            participant.on("trackSubscribed", track => {
              // Nos subscribimos a los dispositivos del participante para saber cuando los pone disabled o enabled
              track.on("disabled", () => {
                // do something with the UI here
                track.kind === "audio" && setIconMicParticipantVisible(true);
                track.kind === "video" && setAvatarVideoParticipantVisible(true);
              });
              track.on("enabled", () => {
                // do something with the UI here
                track.kind === "audio" && setIconMicParticipantVisible(false);
                track.kind === "video" && setAvatarVideoParticipantVisible(false);
              });
            });
          });
        });

        // Si el participante remoto ya se encuentra en la sala, nos subscribimos a sus dispositivos de audio y video
        room.participants.forEach(participant => {
          participant.tracks.forEach(publication => {
            publication.on("subscribed", track => {
              track.kind === "video" && track.attach(videoParticipantRef.current);
              track.kind === "audio" && track.attach(audioParticipantRef.current);
              track.kind === "audio" && !track.isEnabled && setIconMicParticipantVisible(true);
              track.kind === "video" && !track.isEnabled && setAvatarVideoParticipantVisible(true);
            });
          });

          participant.on("trackSubscribed", track => {
            // Nos subscribimos a los dispositivos del participante para saber cuando los pone disabled o enabled
            track.on("disabled", () => {
              // do something with the UI here
              track.kind === "audio" && setIconMicParticipantVisible(true);
              track.kind === "video" && setAvatarVideoParticipantVisible(true);
            });
            track.on("enabled", () => {
              // do something with the UI here
              track.kind === "audio" && setIconMicParticipantVisible(false);
              track.kind === "video" && setAvatarVideoParticipantVisible(false);
            });
          });
        });

        // Cuando un participante se desconecta de la llamada, borramos to-do lo referente a él
        room.on("participantDisconnected", participant => {
          console.log(participant);
          setVideoParticipant(false);
          setAvatarVideoParticipantVisible(false);
          setIconMicParticipantVisible(false);
          videoParticipantRef.current = null;
        });
      },
      error => {
        errorConnectRoom();
        console.error(`Unable to connect to Room: ${error.message}`);
      }
    );
  };

  // Activamos o desactivamos nuestro video local participant
  const enableDisableVideo = () => {
    if (!disableVideo) {
      setDisableVideo(true);
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.disable();
      });
    } else {
      setDisableVideo(false);
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.enable();
      });
    }
  };

  // Activamos o desactivamos nuestro audio local participant
  const enableDisableAudio = () => {
    if (!disableAudio) {
      setDisableAudio(true);
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });
    } else {
      setDisableAudio(false);
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.enable();
      });
    }
  };

  // Cuando nos desconectamos de la sala, paramos nuestros dispositivos suscritos a la llamada
  const disconnectRoom = async () => {
    if (Object.keys(room).length !== 0) {
      await room.localParticipant.tracks.forEach(publication => {
        publication.track.stop();
        publication.unpublish();
        publication.track.detach();
      });
      await room.disconnect();
    }
    handleClose();
  };

  // Cerramos el modal y reiniciamos todas las variables referentes al local participant + reiniciamos el twilio video del context
  const handleClose = () => {
    setVideoParticipant(false);
    setDisableAudio(false);
    setDisableVideo(false);
    videoParticipantRef.current = null;
    audioParticipantRef.current = null;
    setTwilioVideo(twilioVideo => {
      return {
        ...twilioVideo,
        openModal: false,
        url: "",
        roomName: "",
        roomToken: "",
        audioOnly: false,
        assesorEmail: "",
        clientEmail: "",
        uniq: "",
        labelAudio: "",
        isConnected: false
      };
    });
  };

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={twilioVideo.openModal}
      maxWidth="md"
    >
      <div className={classes.participantsContainer}>
        {/* Participante local video y audio */}
        {!twilioVideo.audioOnly && (
          <video
            className={
              !disableVideo ? classes.localParticipantVideo : classes.localParticipantVideoDisable
            }
            ref={videoRef}
            autoPlay={true}
            muted
          />
        )}
        <audio ref={audioRef} autoPlay={true}></audio>
        {(disableVideo || twilioVideo.audioOnly) && <Avatar>{assessor.name.charAt(0)}</Avatar>}
      </div>

      {/* Participante remoto video */}
      {!twilioVideo.audioOnly && videoParticipant && (
        <>
          {iconMicParticipantVisible && <MicOff className={classes.iconMicParticipant} />}
          <video
            className={!avatarVideoParticipantVisible ? classes.video : classes.invisible}
            ref={videoParticipantRef}
            autoPlay={true}
            muted
          />
        </>
      )}

      {videoParticipant && (avatarVideoParticipantVisible || twilioVideo.audioOnly) && (
        <div className={classes.avatarContainer}>
          {iconMicParticipantVisible && <MicOff className={classes.iconMicParticipant} />}
          <Avatar className={classes.avatar}>{client.name.charAt(0)}</Avatar>
        </div>
      )}
      {!videoParticipant && (
        <div className={classes.avatarContainer}>
          <Typography variant="h6" className={classes.title}>
            {t("waitingStudent")}
          </Typography>
        </div>
      )}
      {/* Participante remoto audio */}
      <audio ref={audioParticipantRef} autoPlay={true} />

      {/* Acciones durante la llamada */}
      <div className={classes.buttonsContainer}>
        <IconButton
          className={!disableAudio ? classes.button : classes.buttonOff}
          onClick={enableDisableAudio}
          disabled={!twilioVideo.isConnected}
        >
          {!disableAudio ? <Mic className={classes.icon} /> : <MicOff className={classes.icon} />}
        </IconButton>
        {!twilioVideo.audioOnly && (
          <>
            {/* {isSupported && (
              <IconButton onClick={changeBackgroundVideo}>
                <BlurOn />
              </IconButton>
            )} */}
            <IconButton
              className={!disableVideo ? classes.button : classes.buttonOff}
              onClick={enableDisableVideo}
              disabled={!twilioVideo.isConnected}
            >
              {!disableVideo ? (
                <Videocam className={classes.icon} />
              ) : (
                <VideocamOff className={classes.icon} />
              )}
            </IconButton>
          </>
        )}
        {/* Configuración de los dispositivos de la llamada */}
        <DialogConfiguration
          openDialogConfiguration={openDialogConfiguration}
          setOpenDialogConfiguration={setOpenDialogConfiguration}
          room={room}
          audioParticipantRef={audioParticipantRef}
        />
        <PopoverOptionsConfiguration
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setOpenDialogConfiguration={setOpenDialogConfiguration}
        />
        <IconButton
          className={classes.buttonOff}
          onClick={disconnectRoom}
          disabled={!twilioVideo.isConnected}
        >
          <CallEnd className={classes.icon} />
        </IconButton>
      </div>
    </Dialog>
  );
};

export default TwilioVideo;

const useStyles = makeStyles(() => ({
  avatarContainer: {
    width: 960,
    height: 540,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#202124"
  },
  avatar: {
    width: 100,
    height: 100
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#202124",
    gap: 20
  },
  button: {
    backgroundColor: "#3e3e42"
  },
  buttonOff: {
    backgroundColor: "#c5221f",
    "&:hover": {
      background: "#c5221f"
    }
  },
  icon: {
    color: "#fff"
  },
  iconMicParticipant: {
    position: "absolute",
    top: "1%",
    right: "1%",
    color: "#fff",
    backgroundColor: "#616161",
    border: "1px solid #616161",
    borderRadius: "1rem",
    padding: "0.2rem"
  },
  invisible: {
    display: "none"
  },
  localParticipantVideo: {
    width: 200,
    height: 150
  },
  localParticipantVideoDisable: {
    width: 200,
    height: 150,
    display: "none"
  },
  participantsContainer: {
    zIndex: 1,
    position: "absolute",
    width: 200,
    height: 115,
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#202124",
    border: "2px solid #3e3e42"
  },
  title: {
    color: "#fff"
  },
  video: {
    backgroundColor: "#202124",
    width: 960,
    height: 540
  }
}));

TwilioVideo.propTypes = {
  errorConnectRoom: PropTypes.func,
  setOpenDialogPermissionDenied: PropTypes.func
};
