import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Helper from "../../../../utils/Helper";
import { DeleteForever } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const { constants } = Helper;

const useStyles = makeStyles(() => ({
  messageStatusIcon: {
    alignSelf: "flex-end"
  }
}));

const Icon = ({ messageStatus }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip className={classes.messageStatusIcon} title={t(messageStatus)}>
      {messageStatus === constants.messageStatus.failed ? (
        <CloseIcon />
      ) : messageStatus === constants.messageStatus.queued ? (
        <HourglassEmptyIcon />
      ) : messageStatus === constants.messageStatus.delivered ? (
        <CheckIcon />
      ) : messageStatus === constants.messageStatus.undelivered ? (
        <CloseIcon />
      ) : messageStatus === constants.messageStatus.send ? (
        <CheckIcon />
      ) : messageStatus === constants.messageStatus.sent ? (
        <CheckIcon />
      ) : messageStatus === constants.messageStatus.received ? (
        <DoneAllIcon />
      ) : messageStatus === constants.messageStatus.read ? (
        <DoneAllIcon color={"primary"} />
      ) : messageStatus === constants.messageStatus.deleted ? (
        <DeleteForever />
      ) : (
        <div />
      )}
    </Tooltip>
  );
};

Icon.propTypes = {
  messageStatus: PropTypes.string
};

export default Icon;
