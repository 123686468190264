import React, { useContext } from "react";
import { Badge, MenuItem, Typography } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import AppContext from "../../AppContext";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PopoverItemsDelegates = ({ handleClosePopoverDelegates }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { assessor, setAssessor, setClient, setControlConfig, delegates, appConfig } =
    useContext(AppContext);

  const setAssessorDelegate = oEvent => {
    handleClosePopoverDelegates();
    setControlConfig(oControlConfig => {
      return {
        ...oControlConfig,
        showLoaderUserList: true
      };
    });
    setClient({
      name: "",
      lastName: "",
      compoundName: "",
      email: "",
      country: "",
      courseId: "",
      courseName: "",
      phone: ""
    });
    setAssessor(() => {
      return {
        name: oEvent.assessor_first_name,
        lastName: oEvent.assessor_last_name,
        compoundName: oEvent.assessor_last_name
          ? `${oEvent.assessor_first_name} ${oEvent.assessor_last_name}`
          : oEvent.assessor_first_name,
        email: oEvent.assessor_email
      };
    });
  };

  
  return (
    <>
      <MenuItem
        className={appConfig.darkTheme ? classes.menuItemTitleDark : classes.menuItemTitle}
        value={""}
      >
        <Typography color="primary">{t("delegates")}</Typography>
      </MenuItem>
      {delegates.map(oDelegate => (
        <MenuItem
          key={oDelegate.assessor_email}
          value={oDelegate}
          className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
          onClick={() => setAssessorDelegate(oDelegate)}
          selected={oDelegate.assessor_email === assessor.email}
        >
          <Badge
            overlap="rectangular"
            badgeContent={oDelegate.unread}
            classes={{ badge: classes.badgeColor }}
          >
            <Person color="primary" />
          </Badge>
          {oDelegate.assessor_last_name ? (
            <Typography color="primary">
              {oDelegate.assessor_first_name + " " + oDelegate.assessor_last_name}
            </Typography>
          ) : (
            <Typography color="primary">{oDelegate.assessor_first_name}</Typography>
          )}
        </MenuItem>
      ))}
    </>
  );
};

export default PopoverItemsDelegates;

const useStyles = makeStyles(() => ({
  badgeColor: {
    backgroundColor: "#4fc3f7"
  },
  menuItem: {
    padding: 15,
    gap: 15
  },
  menuItemDark: {
    backgroundColor: "#3e3e42",
    "&:hover": {
      backgroundColor: "#2d2d30"
    },
    padding: 15,
    gap: 15
  },
  menuItemTitle: {
    justifyContent: "center"
  },
  menuItemTitleDark: {
    justifyContent: "center",
    backgroundColor: "#2d2d30",
    "&:hover": {
      backgroundColor: "#2d2d30"
    },
    pointerEvents: "none"
  }
}));

PopoverItemsDelegates.propTypes = {
  handleClosePopoverDelegates: PropTypes.func
};
