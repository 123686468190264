import { Badge, MenuItem, Popover, Typography } from "@material-ui/core";
import { Chat, DoneAll, Edit, ExitToApp, Https, Info, NoEncryption } from "@material-ui/icons";
import React, { useContext } from "react";
import clsx from 'clsx';
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ApiCalls from "../../utils/APIRequests";
import Helper from "../../utils/Helper";
import FolderIcon from "@material-ui/icons/Folder";

const PopoverUserListItem = ({
  oUser,
  idMoreOptions,
  bOpenMoreOptions,
  anchorMoreOptions,
  handleClosePopoverMore,
  handleMarkRead,
  handleMarkUnread
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    appConfig,
    assessor,
    userList,
    setUserList,
    filteredUserList,
    setFilteredUserList,
    setAppConfig,
    setContactAssessorList,
    setControlConfig
  } = useContext(AppContext);

  const { constants } = Helper;

  const deactivateAndActivateGroup = active => {
    ApiCalls.postActivateDeactivateGroup(appConfig.host, oUser.groupid, assessor.email, active, appConfig.source, appConfig.company)
      .then(() => {
        oUser.active = active;
        handleClosePopoverMore();
      })
      .catch(() => {
        handleClosePopoverMore();
      });
  };

  const leaveGroup = () => {
    ApiCalls.postLeaveGroup(appConfig.host, oUser.groupid, assessor.email, appConfig.source, appConfig.company)
      .then(() => {
        const userListRemoveGroup = userList.filter(user => {
          user.groupid !== oUser.groupid && user;
        });
        const filteredUserListRemoveGroup = filteredUserList.filter(
          user => user.groupid !== oUser.groupid
        );
        setUserList(userListRemoveGroup);
        setFilteredUserList(filteredUserListRemoveGroup);
        handleClosePopoverMore();
      })
      .catch(() => {
        handleClosePopoverMore();
      });
  };

  const infoGroup = sGroupid => {
    ApiCalls.getInfoGroup(appConfig.host, appConfig.source, appConfig.company, sGroupid)
      .then(response => {
        setAppConfig(oAppConfig => {
          return {
            ...oAppConfig,
            openDialogGroupInfo: true,
            groupInfo: response
          };
        });
      })
      .catch(() => {});
  };

  const editGroup = sGroupid => {
    ApiCalls.getInfoGroup(appConfig.host, appConfig.source, appConfig.company, sGroupid)
      .then(response => {
        handleClosePopoverMore();
        setAppConfig(oAppConfig => {
          return {
            ...oAppConfig,
            groupInfo: response
          };
        });
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            viewUserList: false,
            viewContactList: true,
            showLoaderContactList: true,
            isNewGroup: false,
            isUpdateGroup: true,
            tabSelectedContactList: constants.customers
          };
        });
        apiGetAssessorList();
      })
      .catch(() => {});
  };

  const apiGetAssessorList = () => {
    ApiCalls.getAssessors(appConfig.host, appConfig.source, appConfig.company, "")
      .then(resp => resp.json())
      .then(aoContactAssessorList => {
        setContactAssessorList(aoContactAssessorList);
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            showLoaderContactList: false
          };
        });
      })
      .catch(() => {
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            showLoaderContactList: false
          };
        });
      });
  };

  const handleManageTags = () => {
    setControlConfig(oControlConfig => {
      return {
        ...oControlConfig,
        selectedUserTags: { ...oUser },
        openManageTagsDialog: true
      };
    });
  };

  return (
    <>
      <Popover
        id={idMoreOptions}
        open={bOpenMoreOptions}
        anchorEl={anchorMoreOptions}
        onClose={handleClosePopoverMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {oUser.group ? (
          <>
            <MenuItem
              onClick={() => infoGroup(oUser.groupid)}
              className={appConfig.darkTheme ? classes.menuItemDark : classes.menuItem}
            >
              <Info color="primary" />
              <Typography color="primary">{t("infoGroup")}</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => editGroup(oUser.groupid)}
              className={
                oUser.owner && oUser.active && appConfig.darkTheme
                  ? classes.menuItemDark
                  : oUser.owner && oUser.active
                  ? classes.menuItem
                  : classes.invisible
              }
            >
              <Edit color="primary" />
              <Typography color="primary">{t("editGroup")}</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => deactivateAndActivateGroup(false)}
              className={
                oUser.owner && oUser.active && appConfig.darkTheme
                  ? classes.menuItemDark
                  : oUser.owner && oUser.active
                  ? classes.menuItem
                  : classes.invisible
              }
            >
              <Https color="primary" />
              <Typography color="primary">{t("disableGroup")}</Typography>
            </MenuItem>

            <MenuItem
              onClick={() => deactivateAndActivateGroup(true)}
              className={
                oUser.owner && !oUser.active && appConfig.darkTheme
                  ? classes.menuItemDark
                  : oUser.owner && !oUser.active
                  ? classes.menuItem
                  : classes.invisible
              }
            >
              <NoEncryption color="primary" />
              <Typography color="primary">{t("enableGroup")}</Typography>
            </MenuItem>

            <MenuItem
              onClick={leaveGroup}
              className={
                !oUser.owner && appConfig.darkTheme
                  ? classes.menuItemDark
                  : !oUser.owner
                  ? classes.menuItem
                  : classes.invisible
              }
            >
              <ExitToApp color="primary" />
              <Typography color="primary">{t("leaveGroup")}</Typography>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => handleMarkUnread(oUser)}
              className={
                oUser.unread === 0 && oUser.markasunread === false && appConfig.darkTheme
                  ? classes.menuItemDark
                  : oUser.unread === 0 && oUser.markasunread === false
                  ? classes.menuItem
                  : classes.invisible
              }
            >
              <Badge overlap="rectangular" variant="dot" color="error">
                <Chat color="primary" />
              </Badge>
              <Typography color="primary">{t("markUnread")}</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleMarkRead(oUser)}
              className={
                !(oUser.unread === 0 && oUser.markasunread === false) && !appConfig.darkTheme
                  ? classes.menuItem
                  : !(oUser.unread === 0 && oUser.markasunread === false) && appConfig.darkTheme
                  ? classes.menuItemDark
                  : classes.invisible
              }
            >
              <DoneAll color="primary" />
              <Typography color="primary">{t("markRead")}</Typography>
            </MenuItem>
          </>
        )}


        {!oUser.group ? ( // TODO: revisar etiquetas para los grupos
          <MenuItem
            onClick={() => handleManageTags(oUser)}
            className={
              clsx([
                appConfig.darkTheme ? classes.menuItemDark : classes.menuItem,
                appConfig.usetags ? '' : classes.invisible
              ])}
          >
            <FolderIcon color="primary" />
            <Typography color="primary">{t("manageTags")}</Typography>
          </MenuItem>
        ) : ''}
      </Popover>
    </>
  );
};

const useStyles = makeStyles(() => ({
  menuItem: {
    display: "flex",
    gap: 10,
    margin: 1
  },
  menuItemDark: {
    display: "flex",
    gap: 10,
    backgroundColor: "#3e3e42",
    "&:hover": {
      backgroundColor: "#2d2d30"
    }
  },
  invisible: {
    display: "none"
  }
}));

PopoverUserListItem.propTypes = {
  idMoreOptions: PropTypes.string,
  bOpenMoreOptions: PropTypes.bool,
  anchorMoreOptions: PropTypes.object,
  handleClosePopoverMore: PropTypes.func,
  handleMarkRead: PropTypes.func,
  handleMarkUnread: PropTypes.func,
  oUser: PropTypes.shape({
    assessor_email: PropTypes.string,
    assessor_first_name: PropTypes.string,
    assessor_last_name: PropTypes.string,
    client_email: PropTypes.string,
    client_familiarname: PropTypes.string,
    client_lastname: PropTypes.string,
    client_name: PropTypes.string,
    country: PropTypes.string,
    course_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    course_name: PropTypes.string,
    date_modified: PropTypes.string,
    filter: PropTypes.bool,
    language: PropTypes.string,
    phone: PropTypes.string,
    source: PropTypes.string,
    unread: PropTypes.number,
    user: PropTypes.string,
    markasunread: PropTypes.bool,
    group: PropTypes.bool,
    displayname: PropTypes.string,
    owner: PropTypes.bool,
    active: PropTypes.bool,
    groupid: PropTypes.string
  })
};

export default PopoverUserListItem;
