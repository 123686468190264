import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, TextField, Typography } from "@material-ui/core";
import AppContext from "../../../AppContext";
import AudioController from "./AudioController";

const InputBaseDefaultAudio = ({
  textDefaultMessage,
  setTextDefaultMessage,
  micRecording,
  setMicRecording,
  hasTitle,
  setHasTitle
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { appConfig } = useContext(AppContext);

  const handleHasTitle = () => {
    textDefaultMessage !== "" && setHasTitle(true);
  };

  return (
    <Box className={classes.container}>
      <TextField
        id="audio-title"
        label={t("audioTitle")}
        InputLabelProps={
          appConfig.darkTheme
            ? {
                style: { color: "#fff" }
              }
            : {}
        }
        variant="outlined"
        value={textDefaultMessage}
        onChange={event => {
          setTextDefaultMessage(event.target.value);
          handleHasTitle();
        }}
        inputProps={
          appConfig.darkTheme ? { className: classes.inputDark } : { className: classes.input }
        }
        autoFocus
        required
        autoComplete="off"
        error={!hasTitle}
      />
      <Box className={classes.recorder}>
        <form className={classes.infoController}>
          {micRecording.blobURL ? (
            <audio className={classes.audio} src={micRecording.blobURL} controls="controls" />
          ) : micRecording.isRecording ? (
            <Typography>{t("recording")}</Typography>
          ) : (
            <Typography>{t("record")}</Typography>
          )}
        </form>
        <AudioController
          textDefaultMessage={textDefaultMessage}
          micRecording={micRecording}
          setMicRecording={setMicRecording}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    padding: "10px 0",
    flexDirection: "column",
    gap: "10px"
  },
  input: {
    alignSelf: "start",
    marginLeft: 10
  },
  inputDark: {
    alignSelf: "start",
    color: "#fff",
    marginLeft: 10
  },
  recorder: {
    display: "flex",
    alignItems: "center"
  },
  infoController: {
    width: "75%",
    alignItems: "center",
    marginLeft: 10
  },
  audio: {
    width: "95%",
    marginTop: "10px"
  }
}));

InputBaseDefaultAudio.propTypes = {
  textDefaultMessage: PropTypes.string,
  setTextDefaultMessage: PropTypes.func,
  micRecording: PropTypes.shape({
    isRecording: PropTypes.bool,
    blobURL: PropTypes.string,
    blob: PropTypes.any,
    isBlocked: PropTypes.bool
  }),
  setMicRecording: PropTypes.func,
  hasTitle: PropTypes.bool,
  setHasTitle: PropTypes.func
};

export default InputBaseDefaultAudio;
