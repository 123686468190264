import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";
import TabsAttachedFiles from "./TabsAttachedFiles";

const DialogConversationFiles = ({
  openDialogConversationFiles,
  setOpenDialogConversationFiles
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig } = useContext(AppContext);

  const handleClose = () => {
    setOpenDialogConversationFiles(false);
  };

  return (
    <Dialog open={openDialogConversationFiles} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle className={classes.dialogTitle}>{t("conversationMedia")}</DialogTitle>

      <DialogContent
        className={appConfig.darkTheme ? classes.dialogContentDark : classes.dialogContent}
      >
        <TabsAttachedFiles />
      </DialogContent>

      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
      >
        <Button onClick={handleClose} color="primary" className={classes.buttonClose}>
          <Typography variant="caption" color="primary">
            {t("close")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  dialogContent: {
    height: "70vh",
    padding: 0,
    borderTop: "1px solid #fff"
  },
  dialogContentDark: {
    height: "70vh",
    padding: 0,
    backgroundColor: "#3e3e42",
    color: "#fff",
    borderTop: "1px solid #fff"
  },
  dialogActions: {
    borderTop: "1px solid #bdbdbd",
    padding: "0.8rem 0"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959",
    color: "#fff",
    padding: "0.8rem 0"
  },
  buttonClose: {
    marginRight: "10px"
  }
}));

DialogConversationFiles.propTypes = {
  openDialogConversationFiles: PropTypes.bool,
  setOpenDialogConversationFiles: PropTypes.func
};

export default DialogConversationFiles;
