import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../AppContext";
import { Container, Fade } from "@material-ui/core";
import ContactListBar from "../ContactListBar/ContactListBar";
import ScrollToBottom from "react-scroll-to-bottom";
import List from "@material-ui/core/List";
import ContactListItem from "../ContactListItem/ContactListItem";
import ContactListSkeleton from "../ContactListSkeleton/ContactListSkeleton";
import Helper from "../../utils/Helper";
import ContactListItemAssessor from "../ContactListItem/ContactListItemAssessor";
import ApiCalls from "../../utils/APIRequests";

const ContactList = () => {
  const [checked, setChecked] = React.useState([]);
  const classes = useStyles();
  const { constants } = Helper;

  const {
    assessor,
    appConfig,
    controlConfig,
    setControlConfig,
    contactList,
    contactAssessorList,
    newContactSelected
  } = useContext(AppContext);

  // Restart checkboxes
  useEffect(() => {
    if (controlConfig.isUpdateGroup) {
      setChecked(appConfig.groupInfo.members);
      setControlConfig(controlConfig => {
        return {
          ...controlConfig,
          selectUsersGroup: appConfig.groupInfo.members
        };
      });
    } else {
      ApiCalls.getAssessors(appConfig.host, appConfig.source, appConfig.company, assessor.email)
        .then(aoAssessor => {
          const aoAssessorOwner = aoAssessor?.map(assessor => {
            return { ...assessor, owner: true };
          });
          setChecked(aoAssessorOwner);
        })
        .catch(() => {
          const ownerAssessor = {
            assesor_email: assessor.email,
            assesor_first_name: assessor.name,
            assesor_last_name: assessor.lastName,
            icon: `${appConfig.host}/resources/images/proeduca_avatar_40x40.png`,
            owner: true
          };
          setChecked([ownerAssessor]);
        });
    }
  }, [controlConfig.viewContactList]);

  // Depende de la acción que seleccionemos en el popover podremos seleccionar varios usuarios o iniciar una conversación con uno
  const newChatOrNewGroup = oContactUser => {
    if (controlConfig.isNewGroup || controlConfig.isUpdateGroup) {
      if (controlConfig.tabSelectedContactList === constants.customers) {
        const currentIndex = checked.findIndex(
          checkedContactUser => checkedContactUser.client_email === oContactUser.client_email
        );
        delete oContactUser["assessor_email"];
        delete oContactUser["assessor_first_name"];
        delete oContactUser["assessor_last_name"];
        let newChecked = [...checked];
        if (currentIndex === -1) {
          newChecked.push(oContactUser);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            selectUsersGroup: newChecked
          };
        });
      } else {
        const currentIndex = checked.findIndex(
          checkedContactUser => checkedContactUser.assesor_email === oContactUser.assesor_email
        );
        let newChecked = [...checked];
        if (currentIndex === -1) {
          newChecked.push(oContactUser);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            selectUsersGroup: newChecked
          };
        });
      }
    } else {
      newContactSelected(oContactUser);
    }
  };

  return (
    <Fade
      in={controlConfig.viewContactList}
      style={{ display: controlConfig.viewContactList ? "block" : "none" }}
    >
      <Container
        className={appConfig.darkTheme ? classes.contactsContainerDark : classes.contactsContainer}
      >
        <ContactListBar />
        <ScrollToBottom
          className={
            controlConfig.isNewGroup
              ? classes.contactsScrollContainerGroup
              : classes.contactsScrollContainer
          }
          mode="top"
          style={{ height: "80% !important" }}
        >
          {!controlConfig.showLoaderContactList && controlConfig.viewContactList ? (
            <List component="nav">
              {((controlConfig.isNewGroup || controlConfig.isUpdateGroup) &&
                controlConfig.tabSelectedContactList === constants.customers) ||
              (!controlConfig.isNewGroup && !controlConfig.isUpdateGroup)
                ? contactList?.map(oContactUser => (
                    <ContactListItem
                      key={oContactUser.user}
                      oContactUser={oContactUser}
                      checked={checked}
                      newChatOrNewGroup={newChatOrNewGroup}
                    />
                  ))
                : contactAssessorList?.map(oContactAssessor => (
                    <ContactListItemAssessor
                      key={oContactAssessor.assesor_email}
                      oContactUser={oContactAssessor}
                      checked={checked}
                      newChatOrNewGroup={newChatOrNewGroup}
                    />
                  ))}
            </List>
          ) : (
            <ContactListSkeleton />
          )}
        </ScrollToBottom>
      </Container>
    </Fade>
  );
};

const useStyles = makeStyles(() => ({
  contactsContainer: {
    marginLeft: "0 !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    height: "100%",
    width: "40% !important",
    alignItems: "flex-end",
    padding: "0 !important",
    boxShadow: "2px 2px 5px #0000007a"
  },
  contactsContainerDark: {
    marginLeft: "0 !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#2d2d30",
    borderRadius: "10px",
    height: "100%",
    width: "40% !important",
    alignItems: "flex-end",
    padding: "0 !important",
    boxShadow: "2px 2px 5px #0000007a"
  },
  contactsScrollContainer: {
    width: "100%",
    height: "calc(100% - 7.5rem) !important",
    padding: "0 !important"
  },
  contactsScrollContainerGroup: {
    width: "100%",
    height: "calc(100% - 11.8rem) !important",
    padding: "0 !important"
  }
}));

export default ContactList;
