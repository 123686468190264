import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "./Icon/Icon";
import AppContext from "../../../AppContext";
import Helper from "../../../utils/Helper";
import ApiCalls from "../../../utils/APIRequests";
import Typography from "@material-ui/core/Typography";
import { Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import PopoverMessageSchedule from "./PopoverMessageSchedule";
import DialogScheduleInputChat from "../../InputChat/Modals/DialogScheduleInputChat";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MessageSchedule = ({
  message: { text, at: date_modified, direction, messagestatus, attach, mime, ubm_id }
}) => {
  const { t } = useTranslation();
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailOpacity, setThumbnailOpacity] = useState();
  const [audioURL, setAudioURL] = useState("");
  const { constants, isSingleEmoji, parseHour } = Helper;
  const [sUrlCompound, setSUrlCompound] = useState("");
  const [zoom, setZoom] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { appConfig } = useContext(AppContext);

  const [anchorElSettingsMessage, setAnchorElSettingsMessage] = React.useState(null);
  const bOpenSettingsMessage = Boolean(anchorElSettingsMessage);
  const idSettingsMessage = bOpenSettingsMessage ? "simple-popover" : undefined;

  const [openDialogSchedule, setOpenDialogSchedule] = useState(false);

  const useStyles = makeStyles(() => ({
    paper: {
      overflowY: "unset"
    },
    customizedButton: {
      display: "flex",
      position: "fixed",
      right: 10,
      top: 10,
      color: "gray"
    },
    singleEmoji: {
      marginTop: 14,
      marginBottom: 14,
      margin: "auto",
      fontSize: "60px !important"
    },
    singleEmojiMessageContent: {
      width: "100px !important"
    },
    linkContainer: {
      marginTop: 14,
      marginBottom: 14,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column"
    },
    paragraph: {
      wordBreak: "break-word",
      maxWidth: mime ? 240 : "none",
      "& p": {
        fontSize: `${appConfig.size}px !important`
      }
    },
    messageContent: {
      borderRadius: "16px",
      padding: "5px 20px",
      maxWidth: "70%"
    },
    messageContentGray: {
      backgroundColor: "#E2FFC7"
    },
    messageContentBlue: {
      backgroundColor: "#FEFEFE"
    },
    messageContentGrayDark: {
      backgroundColor: "#065D54",
      color: "#fff"
    },
    messageTextContainer: {
      display: "flex",
      flexDirection: "column",
      whiteSpace: "pre-line"
    },
    messageInfoTime: {
      marginLeft: "auto"
    },
    messageGroup: {
      margin: "0 10px 10px 10px",
      display: "flex"
    },
    flexStart: {
      justifyContent: "flex-start"
    },
    flexEnd: {
      justifyContent: "flex-end"
    },
    attachmentButton: {
      alignItems: "center",
      alignContent: "center",
      alignSelf: "center"
    },
    messageInfoContainer: {
      alignItems: "end",
      display: "flex"
    },
    thumbnail: {
      width: "100%",
      minWidth: 240,
      cursor: "pointer",
      marginTop: 15
    },
    audio: {
      marginTop: 15
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "flex-end",
      backgroundColor: "#000000B0",
      padding: 0
    },
    dialogPreview: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end"
    },
    image: {
      maxWidth: "100%"
    }
  }));

  const classes = useStyles();

  const handleCloseDialogSchedule = () => {
    setOpenDialogSchedule(false);
  };

  const handleClosePopoverSettingsMessage = () => {
    setAnchorElSettingsMessage(null);
  };

  if (!text) {
    text = "";
  }

  const parseAttachBase64 = sAttachment => {
    if (sAttachment && sAttachment.length) {
      ApiCalls.getAttachmentsThumbnailAPI(sAttachment).then(async response => {
        let sBase64 = await response.text();
        setThumbnail("data:image/jpeg;base64," + sBase64);
      });
    }
  };

  useEffect(() => {
    parseAttachBase64(attach);
  }, [attach]);

  const getAttachedFile = (aoAttachments, mime) => {
    const getMimeApplication = constants.mimeTypeApplication.some(mimeType => mimeType === mime);
    const getMimeVideo = constants.mimeTypeVideo.some(mimeType => mimeType === mime);
    if (aoAttachments && aoAttachments.length && !getMimeApplication && !getMimeVideo) {
      ApiCalls.getAttachmentsAPI(aoAttachments).then(sUrlCompound => {
        setSUrlCompound(sUrlCompound);
        setOpen(true);
      });
    } else if (aoAttachments && aoAttachments.length) {
      ApiCalls.getAttachmentsAPI(aoAttachments).then(sUrlCompound => {
        window.open(sUrlCompound);
      });
    }
  };

  const getAttachedAudio = async aoAttachments => {
    if (aoAttachments && aoAttachments.length) {
      await ApiCalls.getAttachmentsAPI(aoAttachments).then(response => {
        setAudioURL(response);
      });
    }
  };

  const messageContentClass = sText => {
    let sClass = `${classes.messageContent} ${
      appConfig.darkTheme ? classes.messageContentGrayDark : classes.messageContentGray
    }`;
    if (isSingleEmoji(sText)) {
      sClass = `${sClass} ${classes.singleEmojiMessageContent}`;
    }
    return sClass;
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getMimeAudio = constants.mimeTypeAudio.some(mimeType => mimeType === mime);
    if (!!attach && attach.length) {
      if (getMimeAudio) {
        getAttachedAudio(attach);
      }
    }
  }, [attach]);

  const attachExists = () => {
    const getMimeAudio = constants.mimeTypeAudio.some(mimeType => mimeType === mime);
    if (!!attach && attach.length) {
      if (getMimeAudio && audioURL) {
        return <audio className={classes.audio} src={audioURL} controls="controls" />;
      } else {
        return (
          <img
            onClick={() => getAttachedFile(attach, mime)}
            className={classes.thumbnail}
            src={thumbnail}
            alt={t("attachment")}
            onMouseEnter={() => {
              setThumbnailOpacity(0.85);
            }}
            onMouseLeave={() => {
              setThumbnailOpacity(1);
            }}
          />
        );
      }
    } else {
      return false;
    }
  };

  const handleDownloadFile = () => {
    window.open(sUrlCompound);
  };

  const handleOpenPopoverSettingsMessage = event => {
    if (messagestatus !== "deleted" && direction !== "received") {
      setAnchorElSettingsMessage(event.currentTarget);
    }
  };

  return (
    <form
      onContextMenu={e => {
        handleOpenPopoverSettingsMessage(e);
        e.preventDefault();
      }}
    >
      <div className={`${classes.messageGroup} ${classes.flexEnd}`}>
        <div className={messageContentClass(text)}>
          <div className={classes.messageTextContainer} style={{ opacity: thumbnailOpacity }}>
            {attachExists()}
            {isSingleEmoji(text) ? (
              <Typography className={classes.singleEmoji}>{text}</Typography>
            ) : (
              <ReactMarkdown className={classes.paragraph} remarkPlugins={[remarkGfm]}>
                {text}
              </ReactMarkdown>
            )}
          </div>
          <div className={classes.messageInfoContainer}>
            <Typography
              className={classes.messageInfoTime}
              style={{ fontSize: `${appConfig.size - 2}px` }}
            >
              {parseHour(date_modified)}
            </Typography>
          </div>
        </div>
        <Icon messageStatus={messagestatus} />
      </div>
      <PopoverMessageSchedule
        idSettingsMessage={idSettingsMessage}
        bOpenSettingsMessage={bOpenSettingsMessage}
        anchorElSettingsMessage={anchorElSettingsMessage}
        handleClosePopoverSettingsMessage={handleClosePopoverSettingsMessage}
        setOpenDialogSchedule={setOpenDialogSchedule}
        ubm_id={ubm_id}
      />
      <DialogScheduleInputChat
        openDialogSchedule={openDialogSchedule}
        handleCloseDialogSchedule={handleCloseDialogSchedule}
        inputMessage=""
        setInputMessage={() => {}}
        setCacheUsers={() => {}}
        ubm_id={ubm_id}
      />
      <Dialog maxWidth="md" open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
        <TransformWrapper>
          {({ zoomIn, zoomOut }) => (
            <div>
              <div className={classes.customizedButton}>
                {!zoom ? (
                  <IconButton
                    color="primary"
                    onClick={() => {
                      zoomIn();
                      setZoom(true);
                    }}
                  >
                    <ZoomInIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    onClick={() => {
                      zoomOut();
                      setZoom(false);
                    }}
                  >
                    <ZoomOutIcon />
                  </IconButton>
                )}
                <IconButton onClick={handleDownloadFile} color="primary">
                  <GetAppIcon />
                </IconButton>
                <IconButton onClick={handleClose} color="primary">
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.dialogPreview}>
                <TransformComponent>
                  <img className={classes.image} src={sUrlCompound} alt={t("imageNotFound")} />
                </TransformComponent>
              </div>
            </div>
          )}
        </TransformWrapper>
      </Dialog>
    </form>
  );
};

MessageSchedule.propTypes = {
  message: PropTypes.shape({
    assesor_name: PropTypes.string,
    full_name: PropTypes.string,
    date_modified: PropTypes.string,
    at: PropTypes.string,
    oppo_name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    source: PropTypes.string,
    text: PropTypes.string,
    messagestatus: PropTypes.string,
    direction: PropTypes.string,
    attach: PropTypes.string,
    mime: PropTypes.string,
    ubm_id: PropTypes.string
  })
};

export default MessageSchedule;
