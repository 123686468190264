import { Dialog, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import PropTypes from "prop-types";

import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";

const DialogImagesViewer = ({ open, setOpen, sUrlCompound }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [zoom, setZoom] = useState(false);

  const handleDownloadFile = () => {
    window.open(sUrlCompound);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <TransformWrapper>
        {({ zoomIn, zoomOut }) => (
          <div>
            <div className={classes.customizedButton}>
              {!zoom ? (
                <IconButton
                  color="secondary"
                  onClick={() => {
                    zoomIn();
                    setZoom(true);
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="secondary"
                  onClick={() => {
                    zoomOut();
                    setZoom(false);
                  }}
                >
                  <ZoomOutIcon />
                </IconButton>
              )}
              <IconButton onClick={handleDownloadFile} color="secondary">
                <GetAppIcon />
              </IconButton>
              <IconButton onClick={handleClose} color="secondary">
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.dialogPreview}>
              <TransformComponent>
                <img
                  id="imageViewer"
                  style={{ maxWidth: "100%", height: "auto" }}
                  src={sUrlCompound}
                  alt={t("imageNotFound")}
                />
              </TransformComponent>
            </div>
          </div>
        )}
      </TransformWrapper>
    </Dialog>
  );
};

export default DialogImagesViewer;

const useStyles = makeStyles(() => ({
  paper: {
    overflowY: "unset",
    width: "100%"
  },
  customizedButton: {
    display: "flex",
    position: "fixed",
    right: 10,
    top: 10,
    color: "gray"
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#000000B0",
    padding: 0
  },
  dialogPreview: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  image: {
    width: "52vh"
  }
}));

DialogImagesViewer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  sUrlCompound: PropTypes.string
};
