import React, { useContext } from "react";
import { Badge, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import AppContext from "../../../AppContext";
import { useTranslation } from "react-i18next";
import { Menu } from "@material-ui/icons";

import is_electron from "is-electron";

const MenuButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userList, setControlConfig } = useContext(AppContext);

  const handleShowHideUserList = () => {
    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        viewContactList: false,
        viewUserList: !controlConfig.viewUserList
      };
    });
  };

  const unreadChats = () => {
    let bVisible = false;
    if (userList && userList.length > 0) {
      userList.forEach(user => {
        if (typeof user?.markasunread != 'undefined') {
          bVisible = user.markasunread ? true : bVisible;
        }
      });
    }

    let bNotifications = !!countPendingNotifications();

    return bVisible || bNotifications;
  };

  const countPendingNotifications = () => {
    let nTotalUnread = 0;
    if (userList && userList.length > 0) {
      userList.forEach(user => {
        if (typeof user?.unread != 'undefined') {
          nTotalUnread += user.unread;
        }
      });
    }

    return nTotalUnread;
  };

  return (
    <Tooltip
      className={is_electron() ? classes.invisible : ""}
      title={<Typography variant="body2">{t("showUserList.tooltip")}</Typography>}
    >
      <IconButton
        className={classes.notificationsButton}
        color="secondary"
        component="span"
        onClick={handleShowHideUserList}
      >
        <Badge overlap="rectangular" variant="dot" color="error" invisible={!unreadChats()}>
          <Menu />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles(() => ({
  notificationsButton: {
    marginRight: 15
  },
  invisible: {
    display: "none"
  }
}));

export default MenuButton;
